import { Constants } from '../api/Constants';

import * as IRS from './IRS';
import * as DataMerger from './DataMerger';
import * as Enum from '../api/Enum';

export class ResponseWrapper<T> {
	public constructor(data: T, status: RSStatus){
		this.data = data;
		this.status = status;
	}
	public data: T;
	public status: RSStatus;
}

interface IUpdate<I> {
	update(dto: I): void;
}

export class RSClsFactory {
	public static factory<T>(type: {new(): T;}) : T {
		return <T>new type();
	}
}

class RSBase<T extends IUpdate<I>,I> implements IUpdate<I> {

	constructor(type: { new(): T ;}) {
		this._type =type;
	}

	private _type: { new(): T; }

	public update(dto: I): void { }

	public deserialize(dto: I) : T {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory<T>(this._type);
		pd.update(dto);
		return pd;
	}

	public copy(dto:I) {
		let array =Object.getOwnPropertyNames(dto);
		array.forEach((a)=>{
			this[a]=dto[a];
 		});
	}
}


export class RSStatus
{
	public errorCode :number;
	public message: string;
	public controller: string;
	public methodName: string;

	public static deserialize(dto: IRS.IStatus) : RSStatus {
		if (!dto)
			return null;
		var r = new RSStatus();
		r.errorCode = dto.errorCode;
		r.message = dto.message;
		r.controller = dto.controller;
		r.methodName = dto.methodName;
		return r;
	}
}

export class IResponseUpdater implements IRS.IIResponseUpdater {

	public updatedActivity: string;
	public noChange: boolean;

	public update(dto: IRS.IIResponseUpdater) : void {
		this.updatedActivity = dto.updatedActivity;
		this.noChange = dto.noChange;
	}

	public static deserialize(dto: IRS.IIResponseUpdater) : IResponseUpdater {
		if (!dto)
			return null;
		let pd = new IResponseUpdater
		pd.update(dto);
		return pd;
	}
}

export class ResponseError implements IRS.IResponseError {

	public errorType: string;
	public errorMessage: string;

	public update(dto: IRS.IResponseError) {
		this.errorType = dto.errorType;
		this.errorMessage =dto.errorMessage;
	}

	public static deserialize(dto: IRS.IResponseError) : ResponseError {
		if (!dto)
			return null;
		let pd = new ResponseError();
		pd.update(dto);
		return pd;
	}
}

export class RSAction implements IRS.IRSAction {

	public id: string;
	public baseId: string;
	public parentageId: string;
	public isChild: boolean;
	public ordinal: number;
	public action: string;
	public applicationId: string;
	public filename: string;
	public path: string;
	public label: string;
	public actionDate: string;
	public dateExpression: string;
	public status: number;
	public hasCount: boolean;
	public count: string;
	public arguments: string;
	public origin: string;
	public isComplete: boolean;
	public percentComplete: number;
	public hasUI: boolean;
	public outcomeSuccess: boolean;
	public outcomeFail: boolean;
	public hasDetails: boolean;
	public jobId: string;
	public downloadUrl: string;
	public children: Array<RSAction> = [];
	public hasChildren: boolean;
	public icon:string;
	public requiresPreparationState:number;

	public update(dto: IRS.IRSAction) : void {
		this.id = dto.id;
		this.baseId = dto.baseId;
		this.parentageId = dto.parentageId;
		this.isChild = dto.isChild;
		this.ordinal = dto.ordinal;
		this.action = dto.action;
		this.applicationId = dto.applicationId;
		this.filename = dto.filename;
		this.path = dto.path;
		this.label = dto.label;
		this.actionDate = dto.actionDate;
		this.dateExpression = dto.dateExpression;
		this.status = dto.status;
		this.count = dto.count;
		this.hasCount = dto.hasCount;
		this.arguments = dto.arguments;
		this.origin = dto.origin;
		this.isComplete = dto.isComplete;
		this.percentComplete = dto.percentComplete;
		this.hasUI = dto.hasUI;
		this.outcomeSuccess = dto.outcomeSuccess;
		this.outcomeFail = dto.outcomeFail;
		this.hasDetails = dto.hasDetails;
		this.jobId = dto.jobId;
		this.downloadUrl = dto.downloadUrl;
		this.icon =dto.icon;
		DataMerger.MergeHelper.ListMerge(dto.children, this.children,  (i) => { return i.id },  RSAction);
		this.hasChildren = dto.hasChildren;
		this.requiresPreparationState = dto.requiresPreparationState;
	}

	public deserialize(dto: IRS.IRSAction) : RSAction {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSAction);
		pd.update(dto);
		return pd;
	}
}

export class RSGetWindowsUpdates implements IRS.IRSGetWindowsUpdates {

	public items: Array<RSWindowsUpdate> = [];
	public operatingSystems: Array<RSOperatingSystem> = [];

	public update(dto: IRS.IRSGetWindowsUpdates) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSWindowsUpdate);
		DataMerger.MergeHelper.ListMerge(dto.operatingSystems, this.operatingSystems,  (i) => { return i.id },  RSOperatingSystem);
	}

	public deserialize(dto: IRS.IRSGetWindowsUpdates) : RSGetWindowsUpdates {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetWindowsUpdates);
		pd.update(dto);
		return pd;
	}
}

export class RSWindowsUpdate implements IRS.IRSWindowsUpdate {

	public id: string;
	public fullId: string;
	public operatingSystemId: string;
	public checkpoint: string;
	public description: string;
	public enabled: boolean;
	public month: string;
	public imageStatus: number;
	public imageStatusDescription: string;
	public imageStatusLastChangedExpression: string;
	public imageExists: boolean;
	public percentageComplete: number;
	public runCandidateCount: number;
	public vmCount: number;
	public origin: number;
	public createdBy: string;
	public isRunning: boolean;
	public canBuild: boolean;
	public canRetire: boolean;
	public succeededCount: number;
	public failedCount: number;
	public inProgressCount: number;
	public remainingCount: number;
	public buildingCount: number;
	public buildingFailureCount: number;

	public update(dto: IRS.IRSWindowsUpdate) : void {
		this.id = dto.id;
		this.fullId = dto.fullId;
		this.operatingSystemId = dto.operatingSystemId;
		this.checkpoint = dto.checkpoint;
		this.description = dto.description;
		this.enabled = dto.enabled;
		this.month = dto.month;
		this.imageStatus = dto.imageStatus;
		this.imageStatusDescription = dto.imageStatusDescription;
		this.imageStatusLastChangedExpression = dto.imageStatusLastChangedExpression;
		this.imageExists = dto.imageExists;
		this.percentageComplete = dto.percentageComplete;
		this.runCandidateCount = dto.runCandidateCount;
		this.vmCount = dto.vmCount;
		this.origin = dto.origin;
		this.createdBy = dto.createdBy;
		this.isRunning = dto.isRunning;
		this.canBuild = dto.canBuild;
		this.canRetire = dto.canRetire;
		this.succeededCount = dto.succeededCount;
		this.failedCount = dto.failedCount;
		this.inProgressCount = dto.inProgressCount;
		this.remainingCount = dto.remainingCount;
		this.buildingCount = dto.buildingCount;
		this.buildingFailureCount = dto.buildingFailureCount;
	}

	public deserialize(dto: IRS.IRSWindowsUpdate) : RSWindowsUpdate {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSWindowsUpdate);
		pd.update(dto);
		return pd;
	}
}

export class RSVhd2 implements IRS.IRSVhd2 {

	public id: string;
	public name: string;

	public update(dto: IRS.IRSVhd2) : void {
		this.id = dto.id;
		this.name = dto.name;
	}

	public deserialize(dto: IRS.IRSVhd2) : RSVhd2 {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSVhd2);
		pd.update(dto);
		return pd;
	}
}

export class RSPatchUpdate implements IRS.IRSPatchUpdate {

	public id: string;
	public name: string;

	public update(dto: IRS.IRSPatchUpdate) : void {
		this.id =dto.id;
		this.name = dto.name;
	}

	public deserialize(dto: IRS.IRSPatchUpdate) : RSPatchUpdate {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSPatchUpdate);
		pd.update(dto);
		return pd;
	}
}

export class RSXCheckVirtualMachine implements IRS.IRSXCheckVirtualMachine {

	public id: string;
	public dateCreated: string;
	public status: string;
	public name: string;
	public imageId: string;
	public imageDisplay: string;
	public operatingSystemId: string;
	public remotingUrl: string;
	public progressPercentage: number;

	public update(dto: IRS.IRSXCheckVirtualMachine) : void {
		this.id = dto.id;
		this.dateCreated = dto.dateCreated;
		this.status = dto.status;
		this.name = dto.name;
		this.imageId = dto.imageId;
		this.imageDisplay = dto.imageDisplay;
		this.operatingSystemId = dto.operatingSystemId;
		this.remotingUrl = dto.remotingUrl;
		this.progressPercentage = dto.progressPercentage;
	}

	public deserialize(dto: IRS.IRSXCheckVirtualMachine) : RSXCheckVirtualMachine {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSXCheckVirtualMachine);
		pd.update(dto);
		return pd;
	}
}

export class RSGetXCheckVirtualMachines implements IRS.IRSGetXCheckVirtualMachines {

	public items: Array<RSXCheckVirtualMachine> = [];

	public update(dto: IRS.IRSGetXCheckVirtualMachines) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSXCheckVirtualMachine);
	}

	public deserialize(dto: IRS.IRSGetXCheckVirtualMachines) : RSGetXCheckVirtualMachines {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetXCheckVirtualMachines);
		pd.update(dto);
		return pd;
	}
}

export class RSGetBaseImages implements IRS.IRSGetBaseImages {

	public images: Array<RSVhd2> = [];

	public update(dto: IRS.IRSGetBaseImages) : void {
		DataMerger.MergeHelper.ListMerge(dto.images, this.images,  (i) => { return i.id },  RSVhd2);
	}

	public deserialize(dto: IRS.IRSGetBaseImages) : RSGetBaseImages {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory( RSGetBaseImages);
		pd.update(dto);
		return pd;
	}
}

export class RSGetPatchUpdates implements IRS.IRSGetPatchUpdates {

	public items: Array<RSPatchUpdate> = [];

	public update(dto: IRS.IRSGetPatchUpdates) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSVhd2);
	}

	public deserialize(dto: IRS.IRSGetPatchUpdates) : RSGetPatchUpdates {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory( RSGetPatchUpdates);
		pd.update(dto);
		return pd;
	}
}

export class RSActivityItem implements IRS.IRSActivityItem {

	public id: string;
	public activityType: number;
	public text: string;
	public activityDate: string;
	public projectId: string;
	public projectName: string;

	public update(dto: IRS.IRSActivityItem) : void {
		this.id = dto.id;
		this.activityType = dto.activityType;
		this.text = dto.text;
		this.activityDate = dto.activityDate;
		this.projectId = dto.projectId;
		this.projectName = dto.projectName;
	}

	public deserialize(dto: IRS.IRSActivityItem) : RSActivityItem {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSActivityItem);
		pd.update(dto);
		return pd;
	}
}

export class RSAdminApplication 
	extends RSBase<RSAdminApplication,IRS.IRSAdminApplication> implements IRS.IRSAdminApplication {

	constructor(){super(RSAdminApplication)}

	public applicationId: string;
	public name: string;
	public projectId: string;
	public projectName: string;

	public update(dto: IRS.IRSAdminApplication) : void { super.copy(dto)}

	public deserialize(dto: IRS.IRSAdminApplication) : RSAdminApplication {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory( RSAdminApplication);
		pd.update(dto);
		return pd;
	}
}

export class RSAdminAssessmentGroup 
	extends RSBase<RSAdminAssessmentGroup,IRS.IRSAdminAssessmentGroup> implements IRS.IRSAdminAssessmentGroup {

	constructor() {super(RSAdminAssessmentGroup)}

	public id: string;
	public name: string;

	public update(dto: IRS.IRSAdminAssessmentGroup) : void { super.copy(dto); }
}

export class RSAdminAssGrpGetFilePayload implements IRS.IRSAdminAssGrpGetFilePayload {

	public bulletinUrls: Array<string> = [];
	public downloadUrls: Array<string> = [];
	public filenames: Array<string> = [];

	public update(dto: IRS.IRSAdminAssGrpGetFilePayload) : void {
		this.bulletinUrls = dto.bulletinUrls;
		this.downloadUrls = dto.downloadUrls;
		this.filenames = dto.filenames;
	}
}

export class RSAdminCheckCandidateGrp
		extends RSBase<RSAdminCheckCandidateGrp, IRS.IRSAdminCheckCandidateGrp> implements IRS.IRSAdminCheckCandidateGrp {

	constructor() {super(RSAdminCheckCandidateGrp)}
	
	public key: string;
	public productFamily: string;
	public platformElements: string;
	public remediationId: string;
	public remediationUrl: string;
	public state: number;
	public checkGenerated: boolean;

	public update(dto: IRS.IRSAdminCheckCandidateGrp) { super.copy(dto);}
}

export class RSAdminCheck 
		extends RSBase<RSAdminCheck, IRS.IRSAdminCheck> implements IRS.IRSAdminCheck {

	constructor(){super(RSAdminCheck)}

	public id: string;
	public name: string;

	public update(dto: IRS.IRSAdminCheck) : void { super.copy(dto)}
}

export class RSAdminTaskMgrItem 
	extends RSBase<RSAdminTaskMgrItem, IRS.IRSAdminTaskMgrItem> implements IRS.IRSAdminTaskMgrItem {

	constructor() {	super(RSAdminTaskMgrItem) }

	public rowId: string;
	public instanceId: string;
	public serverId: string;
	public user: string;
	public messageType: string;
	public jobType: string;
	public processId: number;
	public timeExecuting: string;
	public description: string;
	public name: string;
	public type: string;
	public details: string;
	public jobExecutingVerified: boolean;
	public processorPercentage: number;

	public update(dto: IRS.IRSAdminTaskMgrItem) : void { super.copy(dto); }
}

export class RSAdminQueueItem 
		extends RSBase<RSAdminQueueItem, IRS.IRSAdminQueueItem> implements IRS.IRSAdminQueueItem {

	constructor() {super(RSAdminQueueItem)}

	public rowId: string;
	public routerKey: string;
	public serverId: string;
	public payloadType: string;
	public user: string;
	public queueTime: string;

	public update(dto: IRS.IRSAdminQueueItem) : void { super.copy(dto) }
}

export class RSAdminUserItem 
		extends RSBase<RSAdminUserItem, IRS.IRSAdminUserItem> implements IRS.IRSAdminUserItem {

	constructor() {	super(RSAdminUserItem) }

	public id: string;
	public userEmail: string;
	public emailDomain: string;
	public lastLoginExpression: string;
	public servers:string;
	public featureSet: string;
	public validated: boolean;

	public update(dto: IRS.IRSAdminUserItem) : void { super.copy(dto) }
}

export class RSAdminCheckInclusionExpression 
		extends RSBase<RSAdminCheckInclusionExpression, IRS.IRSAdminCheckInclusionExpression> implements IRS.IRSAdminCheckInclusionExpression {

	constructor() {super(RSAdminCheckInclusionExpression);}

	public id: string;
	public fromExpr: string;
	public toExpr: string;

	public update(dto: IRS.IRSAdminCheckInclusionExpression) { super.copy(dto);}
}

export class RSAdminCheckRule 
		extends RSBase<RSAdminCheckRule, IRS.IRSAdminCheckRule> implements IRS.IRSAdminCheckRule {

	constructor() { super(RSAdminCheckRule); }

	public checkRuleId: string;
	public id: string;
	public rag: number;
	public category: number;
	public query: string;
	public message: string;
	public effortAuto: number;
	public effortManual: number;
	public effortMax: number;
	public oneFixPerPackage: boolean;
	public duplicateMessageRollup: number;
	public moreInfoUrl: string;

	public update(dto: IRS.IRSAdminCheckRule) { super.copy(dto); }
}

export class RSAdminGetAllApplications 
		extends RSBase<RSAdminGetAllApplications, IRS.IRSAdminGetAllApplications> implements IRS.IRSAdminGetAllApplications {

	constructor() { super(RSAdminGetAllApplications); }

	public items: Array<RSAdminApplication> = [];

	public update(dto: IRS.IRSAdminGetAllApplications) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.applicationId },  RSAdminApplication);
	}
}

export class RSAdminGetAssessmentGroup 
		extends RSBase<RSAdminGetAssessmentGroup, IRS.IRSAdminGetAssessmentGroup> implements IRS.IRSAdminGetAssessmentGroup {

	constructor() { super(RSAdminGetAssessmentGroup); }

	public id: string;
	public name: string;
	public checks: Array<RSAdminCheck> = [];

	public update(dto: IRS.IRSAdminGetAssessmentGroup) : void {
		this.id = dto.id;
		this.name = dto.name;
		DataMerger.MergeHelper.ListMerge(dto.checks, this.checks,  (i) => { return i.id },  RSAdminCheck);
	}
}

export class RSAdminGetAssessmentGroups 
		extends RSBase<RSAdminGetAssessmentGroups, IRS.IRSAdminGetAssessmentGroups> implements IRS.IRSAdminGetAssessmentGroups {

	constructor() {super(RSAdminGetAssessmentGroups); }

	public items: Array<RSAdminAssessmentGroup> = [];

	public update(dto: IRS.IRSAdminGetAssessmentGroups) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSAdminAssessmentGroup);
	}
}

export class RSAdminGetCheck 
		extends RSBase<RSAdminGetCheck, IRS.IRSAdminGetCheck> implements IRS.IRSAdminGetCheck  {

	constructor() { super(RSAdminGetCheck); }

	public id: string;
	public name: string;
	public description: string;
	public packageTypes: string;
	public inclusionExpressions: Array<RSAdminCheckInclusionExpression> = [];
	public rules: Array<RSAdminCheckRule> = [];

	public update(dto: IRS.IRSAdminGetCheck) : void {
		this.id = dto.id;
		this.name = dto.name;
		this.description = dto.description;
		this.packageTypes = dto.packageTypes;
		DataMerger.MergeHelper.ListMerge(dto.inclusionExpressions, this.inclusionExpressions,  (i) => { return i.id },  RSAdminCheckInclusionExpression);
		DataMerger.MergeHelper.ListMerge(dto.rules, this.rules,  (i) => { return i.checkRuleId },  RSAdminCheckRule);
	}
}

export class RSAdminGetCheckRule 
		extends RSBase<RSAdminGetCheckRule, IRS.IRSAdminGetCheckRule> implements IRS.IRSAdminGetCheckRule {

	constructor() { super(RSAdminGetCheckRule); }

	public rule: RSAdminCheckRule;
	public template: RSCheckRuleTemplateInfo;

	public update(dto: IRS.IRSAdminGetCheckRule) : void {
		if (this.rule == null && dto.rule != null)
			this.rule = new RSAdminCheckRule();
		if (dto.rule == null)
			this.rule = null
		else
			this.rule.update(dto.rule);

		if (this.template == null && dto.template != null)
			this.template = new RSCheckRuleTemplateInfo();
		if (dto.template == null)
			this.template = null
		else
			this.template.update(dto.template);
	}
}

export class RSAdminGetChecks 
		extends RSBase<RSAdminGetChecks, IRS.IRSAdminGetChecks> implements IRS.IRSAdminGetChecks {

	constructor() { super(RSAdminGetChecks); }

	public items: Array<RSAdminCheck> = [];

	public update(dto: IRS.IRSAdminGetChecks) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSAdminCheck);
	}
}

export class RSAdminGetTaskMgr 
		extends RSBase<RSAdminGetTaskMgr, IRS.IRSAdminGetTaskMgr> implements IRS.IRSAdminGetTaskMgr {

	constructor() {super(RSAdminGetTaskMgr); }

	public items: Array<RSAdminTaskMgrItem> = [];
	public queues: Array<RSAdminQueueItem> = [];
	public timerActivities: Array<RSAdminTimerActivityItem>=[];

	public update(dto: IRS.IRSAdminGetTaskMgr) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.rowId },  RSAdminTaskMgrItem);
		DataMerger.MergeHelper.ListMerge(dto.queues, this.queues, (i)=> { return i.rowId }, RSAdminQueueItem);
		DataMerger.MergeHelper.ListMerge(dto.timerActivities, this.timerActivities, (i)=> { return i.rowId }, RSAdminTimerActivityItem);
	}
}

export class RSAdminTimerActivityItem 
		extends RSBase<RSAdminTimerActivityItem, IRS.IRSAdminTimerActivityItem> implements IRS.IRSAdminTimerActivityItem {

	constructor() { super(RSAdminTimerActivityItem); }

	public rowId: string;
	public serverId: string;
	public timerId: string;
	public lastExecutedExpression: string;
	public interval: string;
	public isOverdue: boolean;
	public timerOverdueExpression:string;

	public update(dto: IRS.IRSAdminTimerActivityItem) { super.copy(dto); }
}

export class RSAdminGetUsers
		extends RSBase<RSAdminGetUsers, IRS.IRSAdminGetUsers> implements IRS.IRSAdminGetUsers {

	constructor() { super(RSAdminGetUsers); }

	public items: Array<RSAdminUserItem> = [];

	public update(dto: IRS.IRSAdminGetUsers) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSAdminUserItem);
	}
}

export class RSAdminGetUser 
		extends RSBase<RSAdminGetUser, IRS.IRSAdminGetUser> implements IRS.IRSAdminGetUser {

	constructor() { super(RSAdminGetUser); }

	public item: RSAdminUserItem=null;
	public featureTypes: Array<RSFeatureType> = [];

	public update(dto: IRS.IRSAdminGetUser) : void {
		if (this.item==null)
			this.item = new RSAdminUserItem()
		this.item.update(dto.item);
		DataMerger.MergeHelper.ListMerge(dto.featureTypes, this.featureTypes,  (i) => { return i.id },  RSFeatureType);
	}
}

export class RSAdminGetEvaluationMachineItem 
		extends RSBase<RSAdminGetEvaluationMachineItem, IRS.IRSAdminGetEvaluationMachineItem> implements IRS.IRSAdminGetEvaluationMachineItem {

	constructor() { super(RSAdminGetEvaluationMachineItem); }

	public requestId: string;
	public machineId: string;
	public ipAddress: string;
	public applicationId: string;
	public userId: string;
	public userEmail: string;
	public timestamp: string;
	public availabilityState: number;
	public percentComplete: number;

	public update(dto: IRS.IRSAdminGetEvaluationMachineItem) { super.copy(dto); }
}

export class RSAdminGetEvaluationMachineRequests 
		extends RSBase<RSAdminGetEvaluationMachineRequests, IRS.IRSAdminGetEvaluationMachineRequests> implements IRS.IRSAdminGetEvaluationMachineRequests {

	constructor() { super(RSAdminGetEvaluationMachineRequests); }

	public requests: Array<RSEvaluationMachineRequest> = [];
	public machines: Array<RSEvaluationMachine> = [];

	public update(dto: IRS.IRSAdminGetEvaluationMachineRequests) : void {
		DataMerger.MergeHelper.ListMerge(dto.requests, this.requests,  (i) => { return i.requestId },  RSEvaluationMachineRequest);
		DataMerger.MergeHelper.ListMerge(dto.machines, this.machines,  (i) => { return i.machineId },  RSEvaluationMachine);
	}
}

export class RSAdminGetJobLogItem 
		extends RSBase<RSAdminGetJobLogItem, IRS.IRSAdminGetJobLogItem> implements IRS.IRSAdminGetJobLogItem {

	constructor() { super(RSAdminGetJobLogItem); }

	public id: string;
	public texts: Array<string> = [];

	public update(dto: IRS.IRSAdminGetJobLogItem) : void {
		this.id = dto.id;
		this.texts = dto.texts;
	}
}

export class RSAdminGetJobLogs 
		extends RSBase<RSAdminGetJobLogs, IRS.IRSAdminGetJobLogs> implements IRS.IRSAdminGetJobLogs {

	constructor() { super(RSAdminGetJobLogs); }

	public items: Array<RSAdminJobLogItem> = [];

	public update(dto: IRS.IRSAdminGetJobLogs) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSAdminJobLogItem);
	}
}

export class RSAdminGetJobStatistics 
		extends RSBase<RSAdminGetJobStatistics, IRS.IRSAdminGetJobStatistics> implements IRS.IRSAdminGetJobStatistics {

	constructor() { super(RSAdminGetJobStatistics); }

	public noDays: number;
	public items: Array<RSAdminJobStatisticItem> = [];

	public update(dto: IRS.IRSAdminGetJobStatistics) : void {
		this.noDays = dto.noDays;
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSAdminJobStatisticItem);
	}
}

export class RSAdminGetKB 
		extends RSBase<RSAdminGetKB, IRS.IRSAdminGetKB> implements IRS.IRSAdminGetKB {

	constructor() { super(RSAdminGetKB); }

	public downloadUrls: Array<string> = [];
	public filenames: Array<string> = [];

	public update(dto: IRS.IRSAdminGetKB) : void {
		this.downloadUrls = dto.downloadUrls;
		this.filenames = dto.filenames;
	}
}

export class RSAdminGetPatchCategories
		extends RSBase<RSAdminGetPatchCategories, IRS.IRSAdminGetPatchCategories> implements IRS.IRSAdminGetPatchCategories {

	constructor() { super(RSAdminGetPatchCategories); }

	public items: Array<string> = [];

	public update(dto: IRS.IRSAdminGetPatchCategories) : void {
		this.items = dto.items;
	}
}

export class RSAdminGetPatchCategory 
		extends RSBase<RSAdminGetPatchCategory, IRS.IRSAdminGetPatchCategory> implements IRS.IRSAdminGetPatchCategory {

	constructor() { super(RSAdminGetPatchCategory); }

	public category: string;
	public items: Array<RSAdminPatchFilename> = [];

	public update(dto: IRS.IRSAdminGetPatchCategory) : void {
		this.category = dto.category;
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.filename },  RSAdminPatchFilename);
	}
}

export class RSAdminGetPatchGroupItem 
		extends RSBase<RSAdminGetPatchGroupItem, IRS.IRSAdminGetPatchGroupItem> implements IRS.IRSAdminGetPatchGroupItem {

	constructor() { super(RSAdminGetPatchGroupItem); }

	public patchGroupId: string;
	public title: string;
	public checkCandidateGroups: Array<RSAdminCheckCandidateGrp> = [];

	public update(dto: IRS.IRSAdminGetPatchGroupItem) : void {
		this.patchGroupId = dto.patchGroupId;
		this.title = dto.title;
		DataMerger.MergeHelper.ListMerge(dto.checkCandidateGroups, this.checkCandidateGroups,  (i) => { return i.key },  RSAdminCheckCandidateGrp);
	}
}

export class RSAdminGetPatchGroupItemSummarised 
		extends RSBase<RSAdminGetPatchGroupItemSummarised, IRS.IRSAdminGetPatchGroupItemSummarised> implements IRS.IRSAdminGetPatchGroupItemSummarised {

	constructor() { super(RSAdminGetPatchGroupItemSummarised); }

	public categories: Array<string> = [];
	public items: Array<RSAdminPatchGroupItemSummarised> = [];

	public update(dto: IRS.IRSAdminGetPatchGroupItemSummarised) : void {
		this.categories = dto.categories;
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.cve },  RSAdminPatchGroupItemSummarised);
	}
}

export class RSAdminGetPatchGroups 
		extends RSBase<RSAdminGetPatchGroups, IRS.IRSAdminGetPatchGroups> implements IRS.IRSAdminGetPatchGroups {

	constructor() { super(RSAdminGetPatchGroups); }

	public items: Array<RSPatchGroup> = [];

	public update(dto: IRS.IRSAdminGetPatchGroups) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSPatchGroup);
	}
}

export class RSAdminGetProjects 
		extends RSBase<RSAdminGetProjects, IRS.IRSAdminGetProjects> implements IRS.IRSAdminGetProjects {

	constructor() { super(RSAdminGetProjects); }

	public items: Array<RSProject> = [];

	public update(dto: IRS.IRSAdminGetProjects) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSProject);
	}
}

export class RSAdminGetServerInstance 
		extends RSBase<RSAdminGetServerInstance, IRS.IRSAdminGetServerInstance> implements IRS.IRSAdminGetServerInstance{

	constructor() { super(RSAdminGetServerInstance); }

	public id: string;
	public dateCreated: string;
	public customerName: string;
	public status: number;
	public websiteIPAddress: string;
	public jobVmIpAddress: string;
	public configuredDefaultFeatureSet: string;
	public configuredStorageId: string;
	public configuredConnectionStringMain: string;
	public configuredConnectionStringAdm: string;
	public heartbeatPackage: RSHeartbeatInfo;
	public heartbeatReport: RSHeartbeatInfo;
	public heartbeatManagement: RSHeartbeatInfo;
	public heartbeatImportSupport: RSHeartbeatInfo;
	public heartbeatConversion: RSHeartbeatInfo;
	public quotaStatistic: RSQuotaStatistic;
	public estimatedQueueLength: number;

	public update(dto: IRS.IRSAdminGetServerInstance) : void {
		this.id = dto.id;
		this.dateCreated = dto.dateCreated;
		this.customerName = dto.customerName;
		this.status = dto.status;
		this.websiteIPAddress = dto.websiteIPAddress;
		this.jobVmIpAddress = dto.jobVmIpAddress;
		this.configuredDefaultFeatureSet = dto.configuredDefaultFeatureSet;
		this.configuredStorageId = dto.configuredStorageId;
		this.configuredConnectionStringMain = dto.configuredConnectionStringMain;
		this.configuredConnectionStringAdm = dto.configuredConnectionStringAdm;
		if (this.heartbeatPackage == null && dto.heartbeatPackage != null)
			this.heartbeatPackage = new RSHeartbeatInfo();
		if (dto.heartbeatPackage == null)
			this.heartbeatPackage = null
		else
			this.heartbeatPackage.update(dto.heartbeatPackage);

		if (this.heartbeatReport == null && dto.heartbeatReport != null)
			this.heartbeatReport = new RSHeartbeatInfo();
		if (dto.heartbeatReport == null)
			this.heartbeatReport = null
		else
			this.heartbeatReport.update(dto.heartbeatReport);

		if (this.heartbeatManagement == null && dto.heartbeatManagement != null)
			this.heartbeatManagement = new RSHeartbeatInfo();
		if (dto.heartbeatManagement == null)
			this.heartbeatManagement = null
		else
			this.heartbeatManagement.update(dto.heartbeatManagement);

		if (this.heartbeatImportSupport == null && dto.heartbeatImportSupport != null)
			this.heartbeatImportSupport = new RSHeartbeatInfo();
		if (dto.heartbeatImportSupport == null)
			this.heartbeatImportSupport = null
		else
			this.heartbeatImportSupport.update(dto.heartbeatImportSupport);

		if (this.heartbeatConversion == null && dto.heartbeatConversion != null)
			this.heartbeatConversion = new RSHeartbeatInfo();
		if (dto.heartbeatConversion == null)
			this.heartbeatConversion = null
		else
			this.heartbeatConversion.update(dto.heartbeatConversion);

		if (this.quotaStatistic == null && dto.quotaStatistic != null)
			this.quotaStatistic = new RSQuotaStatistic();
		if (dto.quotaStatistic == null)
			this.quotaStatistic = null
		else
			this.quotaStatistic.update(dto.quotaStatistic);

		this.estimatedQueueLength = dto.estimatedQueueLength;

	}
}

export class RSAdminGetServers 
		extends RSBase<RSAdminGetServers, IRS.IRSAdminGetServers> implements IRS.IRSAdminGetServers {

	constructor() { super(RSAdminGetServers); }

	public items: Array<RSAdminServerInstance> = [];
	public status: number;

	public update(dto: IRS.IRSAdminGetServers) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSAdminServerInstance);
		this.status = dto.status;
	}
}

export class RSAdminGetSoftwareVersions 
		extends RSBase<RSAdminGetSoftwareVersions, IRS.IRSAdminGetSoftwareVersions> implements IRS.IRSAdminGetSoftwareVersions {

	constructor() { super(RSAdminGetSoftwareVersions); }

	public items: Array<string> = [];
	public installedJobVersion: string;
	public installedWebVersion: string;

	public update(dto: IRS.IRSAdminGetSoftwareVersions) : void {
		this.items = dto.items;
		this.installedJobVersion = dto.installedJobVersion;
		this.installedWebVersion = dto.installedWebVersion;
	}
}

export class RSAdminGetWindowsUpdates 
		extends RSBase<RSAdminGetWindowsUpdates, IRS.IRSAdminGetWindowsUpdates> implements IRS.IRSAdminGetWindowsUpdates {

	constructor() { super(RSAdminGetWindowsUpdates); }

	public items: Array<RSAdminWindowsUpdate> = [];
	public operatingSystems: Array<RSOperatingSystem> = [];

	public update(dto: IRS.IRSAdminGetWindowsUpdates) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSAdminWindowsUpdate);
		DataMerger.MergeHelper.ListMerge(dto.operatingSystems, this.operatingSystems,  (i) => { return i.id },  RSOperatingSystem);
	}
}

export class RSAdminWindowsUpdate 
		extends RSBase<RSAdminWindowsUpdate, IRS.IRSAdminWindowsUpdate> implements IRS.IRSAdminWindowsUpdate {

	constructor() { super(RSAdminWindowsUpdate); }

	public id: string;
	public operatingSystemId: string;
	public description: string;
	public enabled: boolean;
	public imageStatus: number;
	public imageStatusDescription: string;
	public imageStatusLastChangedExpression: string;
	public percentageComplete: number;

	public update(dto: IRS.IRSAdminWindowsUpdate) { super.copy(dto); }
}

export class RSAdminImpersonate 
		extends RSBase<RSAdminImpersonate, IRS.IRSAdminImpersonate> implements IRS.IRSAdminImpersonate {

	constructor() { super(RSAdminImpersonate); }

	public authenticationToken: string;

	public update(dto: IRS.IRSAdminImpersonate) { super.copy(dto); }
}

export class RSAdminJobLogItem 
		extends RSBase<RSAdminJobLogItem, IRS.IRSAdminJobLogItem> implements IRS.IRSAdminJobLogItem {

	constructor() { super(RSAdminJobLogItem); }

	public id: string;
	public type: string;
	public endTime: string;
	public startTime: string;
	public name: string;
	public details: string;
	public succeeded: boolean;
	public exceptionDetails: string;
	public projectName: string;
	public userEmail: string;

	public update(dto: IRS.IRSAdminJobLogItem) { super.copy(dto); }
}

export class RSAdminJobStatisticItem 
		extends RSBase<RSAdminJobStatisticItem, IRS.IRSAdminJobStatisticItem> implements IRS.IRSAdminJobStatisticItem {

	constructor() { super(RSAdminJobStatisticItem); }

	public id: string;
	public successFailDistribution: Array<RSDistributionItem> = [];
	public succeededCount: number;
	public failedCount: number;
	public totalCount: number;
	public maxTimeTaken: number;
	public minTimeTaken: number;
	public avgTimeTaken: number;
	public intervalEventCounts: Array<RSDistributionItem> = [];
	public subItems: Array<RSAdminJobStatisticSubItem> = [];

	public update(dto: IRS.IRSAdminJobStatisticItem) : void {
		this.id = dto.id;
		DataMerger.MergeHelper.ListMerge(dto.successFailDistribution, this.successFailDistribution,  (i) => { return i.id },  RSDistributionItem);
		this.succeededCount = dto.succeededCount;
		this.failedCount = dto.failedCount;
		this.totalCount = dto.totalCount;
		this.maxTimeTaken = dto.maxTimeTaken;
		this.minTimeTaken = dto.minTimeTaken;
		this.avgTimeTaken = dto.avgTimeTaken;
		DataMerger.MergeHelper.ListMerge(dto.intervalEventCounts, this.intervalEventCounts,  (i) => { return i.id },  RSDistributionItem);
		DataMerger.MergeHelper.ListMerge(dto.subItems, this.subItems,  (i) => { return i.id },  RSAdminJobStatisticSubItem);
	}
}

export class RSAdminJobStatisticSubItem 
		extends RSBase<RSAdminJobStatisticSubItem, IRS.IRSAdminJobStatisticSubItem> implements IRS.IRSAdminJobStatisticSubItem {

	constructor() { super(RSAdminJobStatisticSubItem); }

	public id: string;
	public succeededCount: number;
	public failedCount: number;

	public update(dto: IRS.IRSAdminJobStatisticSubItem) { super.copy(dto); }
}

export class RSAdminPatchFilename 
		extends RSBase<RSAdminPatchFilename, IRS.IRSAdminPatchFilename> implements IRS.IRSAdminPatchFilename {

	constructor() { super(RSAdminPatchFilename); }

	public filename: string;

	public update(dto: IRS.IRSAdminPatchFilename) {super.copy(dto); }
}

export class RSAdminPatchGroupItemSummarised 
		extends RSBase<RSAdminPatchGroupItemSummarised, IRS.IRSAdminPatchGroupItemSummarised> implements IRS.IRSAdminPatchGroupItemSummarised {

	constructor() { super(RSAdminPatchGroupItemSummarised); }

	public cve: string;
	public title: string;
	public publiclyDisclosed: string;
	public exploited: string;
	public description: string;
	public url: string;
	public category: string;

	public update(dto: IRS.IRSAdminPatchGroupItemSummarised) { super.copy(dto); }
}

export class RSAdminRegisterTestEvent 
		extends RSBase<RSAdminRegisterTestEvent, IRS.IRSAdminRegisterTestEvent> implements IRS.IRSAdminRegisterTestEvent {

	constructor() { super(RSAdminRegisterTestEvent); }

	public eventId: string;

	public update(dto: IRS.IRSAdminRegisterTestEvent) { super.copy(dto); }
}

export class RSAdminServerInstance 
		extends RSBase<RSAdminServerInstance, IRS.IRSAdminServerInstance> implements IRS.IRSAdminServerInstance {

	constructor() { super(RSAdminServerInstance); }
	
	public id: string;
	public dateCreated: string;
	public customerName: string;
	public status: number;
	public websiteIPAddress: string;
	public jobVmIpAddress: string;
	public configuredDefaultFeatureSet: string;
	public configuredStorageId: string;
	public configuredConnectionStringMain: string;
	public configuredConnectionStringAdm: string;
	public heartbeatPackage: RSHeartbeatInfo;
	public heartbeatReport: RSHeartbeatInfo;
	public heartbeatManagement: RSHeartbeatInfo;
	public heartbeatImportSupport: RSHeartbeatInfo;
	public heartbeatConversion: RSHeartbeatInfo;
	public quotaStatistic: RSQuotaStatistic;
	public estimatedQueueLength: number;

	public update(dto: IRS.IRSAdminServerInstance) : void {
		this.id = dto.id;
		this.dateCreated = dto.dateCreated;
		this.customerName = dto.customerName;
		this.status = dto.status;
		this.websiteIPAddress = dto.websiteIPAddress;
		this.jobVmIpAddress = dto.jobVmIpAddress;
		this.configuredDefaultFeatureSet = dto.configuredDefaultFeatureSet;
		this.configuredStorageId = dto.configuredStorageId;
		this.configuredConnectionStringMain = dto.configuredConnectionStringMain;
		this.configuredConnectionStringAdm = dto.configuredConnectionStringAdm;
		if (this.heartbeatPackage == null && dto.heartbeatPackage != null)
			this.heartbeatPackage = new RSHeartbeatInfo();
		if (dto.heartbeatPackage == null)
			this.heartbeatPackage = null
		else
			this.heartbeatPackage.update(dto.heartbeatPackage);

		if (this.heartbeatReport == null && dto.heartbeatReport != null)
			this.heartbeatReport = new RSHeartbeatInfo();
		if (dto.heartbeatReport == null)
			this.heartbeatReport = null
		else
			this.heartbeatReport.update(dto.heartbeatReport);

		if (this.heartbeatManagement == null && dto.heartbeatManagement != null)
			this.heartbeatManagement = new RSHeartbeatInfo();
		if (dto.heartbeatManagement == null)
			this.heartbeatManagement = null
		else
			this.heartbeatManagement.update(dto.heartbeatManagement);

		if (this.heartbeatImportSupport == null && dto.heartbeatImportSupport != null)
			this.heartbeatImportSupport = new RSHeartbeatInfo();
		if (dto.heartbeatImportSupport == null)
			this.heartbeatImportSupport = null
		else
			this.heartbeatImportSupport.update(dto.heartbeatImportSupport);

		if (this.heartbeatConversion == null && dto.heartbeatConversion != null)
			this.heartbeatConversion = new RSHeartbeatInfo();
		if (dto.heartbeatConversion == null)
			this.heartbeatConversion = null
		else
			this.heartbeatConversion.update(dto.heartbeatConversion);

		if (this.quotaStatistic == null && dto.quotaStatistic != null)
			this.quotaStatistic = new RSQuotaStatistic();
		if (dto.quotaStatistic == null)
			this.quotaStatistic = null
		else
			this.quotaStatistic.update(dto.quotaStatistic);

		this.estimatedQueueLength = dto.estimatedQueueLength;

	}
}

export class RSFeatureType implements IRS.IRSFeatureType {

	public id: string;
	public description: string;

	public update(dto: IRS.IRSFeatureType) : void {
		this.id = dto.id;
		this.description = dto.description;
	}

	public deserialize(dto: IRS.IRSFeatureType) : RSFeatureType {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSFeatureType);
		pd.update(dto);
		return pd;
	}
}

export class RSAppArtifact implements IRS.IRSAppArtifact {

	public key: string;
	public artifactType: number;
	public platformNumber: number;
	public status: number;

	public update(dto: IRS.IRSAppArtifact) : void {
		this.key = dto.key;
		this.artifactType = dto.artifactType;
		this.platformNumber = dto.platformNumber;
		this.status = dto.status;
	}

	public deserialize(dto: IRS.IRSAppArtifact) : RSAppArtifact {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSAppArtifact);
		pd.update(dto);
		return pd;
	}
}

export class DetailLevel implements IRS.IDetailLevel {

	public update(dto: IRS.IDetailLevel) : void {
	}

	public deserialize(dto: IRS.IDetailLevel) : DetailLevel {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(DetailLevel);
		pd.update(dto);
		return pd;
	}
}

export class RSApplication implements IRS.IRSApplication {

	public id: string;
	public name: string;
	public filename: string;
	public path: string;
	public noAssess: boolean;
	public hasDotNetElements: boolean;
	public isComplete: boolean;
	public isPending: boolean;
	public hasFailed: boolean;
	public stagedOnly: boolean;
	public stagedButQueued: boolean;
	public productVersion: string;
	public manufacturer: string;
	public productName: string;
	public importDate: string;
	public complexity: number;
	public hasStandardsViolations: boolean;
	public hasBeenAssessed: boolean;
	public isMiddleware: boolean;
	public isVendor: boolean;
	public priority: number;
	public workflowStage: number;
	public workflowStageState: number;
	public assignedToId: string
	public assignedToName: string;
	public importPercentComplete: number;
	public architecture: string;
	public appId: string;
	public installerType: string;
	public dotNetVersion: string;
	public javaVersion: string;
	public originatedFrom: string;
	public hasTest: boolean;
	public hasTestSuccess: boolean;
	public hasTestFail: boolean;
	public testStatus: number;
	public testOutcome: string;
	public discoveryStatus: number;
	public discoveryOutcome: string;
	public discoveryVerified: boolean;
	public discoveryVerifiedUserId: string;
	public discoveryVerifiedUsername: string;
	public runtimeTestStatus: number;
	public repackagedStatus: number;
	public virtualisedStatus: number;
	public publishedState: number;
	public assessmentGroups: Array<RSAssessmentGroup> = [];
	public issues: Array<RSIssue> = [];
	public xcheckTests: Array<RSXCheckTest> = [];
	public xcheckTestsDictionary : { [oscp:string]: RSXCheckTest };
	public assessmentStates: Array<RSRAG> = [];
	public assessmentStatesDictionary : { [platformNumber:string]: RSRAG };

	public update(dto: IRS.IRSApplication) : void {
		this.id = dto.id;
		this.name = dto.name;
		this.filename = dto.filename;
		this.path = dto.path;
		this.noAssess = dto.noAssess;
		this.hasDotNetElements = dto.hasDotNetElements;
		this.isComplete = dto.isComplete;
		this.isPending = dto.isPending;
		this.hasFailed = dto.hasFailed;
		this.stagedOnly = dto.stagedOnly;
		this.stagedButQueued = dto.stagedButQueued;
		this.productVersion = dto.productVersion;
		this.manufacturer = dto.manufacturer;
		this.productName = dto.productName;
		this.importDate = dto.importDate;
		this.complexity = dto.complexity;
		this.hasStandardsViolations = dto.hasStandardsViolations;
		this.hasBeenAssessed = dto.hasBeenAssessed;
		this.isMiddleware = dto.isMiddleware;
		this.isVendor = dto.isVendor;
		this.priority = dto.priority;
		this.workflowStage = dto.workflowStage;
		this.workflowStageState = dto.workflowStageState;
		this.assignedToId = dto.assignedToId;
		this.assignedToName = dto.assignedToName;
		this.importPercentComplete = dto.importPercentComplete;
		this.architecture = dto.architecture;
		this.appId = dto.appId;
		this.installerType = dto.installerType;
		this.dotNetVersion = dto.dotNetVersion;
		this.javaVersion = dto.javaVersion;
		this.originatedFrom = dto.originatedFrom;
		this.hasTest = dto.hasTest;
		this.hasTestSuccess = dto.hasTestSuccess;
		this.hasTestFail = dto.hasTestFail;
		this.testStatus = dto.testStatus;
		this.testOutcome = dto.testOutcome;
		this.discoveryStatus = dto.discoveryStatus;
		this.discoveryOutcome = dto.discoveryOutcome;
		this.discoveryVerified = dto.discoveryVerified;
		this.discoveryVerifiedUserId = dto.discoveryVerifiedUserId;
		this.discoveryVerifiedUsername = dto.discoveryVerifiedUsername;
		this.runtimeTestStatus = dto.runtimeTestStatus;
		this.repackagedStatus = dto.repackagedStatus;
		this.virtualisedStatus = dto.virtualisedStatus;
		this.publishedState = dto.publishedState;
		DataMerger.MergeHelper.ListMerge(dto.assessmentGroups, this.assessmentGroups,  (i) => { return i.id },  RSAssessmentGroup);
		DataMerger.MergeHelper.ListMerge(dto.issues, this.issues,  (i) => { return i.id },  RSIssue);
		DataMerger.MergeHelper.ListMerge(dto.xcheckTests, this.xcheckTests,  (i) => { return i.id },  RSXCheckTest);
		this.xcheckTestsDictionary = {};
		this.xcheckTests.forEach(a => {
			this.xcheckTestsDictionary[a.id] = a;
		});
		DataMerger.MergeHelper.ListMerge(dto.assessmentStates, this.assessmentStates,  (i) => { return i.platformNumber },  RSRAG);
		this.assessmentStatesDictionary = {};
		this.assessmentStates.forEach(a => {
			this.assessmentStatesDictionary[a.platformNumber] = a;
		});
	}

	public deserialize(dto: IRS.IRSApplication) : RSApplication {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSApplication);
		pd.update(dto);
		return pd;
	}
}

export class RSApplicationCompanionFile implements IRS.IRSApplicationCompanionFile {

	public companionFileId: string;
	public filename: string;

	public update(dto: IRS.IRSApplicationCompanionFile) : void {
		this.companionFileId = dto.companionFileId;
		this.filename = dto.filename;
	}

	public deserialize(dto: IRS.IRSApplicationCompanionFile) : RSApplicationCompanionFile {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSApplicationCompanionFile);
		pd.update(dto);
		return pd;
	}
}

export class RSApplicationConflict implements IRS.IRSApplicationConflict {

	public id: string;
	public name: string;

	public update(dto: IRS.IRSApplicationConflict) : void {
		this.id = dto.id;
		this.name = dto.name;
	}

	public deserialize(dto: IRS.IRSApplicationConflict) : RSApplicationConflict {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSApplicationConflict);
		pd.update(dto);
		return pd;
	}
}

export class RSApplicationImportProgress implements IRS.IRSApplicationImportProgress {

	public applicationId: string;
	public percentage: number;

	public update(dto: IRS.IRSApplicationImportProgress) : void {
		this.applicationId = dto.applicationId;
		this.percentage = dto.percentage;
	}

	public deserialize(dto: IRS.IRSApplicationImportProgress) : RSApplicationImportProgress {
		if (!dto)
			return null;
		let pd =  RSClsFactory.factory(RSApplicationImportProgress);
		pd.update(dto);
		return pd;
	}
}

export class RSApplicationNote implements IRS.IRSApplicationNote {

	public id: string;
	public value: string;
	public placeholder: string;

	public update(dto: IRS.IRSApplicationNote) : void {
		this.id = dto.id;
		this.value = dto.value;
		this.placeholder = dto.placeholder;
	}

	public deserialize(dto: IRS.IRSApplicationNote) : RSApplicationNote {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSApplicationNote);
		pd.update(dto);
		return pd;
	}
}

export class RSApplicationOperationInfo implements IRS.IRSApplicationOperationInfo {


	public update(dto: IRS.IRSApplicationOperationInfo) : void {
	}

	public deserialize(dto: IRS.IRSApplicationOperationInfo) : RSApplicationOperationInfo {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSApplicationOperationInfo);
		pd.update(dto);
		return pd;
	}
}

export class RSApplicationPart implements IRS.IRSApplicationPart {

	public applicationId: string;
	public filename: string;

	public update(dto: IRS.IRSApplicationPart) : void {
		this.applicationId = dto.applicationId;
		this.filename = dto.filename;
	}

	public deserialize(dto: IRS.IRSApplicationPart) : RSApplicationPart {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSApplicationPart);
		pd.update(dto);
		return pd;
	}
}

export class RSApplicationPrerequisiteFile implements IRS.IRSApplicationPrerequisiteFile {

	public prerequisiteFileId: string;
	public filename: string;
	public arguments: string;

	public update(dto: IRS.IRSApplicationPrerequisiteFile) : void {
		this.prerequisiteFileId = dto.prerequisiteFileId;
		this.filename = dto.filename;
		this.arguments = dto.arguments;
	}

	public deserialize(dto: IRS.IRSApplicationPrerequisiteFile) : RSApplicationPrerequisiteFile {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSApplicationPrerequisiteFile);
		pd.update(dto);
		return pd;
	}
}

export class RSXCheckTest implements IRS.IRSXCheckTest {

	public id: string;
	public operatingSystemId: string;
	public checkpoint: string;
	public result: number;
	public shortcutScreenshotComparisonResult: number;

	public update(dto: IRS.IRSXCheckTest) : void {
		this.id = dto.id;
		this.operatingSystemId = dto.operatingSystemId;
		this.checkpoint = dto.checkpoint;
		this.result = dto.result;
		this.shortcutScreenshotComparisonResult = dto.shortcutScreenshotComparisonResult;
	}

	public deserialize(dto: IRS.IRSXCheckTest) : RSXCheckTest {
		if (!dto)
			return null;
		let pd =RSClsFactory.factory(RSXCheckTest);
		pd.update(dto);
		return pd;
	}
}

export class RSAppNoteType implements IRS.IRSAppNoteType {

	public id: string;
	public name: string;
	public cls: string;

	public update(dto: IRS.IRSAppNoteType) : void {
		this.id = dto.id;
		this.name = dto.name;
		this.cls = dto.cls;
	}

	public deserialize(dto: IRS.IRSAppNoteType) : RSAppNoteType {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSAppNoteType);
		pd.update(dto);
		return pd;
	}
}

export class RSAssessmentGroup implements IRS.IRSAssessmentGroup {

	public id: string;
	public name: string;
	public checks: Array<RSCheck> = [];
	public isDisabled: boolean;
	public assessmentStates: Array<RSRAG> = [];
	public assessmentStatesDictionary : { [platformNumber:string]: RSRAG };

	public update(dto: IRS.IRSAssessmentGroup) : void {
		this.id = dto.id;
		this.name = dto.name;
		DataMerger.MergeHelper.ListMerge(dto.checks, this.checks,  (i) => { return i.checkId },  RSCheck);
		this.isDisabled = dto.isDisabled;
		DataMerger.MergeHelper.ListMerge(dto.assessmentStates, this.assessmentStates,  (i) => { return i.platformNumber },  RSRAG);
		this.assessmentStatesDictionary = {};
		this.assessmentStates.forEach(a => {
			this.assessmentStatesDictionary[a.platformNumber] = a;
		});
	}

	public deserialize(dto: IRS.IRSAssessmentGroup) : RSAssessmentGroup {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSAssessmentGroup);
		pd.update(dto);
		return pd;
	}
}

export class RSBrowser implements IRS.IRSBrowser {

	public id: string;
	public display: string;

	public update(dto: IRS.IRSBrowser) : void {
		this.id = dto.id;
		this.display = dto.display;
	}

	public deserialize(dto: IRS.IRSBrowser) : RSBrowser {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSBrowser);
		pd.update(dto);
		return pd;
	}
}

export class RSBulletInfo implements IRS.IRSBulletInfo {

	public itemNo: number;
	public label1: string;
	public label2: string;
	public installerInfo: Array<RSInstaller> = [];

	public update(dto: IRS.IRSBulletInfo) : void {
		this.itemNo = dto.itemNo;
		this.label1 = dto.label1;
		this.label2 = dto.label2;
		DataMerger.MergeHelper.ListMerge(dto.installerInfo, this.installerInfo,  (i) => { return i.id },  RSInstaller);
	}

	public deserialize(dto: IRS.IRSBulletInfo) : RSBulletInfo {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSBulletInfo);
		pd.update(dto);
		return pd;
	}
}

export interface IRSCheck
{
	checkId: string;
}

export class RSCheck implements IRS.IRSCheck, IRSCheck {

	public checkId: string;
	public name: string;
	public category: number;
	public description: string;
	public isEnabled: boolean;
	public appliesToUpdatePeriod: string;
	public assessmentStates: Array<RSRAG> = [];
	public assessmentStatesDictionary : { [platformNumber:string]: RSRAG };

	public update(dto: IRS.IRSCheck) : void {
		this.checkId = dto.checkId;
		this.name = dto.name;
		this.category = dto.category;
		this.description = dto.description;
		this.isEnabled = dto.isEnabled;
		this.appliesToUpdatePeriod = dto.appliesToUpdatePeriod;
		DataMerger.MergeHelper.ListMerge(dto.assessmentStates, this.assessmentStates,  (i) => { return i.platformNumber },  RSRAG);
		this.assessmentStatesDictionary = {};
		this.assessmentStates.forEach(a => {
			this.assessmentStatesDictionary[a.platformNumber] = a;
		});
	}

	public deserialize(dto: IRS.IRSCheck) : RSCheck {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSCheck);
		pd.update(dto);
		return pd;
	}
}

export class RSCheckRuleTemplateInfo implements IRS.IRSCheckRuleTemplateInfo {

	public templateId: string;
	public type: string;

	public update(dto: IRS.IRSCheckRuleTemplateInfo) : void {
		this.templateId = dto.templateId;
		this.type = dto.type;
	}

	public deserialize(dto: IRS.IRSCheckRuleTemplateInfo) : RSCheckRuleTemplateInfo {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSCheckRuleTemplateInfo);
		pd.update(dto);
		return pd;
	}
}

export class RSChkCandStateItem implements IRS.IRSChkCandStateItem {

	public key: number;
	public s1: number;
	public s2: number;
	public s3: number;
	public s4: number;
	public s5: number;

	public update(dto: IRS.IRSChkCandStateItem) : void {
		this.key = dto.key;
		this.s1 = dto.s1;
		this.s2 = dto.s2;
		this.s3 = dto.s3;
		this.s4 = dto.s4;
		this.s5 = dto.s5;
	}

	public deserialize(dto: IRS.IRSChkCandStateItem) : RSChkCandStateItem {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSChkCandStateItem);
		pd.update(dto);
		return pd;
	}
}

export class RSConversionTask implements IRS.IRSConversionTask {

	public id: string;
	public type: string;
	public typeDescription: string;
	public percentageComplete: number;
	public transactionId: number;
	public applicationName: string;
	public arguments: string;
	public importDate: string;
	public dateTaskCreated: string;
	public dateTaskStarted: string;
	public dateTaskCompleted: string;
	public secondsInProgress: number;
	public usesRepack: boolean;
	public useFixTransform: boolean;
	public hasUI: boolean;
	public status: number;
	public statusText: string;

	public update(dto: IRS.IRSConversionTask) : void {
		this.id = dto.id;
		this.type = dto.type;
		this.typeDescription = dto.typeDescription;
		this.percentageComplete = dto.percentageComplete;
		this.transactionId = dto.transactionId;
		this.applicationName = dto.applicationName;
		this.arguments = dto.arguments;
		this.importDate = dto.importDate;
		this.dateTaskCreated = dto.dateTaskCreated;
		this.dateTaskStarted = dto.dateTaskStarted;
		this.dateTaskCompleted = dto.dateTaskCompleted;
		this.secondsInProgress = dto.secondsInProgress;
		this.usesRepack = dto.usesRepack;
		this.useFixTransform = dto.useFixTransform;
		this.hasUI = dto.hasUI;
		this.status = dto.status;
		this.statusText = dto.statusText;
	}

	public deserialize(dto: IRS.IRSConversionTask) : RSConversionTask {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSConversionTask);
		pd.update(dto);
		return pd;
	}
}

export class RSConversion_GetJobOutput implements IRS.IRSConversion_GetJobOutput {

	public filename: string;
	public outputName: string;

	public update(dto: IRS.IRSConversion_GetJobOutput) : void {
		this.filename = dto.filename;
		this.outputName = dto.outputName;
	}

	public deserialize(dto: IRS.IRSConversion_GetJobOutput) : RSConversion_GetJobOutput {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSConversion_GetJobOutput);
		pd.update(dto);
		return pd;
	}
}

export class RSConversion_GetOutput implements IRS.IRSConversion_GetOutput {

	public filename: string;
	public outputName: string;

	public update(dto: IRS.IRSConversion_GetOutput) : void {
		this.filename = dto.filename;
		this.outputName = dto.outputName;
	}

	public deserialize(dto: IRS.IRSConversion_GetOutput) : RSConversion_GetOutput {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSConversion_GetOutput);
		pd.update(dto);
		return pd;
	}
}

export class RSConversion_GetRetainedFile implements IRS.IRSConversion_GetRetainedFile {

	public filename: string;
	public outputName: string;

	public update(dto: IRS.IRSConversion_GetRetainedFile) : void {
		this.filename = dto.filename;
		this.outputName = dto.outputName;
	}

	public deserialize(dto: IRS.IRSConversion_GetRetainedFile) : RSConversion_GetRetainedFile {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSConversion_GetRetainedFile);
		pd.update(dto);
		return pd;
	}
}

export class RSExecuteClickerScript implements IRS.IRSExecuteClickerScript {

	public jobId: string;
	public exchangeId: string;

	public update(dto: IRS.IRSExecuteClickerScript) : void {
		this.jobId = dto.jobId;
		this.exchangeId = dto.exchangeId;
	}

	public deserialize(dto: IRS.IRSExecuteClickerScript) : RSExecuteClickerScript {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSExecuteClickerScript);
		pd.update(dto);
		return pd;
	}
}

export class RSConvertApplication implements IRS.IRSConvertApplication {

	public jobId: string;

	public update(dto: IRS.IRSConvertApplication) : void {
		this.jobId = dto.jobId;
	}

	public deserialize(dto: IRS.IRSConvertApplication) : RSConvertApplication {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSConvertApplication);
		pd.update(dto);
		return pd;
	}
}

export class RSGetConversionTaskLaunchInfo implements IRS.IRSGetConversionTaskLaunchInfo {

	public preconditions: Array<RSConversionTaskLaunchInfoPrecondition>= [];

	public update(dto: IRS.IRSGetConversionTaskLaunchInfo) : void {
		DataMerger.MergeHelper.ListMerge(dto.preconditions, this.preconditions,  (i) => { return i.id },  RSConversionTaskLaunchInfoPrecondition);
	}

	public deserialize(dto: IRS.IRSGetConversionTaskLaunchInfo) : RSGetConversionTaskLaunchInfo {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetConversionTaskLaunchInfo);
		pd.update(dto);
		return pd;
	}
}

export class RSConversionTaskLaunchInfoPrecondition implements IRS.IRSConversionTaskLaunchInfoPrecondition {

	public id:string;
	public description:string;
	public isSatisfied: boolean;

	public update(dto: IRS.IRSConversionTaskLaunchInfoPrecondition) : void {
		this.id = dto.id;
		this.description = dto.description;
		this.isSatisfied = dto.isSatisfied;
	}

	public deserialize(dto: IRS.IRSConversionTaskLaunchInfoPrecondition) : RSConversionTaskLaunchInfoPrecondition {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSConversionTaskLaunchInfoPrecondition);
		pd.update(dto);
		return pd;
	}
}

export class RSConvTimeLineStep implements IRS.IRSConvTimeLineStep {

	public id: number;
	public title: string;
	public contentTitle: string;
	public contentTitleIcon: string;
	public state: number;
	public isInProgress: boolean;
	public isDone: boolean;

	public update(dto: IRS.IRSConvTimeLineStep) : void {
		this.id = dto.id;
		this.title = dto.title;
		this.contentTitle = dto.contentTitle;
		this.contentTitleIcon = dto.contentTitleIcon;
		this.state = dto.state;
		this.isInProgress = dto.isInProgress;
		this.isDone = dto.isDone;
	}

	public deserialize(dto: IRS.IRSConvTimeLineStep) : RSConvTimeLineStep {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSConvTimeLineStep);
		pd.update(dto);
		return pd;
	}
}

export class RSCreateProjectRule implements IRS.IRSCreateProjectRule {

	public id: string;
	public projectRuleId: string;

	public update(dto: IRS.IRSCreateProjectRule) : void {
		this.id = dto.id;
		this.projectRuleId = dto.projectRuleId;
	}

	public deserialize(dto: IRS.IRSCreateProjectRule) : RSCreateProjectRule {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSCreateProjectRule);
		pd.update(dto);
		return pd;
	}
}

export class RSCreateUserProfileColumnSet implements IRS.IRSCreateUserProfileColumnSet {

	public createdProfileId: string;
	public profiles: Array<RSUserColumnProfile> = [];

	public update(dto: IRS.IRSCreateUserProfileColumnSet) : void {
		this.createdProfileId = dto.createdProfileId;
		DataMerger.MergeHelper.ListMerge(dto.profiles, this.profiles,  (i) => { return i.profileId },  RSUserColumnProfile);
	}

	public deserialize(dto: IRS.IRSCreateUserProfileColumnSet) : RSCreateUserProfileColumnSet {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSCreateUserProfileColumnSet);
		pd.update(dto);
		return pd;
	}
}

export class RSCustomRule implements IRS.IRSCustomRule {

	public ruleId: string;
	public ruleTitle: string;
	public ruleDescription: string;
	public messages: Array<RSCustomRuleMsg> = [];
	public hasAutoFix: boolean;
	public isRegistered: boolean;

	public update(dto: IRS.IRSCustomRule) : void {
		this.ruleId = dto.ruleId;
		this.ruleTitle = dto.ruleTitle;
		this.ruleDescription = dto.ruleDescription;
		DataMerger.MergeHelper.ListMerge(dto.messages, this.messages,  (i) => { return i.id },  RSCustomRuleMsg);
		this.hasAutoFix = dto.hasAutoFix;
		this.isRegistered = dto.isRegistered;
	}

	public deserialize(dto: IRS.IRSCustomRule) : RSCustomRule {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSCustomRule);
		pd.update(dto);
		return pd;
	}
}

export class RSCustomRuleElement implements IRS.IRSCustomRuleElement {

	public id: string;
	public hasContextValue: boolean;
	public title: string;
	public description: string;
	public fixId: string;
	public isExcludedForVendorPackages: boolean;
	public refreshOnAppUpdate: boolean;
	public children: Array<RSCustomRuleElement> = [];

	public update(dto: IRS.IRSCustomRuleElement) : void {
		this.id = dto.id;
		this.hasContextValue = dto.hasContextValue;
		this.title = dto.title;
		this.description = dto.description;
		this.fixId = dto.fixId;
		this.isExcludedForVendorPackages = dto.isExcludedForVendorPackages;
		this.refreshOnAppUpdate = dto.refreshOnAppUpdate;
		DataMerger.MergeHelper.ListMerge(dto.children, this.children,  (i) => { return i.id },  RSCustomRuleElement);
	}

	public deserialize(dto: IRS.IRSCustomRuleElement) : RSCustomRuleElement {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSCustomRuleElement);
		pd.update(dto);
		return pd;
	}
}

export class RSCustomRuleGroup implements IRS.IRSCustomRuleGroup {

	public id: string;
	public name: string;
	public description: string;
	public elements: Array<RSCustomRuleElement> = [];

	public update(dto: IRS.IRSCustomRuleGroup) : void {
		this.id = dto.id;
		this.name = dto.name;
		this.description = dto.description;
		DataMerger.MergeHelper.ListMerge(dto.elements, this.elements,  (i) => { return i.id },  RSCustomRuleElement);
	}

	public deserialize(dto: IRS.IRSCustomRuleGroup) : RSCustomRuleGroup {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSCustomRuleGroup);
		pd.update(dto);
		return pd;
	}
}

export class RSCustomRuleMsg implements IRS.IRSCustomRuleMsg {

	public id: string;
	public text: string;

	public update(dto: IRS.IRSCustomRuleMsg) : void {
		this.id = dto.id;
		this.text = dto.text;
	}

	public deserialize(dto: IRS.IRSCustomRuleMsg) : RSCustomRuleMsg {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSCustomRuleMsg);
		pd.update(dto);
		return pd;
	}
}

export class RSCustomRuleSet implements IRS.IRSCustomRuleSet {

	public customRuleId: string;
	public rules: Array<RSCustomRule> = [];

	public update(dto: IRS.IRSCustomRuleSet) : void {
		this.customRuleId = dto.customRuleId;
		DataMerger.MergeHelper.ListMerge(dto.rules, this.rules,  (i) => { return i.ruleId },  RSCustomRule);
	}

	public deserialize(dto: IRS.IRSCustomRuleSet) : RSCustomRuleSet {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSCustomRuleSet);
		pd.update(dto);
		return pd;
	}
}

export class RSDependencyItem implements IRS.IRSDependencyItem {

	public id: string;
	public name: string;
	public isWindowsFeature: boolean;

	public update(dto: IRS.IRSDependencyItem) : void {
		this.id = dto.id;
		this.name = dto.name;
		this.isWindowsFeature = dto.isWindowsFeature;
	}

	public deserialize(dto: IRS.IRSDependencyItem) : RSDependencyItem {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSDependencyItem);
		pd.update(dto);
		return pd;
	}
}

export class RSDistributionItem implements IRS.IRSDistributionItem {

	public id: number;
	public label: string;
	public count: number;
	public title: string;

	public update(dto: IRS.IRSDistributionItem) : void {
		this.id = dto.id;
		this.label = dto.label;
		this.count = dto.count;
		this.title = dto.title;
	}

	public deserialize(dto: IRS.IRSDistributionItem) : RSDistributionItem {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSDistributionItem);
		pd.update(dto);
		return pd;
	}
}

export class RSDotNetVersion implements IRS.IRSDotNetVersion {

	public id: string;
	public display: string;

	public update(dto: IRS.IRSDotNetVersion) : void {
		this.id = dto.id;
		this.display = dto.display;
	}

	public deserialize(dto: IRS.IRSDotNetVersion) : RSDotNetVersion {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSDotNetVersion);
		pd.update(dto);
		return pd;
	}
}

export class RSDownloadApplicationDocumentation implements IRS.IRSDownloadApplicationDocumentation {

	public filename: string;
	public outputName: string;

	public update(dto: IRS.IRSDownloadApplicationDocumentation) : void {
		this.filename = dto.filename;
		this.outputName = dto.outputName;
	}

	public deserialize(dto: IRS.IRSDownloadApplicationDocumentation) : RSDownloadApplicationDocumentation {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSDownloadApplicationDocumentation);
		pd.update(dto);
		return pd;
	}
}

export class RSDriveLink implements IRS.IRSDriveLink {

	public driveLinkId: string;
	public projectId: string;
	public driveLinkType: number;
	public definition: string;
	public dateCreated: string;
	public enabled: boolean;
	public display: string;

	public update(dto: IRS.IRSDriveLink) : void {
		this.driveLinkId = dto.driveLinkId;
		this.projectId = dto.projectId;
		this.driveLinkType = dto.driveLinkType;
		this.definition = dto.definition;
		this.dateCreated = dto.dateCreated;
		this.enabled = dto.enabled;
		this.display = dto.display;
	}

	public deserialize(dto: IRS.IRSDriveLink) : RSDriveLink {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSDriveLink);
		pd.update(dto);
		return pd;
	}
}

export class RSDriveLinkMapItem implements IRS.IRSDriveLinkMapItem {

	public applicationId: string;
	public path: string;
	public secondaries: Array<string> = [];
	public selectedSecondaries: Array<string> = [];
	public importedTransforms: string;
	public status: number;
	public importPhase: number;
	public dateCreated: Date;

	public update(dto: IRS.IRSDriveLinkMapItem) : void {
		this.applicationId = dto.applicationId;
		this.path = dto.path;
		this.secondaries = dto.secondaries;
		this.selectedSecondaries = dto.selectedSecondaries;
		this.importedTransforms = dto.importedTransforms;
		this.status = dto.status;
		this.importPhase = dto.importPhase;
		this.dateCreated = dto.dateCreated;
	}

	public deserialize(dto: IRS.IRSDriveLinkMapItem) : RSDriveLinkMapItem {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSDriveLinkMapItem);
		pd.update(dto);
		return pd;
	}
}

export class Fudge implements IRS.IFudge {

	public update(dto: IRS.IFudge) : void {
	}

	public deserialize(dto: IRS.IFudge) : Fudge {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(Fudge);
		pd.update(dto);
		return pd;
	}
}

export class RSEvaluationLogEntry implements IRS.IRSEvaluationLogEntry {

	public key: string;
	public timestamp: string;
	public outcome: string;
	public text: string;
	public type: number;
	public subType: string;

	public update(dto: IRS.IRSEvaluationLogEntry) : void {
		this.key = dto.key;
		this.timestamp = dto.timestamp;
		this.outcome = dto.outcome;
		this.text = dto.text;
		this.type = dto.type;
		this.subType = dto.subType;
	}

	public deserialize(dto: IRS.IRSEvaluationLogEntry) : RSEvaluationLogEntry {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSEvaluationLogEntry);
		pd.update(dto);
		return pd;
	}
}

export class RSEvaluationMachine implements IRS.IRSEvaluationMachine {

	public machineId: string;
	public requestId: string;
	public applicationId: string;
	public applicationName: string;
	public state: number;
	public userEmail: string;
	public progressPercentage: number;
	public timestamp: string;

	public update(dto: IRS.IRSEvaluationMachine) : void {
		this.machineId = dto.machineId;
		this.requestId = dto.requestId;
		this.applicationId = dto.applicationId;
		this.applicationName = dto.applicationName;
		this.state = dto.state;
		this.userEmail = dto.userEmail;
		this.progressPercentage = dto.progressPercentage;
		this.timestamp = dto.timestamp;
	}

	public deserialize(dto: IRS.IRSEvaluationMachine) : RSEvaluationMachine {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSEvaluationMachine);
		pd.update(dto);
		return pd;
	}
}

export class RSEvaluationMachineRequest implements IRS.IRSEvaluationMachineRequest {

	public requestId: string;
	public machineId: string;
	public applicationId: string;
	public applicationName: string;
	public userId: string;
	public userEmail: string;
	public timestamp: string;

	public update(dto: IRS.IRSEvaluationMachineRequest) : void {
		this.requestId = dto.requestId;
		this.machineId = dto.machineId;
		this.applicationId = dto.applicationId;
		this.applicationName = dto.applicationName;
		this.userId = dto.userId;
		this.userEmail = dto.userEmail;
		this.timestamp = dto.timestamp;
	}

	public deserialize(dto: IRS.IRSEvaluationMachineRequest) : RSEvaluationMachineRequest {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSEvaluationMachineRequest);
		pd.update(dto);
		return pd;
	}
}

export class RSExecuteQuery implements IRS.IRSExecuteQuery {

	public jobId: string;

	public update(dto: IRS.IRSExecuteQuery) : void {
		this.jobId = dto.jobId;
	}

	public  deserialize(dto: IRS.IRSExecuteQuery) : RSExecuteQuery {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSExecuteQuery);
		pd.update(dto);
		return pd;
	}
}

export class RSExecuteType2Query implements IRS.IRSExecuteType2Query {

	public jobId: string;

	public update(dto: IRS.IRSExecuteType2Query) : void {
		this.jobId = dto.jobId;
	}

	public deserialize(dto: IRS.IRSExecuteType2Query) : RSExecuteType2Query {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSExecuteType2Query);
		pd.update(dto);
		return pd;
	}
}

export class RSExtractedMsi implements IRS.IRSExtractedMsi {

	public filename: string;
	public arguments: Array<RSNameValue> = [];
	public productCode: string;
	public wasImportedDuringCapture: boolean;
	public isChecked: boolean;
	public canBeImported: boolean;
	public alreadyImported: boolean;
	public containsInfo: boolean;

	public update(dto: IRS.IRSExtractedMsi) : void {
		this.filename = dto.filename;
		DataMerger.MergeHelper.ListMerge(dto.arguments, this.arguments,  (i) => { return i.name },  RSNameValue);
		this.productCode = dto.productCode;
		this.wasImportedDuringCapture = dto.wasImportedDuringCapture;
		this.isChecked = dto.isChecked;
		this.canBeImported = dto.canBeImported;
		this.alreadyImported = dto.alreadyImported;
		this.containsInfo = dto.containsInfo;
	}

	public deserialize(dto: IRS.IRSExtractedMsi) : RSExtractedMsi {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSExtractedMsi);
		pd.update(dto);
		return pd;
	}
}

export class RSGeneratedOutputItem implements IRS.IRSGeneratedOutputItem {

	public key: string;
	public type: number;
	public subType: string;
	public title: string;
	public status: number;
	public generated: string;
	public flags: string;
	public url: string;
	public isSas: boolean;
	public tooltip: string;
	public jobId: string;
	public platforms: Array<number> = [];

	public update(dto: IRS.IRSGeneratedOutputItem) : void {
		this.key = dto.key;
		this.type = dto.type;
		this.subType = dto.subType;
		this.title = dto.title;
		this.status = dto.status;
		this.generated = dto.generated;
		this.flags = dto.flags;
		this.url = dto.url;
		this.isSas = dto.isSas;
		this.tooltip = dto.tooltip;
		this.jobId = dto.jobId;
		this.platforms = dto.platforms;
	}

	public deserialize(dto: IRS.IRSGeneratedOutputItem) : RSGeneratedOutputItem {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGeneratedOutputItem);
		pd.update(dto);
		return pd;
	}
}

export class RSGetActionDetails implements IRS.IRSGetActionDetails {

	public jobId: string;
	public applicationId: string;
	public projectId: string;
	public parentJobId: string;
	public foundOk: boolean;
	public action: string;
	public actionStatus: number;
	public actionError: string;
	public type: string;
	public jobType: number;
	public task: RSGetConversionTask;
	public eval: RSGetEvalMachine;
	public isPvadApplication: boolean;
	public xCheckResults: Array<RSXCheckResult> = [];

	public update(dto: IRS.IRSGetActionDetails) : void {
		this.jobId = dto.jobId;
		this.applicationId = dto.applicationId;
		this.projectId = dto.projectId;
		this.parentJobId = dto.parentJobId;
		this.foundOk = dto.foundOk;
		this.action = dto.action;
		this.jobType = dto.jobType;
		this.actionStatus = dto.actionStatus;
		this.actionError = dto.actionError;
		this.type = dto.type;
		if (this.task == null && dto.task != null)
			this.task = new RSGetConversionTask();
		if (dto.task == null)
			this.task = null
		else
			this.task.update(dto.task);

		if (this.eval == null && dto.eval != null)
			this.eval = new RSGetEvalMachine();
		if (dto.eval == null)
			this.eval = null
		else
			this.eval.update(dto.eval);

		this.isPvadApplication = dto.isPvadApplication;
		DataMerger.MergeHelper.ListMerge(dto.xCheckResults, this.xCheckResults,  (i) => { return i.id },  RSXCheckResult);
	}

	public deserialize(dto: IRS.IRSGetActionDetails) : RSGetActionDetails {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetActionDetails);
		pd.update(dto);
		return pd;
	}
}

export class RSAdminGetOperatingSystems implements IRS.IRSAdminGetOperatingSystems {

	public items: Array<RSOperatingSystem> = [];

	public update(dto: IRS.IRSAdminGetOperatingSystems) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSOperatingSystem);
	}

	public deserialize(dto: IRS.IRSAdminGetOperatingSystems) : RSAdminGetOperatingSystems {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSAdminGetOperatingSystems);
		pd.update(dto);
		return pd;
	}
}

export class RSAdminGetVirtualMachines implements IRS.IRSAdminGetVirtualMachines {

	public items: Array<RSAdminVirtualMachine> = [];

	public update(dto: IRS.IRSAdminGetVirtualMachines) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSAdminVirtualMachine);
	}

	public deserialize(dto: IRS.IRSAdminGetVirtualMachines) : RSAdminGetVirtualMachines {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSAdminGetVirtualMachines);
		pd.update(dto);
		return pd;
	}
}

export class RSAdminVirtualMachine implements IRS.IRSAdminVirtualMachine {

	public id: string;
	public runningState: string;

	public update(dto: IRS.IRSAdminVirtualMachine) : void {
		this.id = dto.id;
		this.runningState = dto.runningState;
	}

	public deserialize(dto: IRS.IRSAdminVirtualMachine) : RSAdminVirtualMachine {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSAdminVirtualMachine);
		pd.update(dto);
		return pd;
	}
}

export class RSGetActionImages implements IRS.IRSGetActionImages {

	public images: Array<RSImageItem> = [];

	public update(dto: IRS.IRSGetActionImages) : void {
		DataMerger.MergeHelper.ListMerge(dto.images, this.images,  (i) => { return i.id },  RSImageItem);
	}

	public deserialize(dto: IRS.IRSGetActionImages) : RSGetActionImages {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetActionImages);
		pd.update(dto);
		return pd;
	}
}

export class RSGetActionsForApplication implements IRS.IRSGetActionsForApplication {

	public items: Array<RSAction> = [];
	public mostRecentId: string;
	public siblingInstallers: Array<RSInstaller> = [];

	public update(dto: IRS.IRSGetActionsForApplication) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSAction);
		this.mostRecentId = dto.mostRecentId;
		DataMerger.MergeHelper.ListMerge(dto.siblingInstallers, this.siblingInstallers,  (i) => { return i.id },  RSInstaller);
	}

	public deserialize(dto: IRS.IRSGetActionsForApplication) : RSGetActionsForApplication {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetActionsForApplication);
		pd.update(dto);
		return pd;
	}
}

export class RSGetActionsForProject implements IRS.IRSGetActionsForProject {

	public items: Array<RSAction> = [];

	public update(dto: IRS.IRSGetActionsForProject) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSAction);
	}

	public deserialize(dto: IRS.IRSGetActionsForProject) : RSGetActionsForProject {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetActionsForProject);
		pd.update(dto);
		return pd;
	}
}

export class RSGetApplication implements IRS.IRSGetApplication {

	public id: string;
	public name: string;
	public status:number;
	public filename: string;
	public projectId:string;
	public path: string;
	public icon: string;
	public complexity: number;
	public compoundVersion: string;
	public productName: string;
	public manufacturer: string;
	public friendlyName: string;
	public importDate: string;
	public recommendedSwitches: string;
	public recommendedSilentSwitches: string;
	public lastRepackSwitches: string;
	public noAssess: boolean;
	public isProjectOwner: boolean;
	public isProjectContributor: boolean;
	public hasDotNetElements: boolean;
	public hasJavaElements: boolean;
	public importedTransforms: string;
	public hasBeenAssessed: boolean;
	public fixingPermitted: boolean;
	public productDetailsDefined: boolean;
	public isMsi: boolean;
	public isUnspecified: boolean;
	public priority: number;
	public workflowStage: number;
	public workflowStageState: number;
	public assignee: string;
	public isFromMappedDrive: boolean;
	public architecture: number;
	public architectureName:string;
	public installPath: string;
	public exeFlavour: number;
	public exeFlavourDescription: string;
	public exeFlavourUrl: string;
	public exeFlavourRepackageWarning: boolean;
	public hasExtractTransform: boolean;
	public defaultMsiArgs: string;
	public defaultMsiArgsSilent: string;
	public arguments: string;
	public argumentsSilent:string;
	public isMiddleware: boolean;
	public isVendor: boolean;
	public hasUserInterface: number;
	public sourceAvailabilityState: number;
	public hasValidAppId: boolean;
	public invalidAppIdError: string;
	public needsSilentArgs: boolean;
	public hasSilentArgs: boolean;
	public canPublishOriginal: boolean;
	public cannotPublishOriginalReasons: Array<string>;	
	public hasInvalidPackageId: boolean;
	public cleanedPackageId: string;
	public discoveryJobGeneratedSnapshot: boolean;
	public discoveryJobSnapshotContainedDependencies: boolean;
	public publishedState: number;
	public isCompatibilityReportSynchronised: boolean;
	public generatedOutputItems: Array<RSGeneratedOutputItem> = [];
	public repackageStatus: number;
	public isApplicationReadyForConversion: boolean;
	public repackedMsiImported: boolean;
	public extractedFromApplicationId: string;
	public extractedFromApplicationName: string;
	public fixingJobInfo: RSFixingJobInfo;
	public additionalVirtualisationConnectionGroupsRequired: boolean;
	public extractedApplications: Array<RSApplicationPart> = [];
	public notes: Array<RSApplicationNote> = [];
	public issueSummarisations: Array<RSIssueSummarisation> = [];
	public issueSummarisationsDictionary : { [platformNumber:string]: RSIssueSummarisation };
	public platforms: Array<RSPlatform> = [];
	public assessmentGroups: Array<RSAssessmentGroup> = [];
	public issues: Array<RSIssue> = [];
	public customRules: Array<RSCustomRuleSet> = [];
	public artifacts: Array<RSAppArtifact> = [];
	public companionFiles: Array<RSApplicationCompanionFile> = [];
	public prerequisiteFiles: Array<RSApplicationPrerequisiteFile> = [];
	public installDependencies: Array<RSApplicationInstallDependency> = [];
	public platformsWithFixTransform: Array<number> = [];
	public hasResponseTransform: boolean;
	public canUpdateVirtualisationReadme: boolean;
	public updatedActivity: string;
	public noChange: boolean;
	public clickerScript: string;
	public assessmentStates: Array<RSRAG> = [];
	public assessmentStatesDictionary : { [platformNumber:string]: RSRAG };
	public actions: Array<RSAction> = [];
	public outputActions: Array<RSAction> = [];
	public mostRecentActionId : string;
	public isMsp: boolean;
	public isPartiallyImported: boolean;
	public patchParts : Array<RSPartialPatchItem> =[];

	public update(dto: IRS.IRSGetApplication) : void {
		this.id = dto.id;
		this.name = dto.name;
		this.status =dto.status;
		this.filename = dto.filename;
		this.projectId = dto.projectId;
		this.path = dto.path;
		this.icon = dto.icon;
		this.complexity = dto.complexity;
		this.compoundVersion = dto.compoundVersion;
		this.productName = dto.productName;
		this.manufacturer = dto.manufacturer;
		this.friendlyName = dto.friendlyName;
		this.importDate = dto.importDate;
		this.recommendedSilentSwitches = dto.recommendedSilentSwitches;
		this.recommendedSwitches = dto.recommendedSwitches;
		this.lastRepackSwitches = dto.lastRepackSwitches;
		this.noAssess = dto.noAssess;
		this.isProjectOwner = dto.isProjectOwner;
		this.isProjectContributor = dto.isProjectContributor;
		this.hasDotNetElements = dto.hasDotNetElements;
		this.hasJavaElements = dto.hasJavaElements;
		this.importedTransforms = dto.importedTransforms;
		this.hasBeenAssessed = dto.hasBeenAssessed;
		this.fixingPermitted = dto.fixingPermitted;
		this.productDetailsDefined = dto.productDetailsDefined;
		this.isMsi = dto.isMsi;
		this.isUnspecified = dto.isUnspecified;
		this.priority = dto.priority;
		this.workflowStage = dto.workflowStage;
		this.workflowStageState = dto.workflowStageState;
		this.assignee = dto.assignee;
		this.isFromMappedDrive = dto.isFromMappedDrive;
		this.architecture = dto.architecture;
		this.architectureName = dto.architectureName;
		this.installPath = dto.installPath;
		this.exeFlavour = dto.exeFlavour;
		this.exeFlavourDescription = dto.exeFlavourDescription;
		this.exeFlavourUrl = dto.exeFlavourUrl;
		this.exeFlavourRepackageWarning = dto.exeFlavourRepackageWarning;
		this.hasExtractTransform = dto.hasExtractTransform;
		this.defaultMsiArgsSilent = dto.defaultMsiArgsSilent;
		this.defaultMsiArgs = dto.defaultMsiArgs;
		this.arguments = dto.arguments;
		this.argumentsSilent = dto.argumentsSilent;
		this.isMiddleware = dto.isMiddleware;
		this.isVendor = dto.isVendor;
		this.hasValidAppId = dto.hasValidAppId;
		this.invalidAppIdError = dto.invalidAppIdError;
		this.hasSilentArgs = dto.hasSilentArgs;
		this.needsSilentArgs = dto.needsSilentArgs;
		this.canPublishOriginal = dto.canPublishOriginal;
		this.cannotPublishOriginalReasons = dto.cannotPublishOriginalReasons;
		this.hasInvalidPackageId = dto.hasInvalidPackageId;
		this.hasUserInterface = dto.hasUserInterface;
		this.sourceAvailabilityState = dto.sourceAvailabilityState;
		this.cleanedPackageId = dto.cleanedPackageId;
		this.discoveryJobGeneratedSnapshot= dto.discoveryJobGeneratedSnapshot;
		this.discoveryJobSnapshotContainedDependencies = dto.discoveryJobSnapshotContainedDependencies;
		this.publishedState = dto.publishedState;
		this.isCompatibilityReportSynchronised = dto.isCompatibilityReportSynchronised;
		DataMerger.MergeHelper.ListMerge(dto.generatedOutputItems, this.generatedOutputItems,  (i) => { return i.key },  RSGeneratedOutputItem);
		this.repackageStatus = dto.repackageStatus;
		this.isApplicationReadyForConversion = dto.isApplicationReadyForConversion;
		this.repackedMsiImported = dto.repackedMsiImported;
		this.extractedFromApplicationId = dto.extractedFromApplicationId;
		this.extractedFromApplicationName = dto.extractedFromApplicationName;
		this.additionalVirtualisationConnectionGroupsRequired = dto.additionalVirtualisationConnectionGroupsRequired;
		DataMerger.MergeHelper.ListMerge(dto.extractedApplications, this.extractedApplications,  (i) => { return i.applicationId },  RSApplicationPart);
		DataMerger.MergeHelper.ListMerge(dto.notes, this.notes,  (i) => { return i.id },  RSApplicationNote);
		DataMerger.MergeHelper.ListMerge(dto.issueSummarisations, this.issueSummarisations,  (i) => { return i.platformNumber },  RSIssueSummarisation);
		this.issueSummarisationsDictionary = {};
		this.issueSummarisations.forEach(a => {
			this.issueSummarisationsDictionary[a.platformNumber] = a;
		});
		DataMerger.MergeHelper.ListMerge(dto.platforms, this.platforms,  (i) => { return i.id },  RSPlatform);
		DataMerger.MergeHelper.ListMerge(dto.assessmentGroups, this.assessmentGroups,  (i) => { return i.id },  RSAssessmentGroup);
		DataMerger.MergeHelper.ListMerge(dto.issues, this.issues,  (i) => { return i.id },  RSIssue);
		DataMerger.MergeHelper.ListMerge(dto.customRules, this.customRules,  (i) => { return i.customRuleId },  RSCustomRuleSet);
		DataMerger.MergeHelper.ListMerge(dto.artifacts, this.artifacts,  (i) => { return i.key },  RSAppArtifact);
		DataMerger.MergeHelper.ListMerge(dto.companionFiles, this.companionFiles,  (i) => { return i.companionFileId },  RSApplicationCompanionFile);
		DataMerger.MergeHelper.ListMerge(dto.prerequisiteFiles, this.prerequisiteFiles,  (i) => { return i.prerequisiteFileId },  RSApplicationPrerequisiteFile);
		DataMerger.MergeHelper.ListMerge(dto.installDependencies, this.installDependencies,  (i) => { return i.id },  RSApplicationInstallDependency);
		this.platformsWithFixTransform = dto.platformsWithFixTransform;
		this.hasResponseTransform = dto.hasResponseTransform;
		this.canUpdateVirtualisationReadme = dto.canUpdateVirtualisationReadme;
		this.updatedActivity = dto.updatedActivity;
		this.noChange = dto.noChange;
		this.clickerScript = dto.clickerScript;
		if (dto.fixingJobInfo){
			this.fixingJobInfo = new RSFixingJobInfo();
			this.fixingJobInfo.update(dto.fixingJobInfo);
		}
		DataMerger.MergeHelper.ListMerge(dto.assessmentStates, this.assessmentStates,  (i) => { return i.platformNumber },  RSRAG);
		this.assessmentStatesDictionary = {};
		this.assessmentStates.forEach(a => {
			this.assessmentStatesDictionary[a.platformNumber] = a;
		});
		DataMerger.MergeHelper.ListMerge(dto.actions, this.actions, (i)=> { return i.id}, RSAction );
		this.mostRecentActionId = dto.mostRecentActionId;
		DataMerger.MergeHelper.ListMerge(dto.outputActions, this.outputActions, (i)=>{ return i.id}, RSAction);
		this.isMsp = dto.isMsp;
		this.isPartiallyImported = dto.isPartiallyImported;
		DataMerger.MergeHelper.ListMerge(dto.patchParts, this.patchParts, (i)=>{return i.productCode}, RSPartialPatchItem);
	}

	public deserialize(dto: IRS.IRSGetApplication) : RSGetApplication {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetApplication);
		pd.update(dto);
		return pd;
	}
}

export class RSPartialPatchItem implements IRS.IRSPartialPatchItem {

	public productCode: string;
	public applicationId: string;
	public applicationName:string;
	public status: number;
	public percentComplete : number;

	public update(dto: IRS.IRSPartialPatchItem): void {
		this.productCode = dto.productCode;
		this.applicationId = dto.applicationId;
		this.applicationName = dto.applicationName;
		this.status = dto.status;
		this.percentComplete = dto.percentComplete;
	}

	public deserialize(dto: IRS.IRSPartialPatchItem) : RSPartialPatchItem {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSPartialPatchItem);
		pd.update(dto);
		return pd;
	}
}

export class RSFixingJobInfo implements IRS.IRSFixingJobInfo {

	public status: number;
	public progressPercentage: number;
	public fixDateExpression:string;
	public errors: Array<RSFixError>=[];

	public update(dto: IRS.IRSFixingJobInfo): void {
		this.status = dto.status;
		this.progressPercentage = dto.progressPercentage;
		this.fixDateExpression = dto.fixDateExpression;
		DataMerger.MergeHelper.ListMerge(dto.errors, this.errors, (i) => { return i.id }, RSFixError);
	}

	public deserialize(dto: IRS.IRSFixingJobInfo) : RSFixingJobInfo {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSFixingJobInfo);
		pd.update(dto);
		return pd;
	}
}

export class RSFixError implements IRS.IRSFixError {

	public id: string;
	public checkId: string;
	public message: string;
	public commands: Array<string>;

	public update(dto: IRS.IRSFixError): void {
		this.id = dto.id;
		this.checkId = dto.checkId;
		this.message = dto.message;
		this.commands = dto.commands;
	}

	public deserialize(dto: IRS.IRSFixError) : RSFixError {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSFixError);
		pd.update(dto);
		return pd;
	}
}

export class RSGetApplicationsAtPath implements IRS.IRSGetApplicationsAtPath {

	public items: Array<RSApplication> = [];

	public update(dto: IRS.IRSGetApplicationsAtPath): void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items, (i) => { return i.id }, RSApplication);
	}

	public deserialize(dto: IRS.IRSGetApplicationsAtPath) : RSGetApplicationsAtPath {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetApplicationsAtPath);
		pd.update(dto);
		return pd;
	}
}

export class RSGetApplicationActivity implements IRS.IRSGetApplicationActivity {

	public items: Array<RSActivityItem> = [];

	public update(dto: IRS.IRSGetApplicationActivity) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSActivityItem);
	}

	public deserialize(dto: IRS.IRSGetApplicationActivity) : RSGetApplicationActivity {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetApplicationActivity);
		pd.update(dto);
		return pd;
	}

	public static factory(): RSGetApplicationActivity {
		return new RSGetApplicationActivity();
	}
}

export class RSGetApplicationDocumentationFiles implements IRS.IRSGetApplicationDocumentationFiles {

	public applicationId: string;
	public isCompatible: boolean;
	public zipPath: string;
	public items: Array<RSDocumentationFile> = [];

	public update(dto: IRS.IRSGetApplicationDocumentationFiles) : void {
		this.applicationId = dto.applicationId;
		this.isCompatible = dto.isCompatible;
		this.zipPath = dto.zipPath;
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.key },  RSDocumentationFile);
	}

	public deserialize(dto: IRS.IRSGetApplicationDocumentationFiles) : RSGetApplicationDocumentationFiles {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetApplicationDocumentationFiles);
		pd.update(dto);
		return pd;
	}
}

export class RSDocumentationFile implements IRS.IRSDocumentationFile {

	public key: number;
	public path: string;
	public filename: string;

	public update(dto: IRS.IRSDocumentationFile) : void {
		this.key = dto.key;
		this.path = dto.path;
		this.filename = dto.filename;
	}

	public deserialize(dto: IRS.IRSDocumentationFile) : RSDocumentationFile {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSDocumentationFile);
		pd.update(dto);
		return pd;
	}
}

export class RSGetApplicationIssues implements IRS.IRSGetApplicationIssues {

	public id: string;
	public issueSummarisations: Array<RSIssueSummarisation> = [];
	public issueSummarisationsDictionary : { [platformNumber:string]: RSIssueSummarisation };
	public platforms: Array<RSPlatform> = [];
	public assessmentGroups: Array<RSAssessmentGroup> = [];
	public issues: Array<RSIssue> = [];
	public updatedActivity: string;
	public noChange: boolean;
	public isFixInProgress: boolean;

	public update(dto: IRS.IRSGetApplicationIssues) : void {
		this.id = dto.id;
		DataMerger.MergeHelper.ListMerge(dto.issueSummarisations, this.issueSummarisations,  (i) => { return i.platformNumber },  RSIssueSummarisation);
		this.issueSummarisationsDictionary = {};
		this.issueSummarisations.forEach(a => {
			this.issueSummarisationsDictionary[a.platformNumber] = a;
		});
		DataMerger.MergeHelper.ListMerge(dto.platforms, this.platforms,  (i) => { return i.id },  RSPlatform);
		DataMerger.MergeHelper.ListMerge(dto.assessmentGroups, this.assessmentGroups,  (i) => { return i.id },  RSAssessmentGroup);
		DataMerger.MergeHelper.ListMerge(dto.issues, this.issues,  (i) => { return i.id },  RSIssue);
		this.updatedActivity = dto.updatedActivity;
		this.noChange = dto.noChange;
		this.isFixInProgress = dto.isFixInProgress;
	}

	public deserialize(dto: IRS.IRSGetApplicationIssues) : RSGetApplicationIssues {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetApplicationIssues);
		pd.update(dto);
		return pd;
	}
}

export class RSGetApplicationSiblingInstallers implements IRS.IRSGetApplicationSiblingInstallers {

	public applicationId: string;
	public arguments: string;
	public hasBeenVirtualised: boolean;
	public installers: Array<RSInstaller> = [];

	public update(dto: IRS.IRSGetApplicationSiblingInstallers) : void {
		this.applicationId = dto.applicationId;
		this.arguments = dto.arguments;
		this.hasBeenVirtualised = dto.hasBeenVirtualised;
		DataMerger.MergeHelper.ListMerge(dto.installers, this.installers,  (i) => { return i.id },  RSInstaller);
	}

	public deserialize(dto: IRS.IRSGetApplicationSiblingInstallers) : RSGetApplicationSiblingInstallers {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetApplicationSiblingInstallers);
		pd.update(dto);
		return pd;
	}
}

export class RSGetAssessmentGroup implements IRS.IRSGetAssessmentGroup {

	public id: string;
	public name: string;
	public checks: Array<RSCheck> = [];
	public platforms: Array<RSPlatform> = [];
	public monthlyPatches: Array<RSPatchGroup> = [];
	public isMonthlyUpdateGroup: boolean;
	public updatedActivity: string;
	public noChange: boolean;
	public isEnabled: boolean;
	public assessmentGroupDisableRuleId: string;
	public assessmentStates: Array<RSRAG> = [];
	public assessmentStatesDictionary : { [platformNumber:string]: RSRAG };

	public update(dto: IRS.IRSGetAssessmentGroup) : void {
		this.id = dto.id;
		this.name = dto.name;
		DataMerger.MergeHelper.ListMerge(dto.checks, this.checks,  (i) => { return i.checkId },  RSCheck);
		DataMerger.MergeHelper.ListMerge(dto.platforms, this.platforms,  (i) => { return i.id },  RSPlatform);
		DataMerger.MergeHelper.ListMerge(dto.monthlyPatches, this.monthlyPatches,  (i) => { return i.id },  RSPatchGroup);
		this.isMonthlyUpdateGroup = dto.isMonthlyUpdateGroup;
		this.updatedActivity = dto.updatedActivity;
		this.noChange = dto.noChange;
		this.isEnabled = dto.isEnabled;
		this.assessmentGroupDisableRuleId = dto.assessmentGroupDisableRuleId;
		DataMerger.MergeHelper.ListMerge(dto.assessmentStates, this.assessmentStates,  (i) => { return i.platformNumber },  RSRAG);
		this.assessmentStatesDictionary = {};
		this.assessmentStates.forEach(a => {
			this.assessmentStatesDictionary[a.platformNumber] = a;
		});
	}

	public deserialize(dto: IRS.IRSGetAssessmentGroup) : RSGetAssessmentGroup {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetAssessmentGroup);
		pd.update(dto);
		return pd;
	}
}

export class RSGetCheckInfo implements IRS.IRSGetCheckInfo{

	public checkId: string;
	public checkName: string;
	public description: string;
	public moreInfo: string;
	public patches: Array<RSPatch> = [];

	public update(dto: IRS.IRSGetCheckInfo) : void {
		this.checkId = dto.checkId;
		this.checkName = dto.checkName;
		this.description = dto.description;
		this.moreInfo = dto.moreInfo;
		DataMerger.MergeHelper.ListMerge(dto.patches, this.patches,  (i) => { return i.uniqId },  RSPatch);
	}

	public deserialize(dto: IRS.IRSGetCheckInfo) : RSGetCheckInfo {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetCheckInfo);
		pd.update(dto);
		return pd;
	}
}

export class RSRetainedFile implements IRS.IRSRetainedFile {

	public contentType: string;
	public name: string;
	public description: string;
	public update(dto: IRS.IRSRetainedFile) : void {
		this.contentType = dto.contentType;
		this.name = dto.name;
		this.description = dto.description;
	}

	public deserialize(dto: IRS.IRSRetainedFile) : RSRetainedFile {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSRetainedFile);
		pd.update(dto);
		return pd;
	}
}

export class RSProcess implements IRS.IRSProcess {

	public processId: number;
	public commandLine: string;
	public username: string;
	public hasUi: boolean;
	public isTargetProcess: boolean;
	public runningTime: string;
	public update(dto: IRS.IRSProcess) : void {
		this.processId = dto.processId;
		this.commandLine = dto.commandLine;
		this.username = dto.username;
		this.isTargetProcess = dto.isTargetProcess;
		this.runningTime = dto.runningTime;
		this.hasUi =dto.hasUi;
	}

	public deserialize(dto: IRS.IRSProcess) : RSProcess {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSProcess);
		pd.update(dto);
		return pd;
	}
}

export class RSGetConversionTask implements IRS.IRSGetConversionTask {

	public responseStatus: number;
	public id: string;
	public type: string;
	public jobType: number;
	public typeApproach:string;
	public percentageComplete: number;
	public transactionId: number;
	public applicationId: string;
	public applicationName: string;
	public dateImported: string;
	public dateTaskCreated: string;
	public dateTaskStarted: string;
	public dateTaskCompleted: string;
	public isExecuting: boolean;
	public isExecutingVerified: boolean;
	public imageId: string;
	public secondsInProgress: number;
	public status: number;
	public uiData: string;
	public cancelling: boolean;
	public arguments: string;
	public completionStatExecutionTime: string;
	public completionStatFileCount: number;
	public completionStatRegistryCount: number;
	public completionStatShortcutCount: number;
	public completionStatExtractsMsi: boolean;
	public completionStatDriverInstall: boolean;
	public completionStatContainsQwords: boolean;
	public completionStatChangesPermissions: boolean;
	public completionStatContainsNonAnsi: boolean;
	public completionStatContainsReparsePoints: boolean;
	public completionStatAllUserBasedInstall: boolean;
	public completionStatIceErrors: boolean;
	public completionStatInstallVerified: boolean;
	public completionStatInstallScriptRecordingGenerated:boolean;
	public completionStatTestScriptRecordingGenerated: boolean;
	public completionStatPublishedWithPowershellDeployment: boolean;
	public completionStatistics: Array<RSCompletionStatistic> = [];
	public installerExitCode: number;
	public installerExitMessage: string;
	public nothingGeneratedWarning: boolean;
	public msiExtractOnly: boolean;
	public isWaitingManualModStage: boolean;
	public isPreparingManualModStage: boolean;
	public clickerExecuted: boolean;
	public currentSubState: string;
	public currentStateTitle: string;
	public currentStateDesc: string;
	public currentStateDesc2: string;
	public currentStateIcon: string;
	public currentStateSubTitle:string;
	public canShowRemoteWindow: boolean;
	public isInProgress: boolean;
	public retainedFiles: Array<RSRetainedFile> = [];
	public msiInstallersExtracted: Array<RSExtractedMsi> = [];
	public launchDetails: Array<RSBulletInfo> = [];
	public outputItems: Array<RSGeneratedOutputItem> = [];
	public prerequisiteSiblingInstallers: Array<RSInstaller> = [];
	public mergedSiblingInstallers: Array<RSInstaller> = [];
	public warnings: Array<RSTextItem> = [];
	public preInstalledDependencies: Array<RSTextItem> = [];
	public shortcutRunTestResults: Array<RSXCheckShortcutResult> = [];
	public additionalInfo: Array<RSTextItem> = [];
	public runningProcesses: Array<RSProcess> =[];
	public updatedActivity: string;
	public noChange: boolean;
	public embedRemoteWindow: boolean;
	public adminUser: string;
	public adminUserPwd: string;
	public machineIpAddress: string;
	public remotingUrl: string;
	public outputText: string;
	public canRelease: boolean;
	public evaluationOutcome: number;
	public evaluationOutcomeText: string;
	public virusCheckCompletionStatus: number;
	public shortcutScreenshotCompletionStatus: number;
	public generatedFromRepack: boolean;
	public generatedUsingAutoFix: boolean;
	public generatedUsingResponseTransform: boolean;
	public virtualisedFromRepack: boolean;
	public pvadDirectory: string;
	public uiDetected:boolean;
	public installFailureIndicated: boolean;
	public clickerExecution: RSClickerExecution;

	public update(dto: IRS.IRSGetConversionTask) : void {
		this.responseStatus = dto.responseStatus;
		this.id = dto.id;
		this.type = dto.type;
		this.jobType = dto.jobType;
		this.typeApproach = dto.typeApproach;
		this.percentageComplete = dto.percentageComplete;
		this.transactionId = dto.transactionId;
		this.applicationId = dto.applicationId;
		this.applicationName = dto.applicationName;
		this.dateImported = dto.dateImported;
		this.dateTaskCreated = dto.dateTaskCreated;
		this.dateTaskStarted = dto.dateTaskStarted;
		this.dateTaskCompleted = dto.dateTaskCompleted;
		this.isExecuting = dto.isExecuting;
		this.isExecutingVerified = dto.isExecutingVerified;
		this.imageId = dto.imageId;
		this.secondsInProgress = dto.secondsInProgress;
		this.status = dto.status;
		this.uiData = dto.uiData;
		this.cancelling = dto.cancelling;
		this.arguments = dto.arguments;
		this.completionStatExecutionTime = dto.completionStatExecutionTime;
		this.completionStatFileCount = dto.completionStatFileCount;
		this.completionStatRegistryCount = dto.completionStatRegistryCount;
		this.completionStatShortcutCount = dto.completionStatShortcutCount;
		this.completionStatExtractsMsi = dto.completionStatExtractsMsi;
		this.completionStatDriverInstall = dto.completionStatDriverInstall;
		this.completionStatContainsQwords = dto.completionStatContainsQwords;
		this.completionStatChangesPermissions = dto.completionStatChangesPermissions;
		this.completionStatContainsNonAnsi = dto.completionStatContainsNonAnsi;
		this.completionStatContainsReparsePoints = dto.completionStatContainsReparsePoints;
		this.completionStatAllUserBasedInstall = dto.completionStatAllUserBasedInstall;
		this.completionStatIceErrors = dto.completionStatIceErrors;
		this.completionStatInstallVerified = dto.completionStatInstallVerified;
		this.completionStatInstallScriptRecordingGenerated = dto.completionStatInstallScriptRecordingGenerated;
		this.completionStatTestScriptRecordingGenerated = dto.completionStatTestScriptRecordingGenerated;
		this.completionStatPublishedWithPowershellDeployment = dto.completionStatPublishedWithPowershellDeployment;
		DataMerger.MergeHelper.ListMerge(dto.completionStatistics, this.completionStatistics, (i)=> { return i.id}, RSCompletionStatistic);
		this.installerExitCode = dto.installerExitCode;
		this.installerExitMessage = dto.installerExitMessage;
		this.nothingGeneratedWarning = dto.nothingGeneratedWarning;
		this.msiExtractOnly = dto.msiExtractOnly;
		this.isWaitingManualModStage = dto.isWaitingManualModStage;
		this.isPreparingManualModStage = dto.isPreparingManualModStage;
		this.clickerExecuted = dto.clickerExecuted;
		this.currentSubState = dto.currentSubState;
		this.currentStateTitle = dto.currentStateTitle;
		this.currentStateDesc = dto.currentStateDesc;
		this.currentStateDesc2 = dto.currentStateDesc2;
		this.currentStateIcon = dto.currentStateIcon;
		this.currentStateSubTitle = dto.currentStateSubTitle;
		this.canShowRemoteWindow = dto.canShowRemoteWindow;
		this.isInProgress = dto.isInProgress;
		if (dto.clickerExecution){
			this.clickerExecution = new RSClickerExecution();
			this.clickerExecution.update(dto.clickerExecution);
		}
		else {
			this.clickerExecution =null;
		}

		DataMerger.MergeHelper.ListMerge(dto.retainedFiles, this.retainedFiles, (i)=> { return i.name}, RSRetainedFile);
		DataMerger.MergeHelper.ListMerge(dto.msiInstallersExtracted, this.msiInstallersExtracted,  (i) => { return i.filename },  RSExtractedMsi);
		DataMerger.MergeHelper.ListMerge(dto.launchDetails, this.launchDetails,  (i) => { return i.itemNo },  RSBulletInfo);
		DataMerger.MergeHelper.ListMerge(dto.outputItems, this.outputItems,  (i) => { return i.key },  RSGeneratedOutputItem);
		DataMerger.MergeHelper.ListMerge(dto.prerequisiteSiblingInstallers, this.prerequisiteSiblingInstallers,  (i) => { return i.id },  RSInstaller);
		DataMerger.MergeHelper.ListMerge(dto.mergedSiblingInstallers, this.mergedSiblingInstallers,  (i) => { return i.id },  RSInstaller);
		DataMerger.MergeHelper.ListMerge(dto.warnings, this.warnings,  (i) => { return i.key },  RSTextItem);
		DataMerger.MergeHelper.ListMerge(dto.preInstalledDependencies, this.preInstalledDependencies,  (i) => { return i.key },  RSTextItem);
		DataMerger.MergeHelper.ListMerge(dto.shortcutRunTestResults, this.shortcutRunTestResults,  (i) => { return i.keyHash },  RSXCheckShortcutResult);
		DataMerger.MergeHelper.ListMerge(dto.additionalInfo, this.additionalInfo,  (i) => { return i.key },  RSTextItem);
		DataMerger.MergeHelper.ListMerge(dto.runningProcesses, this.runningProcesses, (i)=>{ return i.processId}, RSProcess);
		this.updatedActivity = dto.updatedActivity;
		this.noChange = dto.noChange;
		this.embedRemoteWindow = dto.embedRemoteWindow;
		this.adminUser = dto.adminUser;
		this.adminUserPwd = dto.adminUserPwd;
		this.machineIpAddress = dto.machineIpAddress;
		this.remotingUrl = dto.remotingUrl;
		this.outputText = dto.outputText;
		this.canRelease = dto.canRelease;
		this.evaluationOutcome = dto.evaluationOutcome;
		this.evaluationOutcomeText = dto.evaluationOutcomeText;
		this.virusCheckCompletionStatus = dto.virusCheckCompletionStatus;
		this.shortcutScreenshotCompletionStatus = dto.shortcutScreenshotCompletionStatus;
		this.generatedFromRepack = dto.generatedFromRepack;
		this.generatedUsingAutoFix = dto.generatedUsingAutoFix;
		this.generatedUsingResponseTransform = dto.generatedUsingResponseTransform;
		this.virtualisedFromRepack = dto.virtualisedFromRepack;
		this.pvadDirectory = dto.pvadDirectory;
		this.uiDetected =dto.uiDetected;
		this.installFailureIndicated = dto.installFailureIndicated;
	}

	public deserialize(dto: IRS.IRSGetConversionTask) : RSGetConversionTask {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetConversionTask);
		pd.update(dto);
		return pd;
	}
}

export class RSClickerExecution implements IRS.IRSClickerExecution {

	public exchangeId: string;
	public hashCode: number;
	public status:number;
	public progressStatus:number;
	public response:string;
	public imageCount:number;

	public update(dto: IRS.IRSClickerExecution) : void {
		this.exchangeId = dto.exchangeId;
		this.hashCode = dto.hashCode;
		this.status = dto.status;
		this.progressStatus = dto.progressStatus;
		this.response = dto.response;
		this.imageCount = dto.imageCount;
	}

	public deserialize(dto: IRS.IRSClickerExecution) : RSClickerExecution {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSClickerExecution);
		pd.update(dto);
		return pd;
	}
}

export class RSCompletionStatistic implements IRS.IRSCompletionStatistic {

	public id: string;
	public valueType:string;
	public asNumber:number;
	public asBoolean:boolean;
	public asString:string;
	public title:string;
	public message:string;

	public update(dto: IRS.IRSCompletionStatistic) : void {
		this.id = dto.id;
		this.valueType = dto.valueType;
		this.asNumber = dto.asNumber;
		this.asBoolean = dto.asBoolean;
		this.asString = dto.asString;
		this.title = dto.title;
		this.message = dto.message;
	}

	public deserialize(dto: IRS.IRSCompletionStatistic) : RSCompletionStatistic {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSCompletionStatistic);
		pd.update(dto);
		return pd;
	}
}


export class RSGetConversionTaskLog implements IRS.IRSGetConversionTaskLog {

	public jobId: string;
	public lines: Array<RSTaskLogLine> = [];

	public update(dto: IRS.IRSGetConversionTaskLog) : void {
		this.jobId = dto.jobId;
		DataMerger.MergeHelper.ListMerge(dto.lines, this.lines,  (i) => { return i.lineNo },  RSTaskLogLine);
	}

	public deserialize(dto: IRS.IRSGetConversionTaskLog) : RSGetConversionTaskLog {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetConversionTaskLog);
		pd.update(dto);
		return pd;
	}

	public static factory(): RSGetConversionTaskLog {
		return new RSGetConversionTaskLog();
	}
}

export class RSGetCurrentConversionTasks implements IRS.IRSGetCurrentConversionTasks {

	public items: Array<RSConversionTask> = [];
	public updatedActivity: string;
	public noChange: boolean;

	public update(dto: IRS.IRSGetCurrentConversionTasks) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSConversionTask);
		this.updatedActivity = dto.updatedActivity;
		this.noChange = dto.noChange;
	}

	public deserialize(dto: IRS.IRSGetCurrentConversionTasks) : RSGetCurrentConversionTasks {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetCurrentConversionTasks);
		pd.update(dto);
		return pd;
	}
}

export class RSGetCurrentConversionTasksForApplication implements IRS.IRSGetCurrentConversionTasksForApplication {

	public items: Array<RSConversionTask> = [];
	public updatedActivity: string;
	public noChange: boolean;

	public update(dto: IRS.IRSGetCurrentConversionTasksForApplication) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSConversionTask);
		this.updatedActivity = dto.updatedActivity;
		this.noChange = dto.noChange;
	}

	public deserialize(dto: IRS.IRSGetCurrentConversionTasksForApplication) : RSGetCurrentConversionTasksForApplication {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetCurrentConversionTasksForApplication);
		pd.update(dto);
		return pd;
	}
}

export class RSGetCustomQueries implements IRS.IRSGetCustomQueries {

	public queries: Array<RSQuery> = [];

	public update(dto: IRS.IRSGetCustomQueries) : void {
		DataMerger.MergeHelper.ListMerge(dto.queries, this.queries,  (i) => { return i.id },  RSQuery);
	}

	public deserialize(dto: IRS.IRSGetCustomQueries) : RSGetCustomQueries {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetCustomQueries);
		pd.update(dto);
		return pd;
	}
}

export class RSGetCustomQueryResults implements IRS.IRSGetCustomQueryResults {

	public status: number;
	public percentageComplete: number;
	public itemCount: number;
	public eid: number;
	public queryColumns: Array<RSQueryColumnName> = [];
	public rows: Array<RSQueryRow> = [];

	public update(dto: IRS.IRSGetCustomQueryResults) : void {
		this.status = dto.status;
		this.percentageComplete = dto.percentageComplete;
		this.itemCount = dto.itemCount;
		this.eid = dto.eid;
		DataMerger.MergeHelper.ListMerge(dto.queryColumns, this.queryColumns,  (i) => { return i.id },  RSQueryColumnName);
		DataMerger.MergeHelper.ListMerge(dto.rows, this.rows,  (i) => { return i.id },  RSQueryRow);
	}

	public deserialize(dto: IRS.IRSGetCustomQueryResults) : RSGetCustomQueryResults {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetCustomQueryResults);
		pd.update(dto);
		return pd;
	}
}

export class RSGetCustomRules implements IRS.IRSGetCustomRules {

	public groups: Array<RSCustomRuleGroup> = [];

	public update(dto: IRS.IRSGetCustomRules) : void {
		DataMerger.MergeHelper.ListMerge(dto.groups, this.groups,  (i) => { return i.id },  RSCustomRuleGroup);
	}

	public deserialize(dto: IRS.IRSGetCustomRules) : RSGetCustomRules {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetCustomRules);
		pd.update(dto);
		return pd;
	}
}

export class RSGetDependencyList implements IRS.IRSGetDependencyList {

	public items: Array<RSDependencyItem> = [];

	public update(dto: IRS.IRSGetDependencyList) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSDependencyItem);
	}

	public deserialize(dto: IRS.IRSGetDependencyList) : RSGetDependencyList {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetDependencyList);
		pd.update(dto);
		return pd;
	}
}

export class RSGetDriveLink implements IRS.IRSGetDriveLink {

	public driveLinkId: string;
	public projectId: string;
	public display: string;
	public driveLinkType: number;
	public scanInProgress: boolean;
	public scanStartDateExpression: string;
	public scanProgressPercentage: number;
	public scanFileCount: number;
	public scanFolderCount: number;
	public sasKey: string;
	public items: Array<RSDriveLinkMapItem> = [];

	public update(dto: IRS.IRSGetDriveLink) : void {
		this.driveLinkId = dto.driveLinkId;
		this.projectId = dto.projectId;
		this.display = dto.display;
		this.driveLinkType = dto.driveLinkType;
		this.scanInProgress = dto.scanInProgress;
		this.scanStartDateExpression = dto.scanStartDateExpression;
		this.scanProgressPercentage = dto.scanProgressPercentage;
		this.scanFileCount = dto.scanFileCount;
		this.scanFolderCount = dto.scanFolderCount;
		this.sasKey = dto.sasKey;
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.applicationId },  RSDriveLinkMapItem);
	}

	public deserialize(dto: IRS.IRSGetDriveLink) : RSGetDriveLink {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetDriveLink);
		pd.update(dto);
		return pd;
	}
}

export class RSGetDriveLinks implements IRS.IRSGetDriveLinks {

	public items: Array<RSDriveLink> = [];

	public update(dto: IRS.IRSGetDriveLinks) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.driveLinkId },  RSDriveLink);
	}

	public  deserialize(dto: IRS.IRSGetDriveLinks) : RSGetDriveLinks {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetDriveLinks);
		pd.update(dto);
		return pd;
	}
}

export class RSGetEvalMachine implements IRS.IRSGetEvalMachine {

	public applicationId: string;
	public machineId: string;
	public requestId: string;
	public requestPending: boolean;
	public pendingRequestActionType: number;
	public hasBeenReleased: boolean;
	public evaluationResultText: string;
	public evaluationOutcome: string;
	public evaluationCompletionDate: string;
	public type: number;
	public subType: string;
	public applicationName: string;
	public evalReportUrl: string;
	public dateRequested: string;
	public dateRequestedExpression: string;
	public requestMachinesInUse: number;
	public requestPoolSize: number;
	public machineState: number;
	public machineIpAddress: string;
	public machineCreationProgress: number;
	public timeRemaining: string;
	public lastError: string;
	public adminUser: string;
	public adminUserPwd: string;
	public stdUser: string;
	public stdUserPwd: string;
	public hasEvalReport: boolean;
	public remotingUrl: string;
	public embedRemoteWindow: boolean;
	public evaluationLogs: Array<RSEvaluationLogEntry> = [];

	public update(dto: IRS.IRSGetEvalMachine) : void {
		this.applicationId = dto.applicationId;
		this.machineId = dto.machineId;
		this.requestId = dto.requestId;
		this.requestPending = dto.requestPending;
		this.pendingRequestActionType = dto.pendingRequestActionType;
		this.hasBeenReleased = dto.hasBeenReleased;
		this.evaluationResultText = dto.evaluationResultText;
		this.evaluationOutcome = dto.evaluationOutcome;
		this.evaluationCompletionDate = dto.evaluationCompletionDate;
		this.type = dto.type;
		this.subType = dto.subType;
		this.applicationName = dto.applicationName;
		this.evalReportUrl = dto.evalReportUrl;
		this.dateRequested = dto.dateRequested;
		this.dateRequestedExpression = dto.dateRequestedExpression;
		this.requestMachinesInUse = dto.requestMachinesInUse;
		this.requestPoolSize = dto.requestPoolSize;
		this.machineState = dto.machineState;
		this.machineIpAddress = dto.machineIpAddress;
		this.machineCreationProgress = dto.machineCreationProgress;
		this.timeRemaining = dto.timeRemaining;
		this.lastError = dto.lastError;
		this.adminUser = dto.adminUser;
		this.adminUserPwd = dto.adminUserPwd;
		this.stdUser = dto.stdUser;
		this.stdUserPwd = dto.stdUserPwd;
		this.hasEvalReport = dto.hasEvalReport;
		this.remotingUrl = dto.remotingUrl;
		this.embedRemoteWindow = dto.embedRemoteWindow;
		DataMerger.MergeHelper.ListMerge(dto.evaluationLogs, this.evaluationLogs,  (i) => { return i.key },  RSEvaluationLogEntry);
	}

	public  deserialize(dto: IRS.IRSGetEvalMachine) : RSGetEvalMachine {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetEvalMachine);
		pd.update(dto);
		return pd;
	}
}

export class RSGetEvalReport implements IRS.IRSGetEvalReport {

	public displayName: string;
	public filename: string;

	public update(dto: IRS.IRSGetEvalReport) : void {
		this.displayName = dto.displayName;
		this.filename = dto.filename;
	}

	public deserialize(dto: IRS.IRSGetEvalReport) : RSGetEvalReport {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetEvalReport);
		pd.update(dto);
		return pd;
	}
}

export class RSGetHelpInfo implements IRS.IRSGetHelpInfo {

	public versionNo: string;

	public update(dto: IRS.IRSGetHelpInfo) : void {
		this.versionNo = dto.versionNo;
	}

	public deserialize(dto: IRS.IRSGetHelpInfo) : RSGetHelpInfo {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetHelpInfo);
		pd.update(dto);
		return pd;
	}
}

export class RSGetIssuesForCheck implements IRS.IRSGetIssuesForCheck {

	public checkId: string;
	public isEnabled: boolean;
	public enabledRuleId: string;
	public issues: Array<RSIssue> = [];
	public issueSummarisations: Array<RSIssueSummarisation> = [];
	public updatedActivity: string;
	public noChange: boolean;

	public update(dto: IRS.IRSGetIssuesForCheck) : void {
		this.checkId = dto.checkId;
		this.isEnabled = dto.isEnabled;
		this.enabledRuleId = dto.enabledRuleId;
		DataMerger.MergeHelper.ListMerge(dto.issues, this.issues,  (i) => { return i.id },  RSIssue);
		DataMerger.MergeHelper.ListMerge(dto.issueSummarisations, this.issueSummarisations,  (i) => { return i.platformNumber },  RSIssueSummarisation);
		this.updatedActivity = dto.updatedActivity;
		this.noChange = dto.noChange;
	}

	public deserialize(dto: IRS.IRSGetIssuesForCheck) : RSGetIssuesForCheck {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetIssuesForCheck);
		pd.update(dto);
		return pd;
	}
}

export class RSGetJobStatus implements IRS.IRSGetJobStatus {

	public status: number;

	public update(dto: IRS.IRSGetJobStatus) : void {
		this.status = dto.status;
	}

	public deserialize(dto: IRS.IRSGetJobStatus) : RSGetJobStatus {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetJobStatus);
		pd.update(dto);
		return pd;
	}
}

export class RSGetMetaData implements IRS.IRSGetMetaData {

	public entities: Array<RSMetaEntity> = [];
	entitiesLookup: DataMerger.IDictionary<RSMetaEntity>

	public update(dto: IRS.IRSGetMetaData) : void {
		DataMerger.MergeHelper.ListMerge(dto.entities, this.entities,  (i) => { return i.eid },  RSMetaEntity);
		this.entitiesLookup ={};
		this.entities.forEach((i)=> {
			this.entitiesLookup[i.eid] = i
		});
	}

	public getEntity(id: number) {
			let r = this.entitiesLookup[id];
			return r
	}

	public deserialize(dto: IRS.IRSGetMetaData) : RSGetMetaData {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetMetaData);
		pd.update(dto);
		return pd;
	}
}

export class RSGetNotifications implements IRS.IRSGetNotifications {

	public notifications: Array<RSNotificationItem> = [];

	public update(dto: IRS.IRSGetNotifications) : void {
		DataMerger.MergeHelper.ListMerge(dto.notifications, this.notifications,  (i) => { return i.id },  RSNotificationItem);
	}

	public deserialize(dto: IRS.IRSGetNotifications) : RSGetNotifications {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetNotifications);
		pd.update(dto);
		return pd;
	}
}

export class RSGetNotificationsForApplication implements IRS.IRSGetNotificationsForApplication {

	public notifications: Array<RSNotificationItem> = [];

	public update(dto: IRS.IRSGetNotificationsForApplication) : void {
		DataMerger.MergeHelper.ListMerge(dto.notifications, this.notifications,  (i) => { return i.id },  RSNotificationItem);
	}

	public deserialize(dto: IRS.IRSGetNotificationsForApplication) : RSGetNotificationsForApplication {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetNotificationsForApplication);
		pd.update(dto);
		return pd;
	}
}

export class RSGetPatchConflictDetails implements IRS.IRSGetPatchConflictDetails {

	public checkId: string;
	public applications: Array<RSApplicationConflict> = [];

	public update(dto: IRS.IRSGetPatchConflictDetails) : void {
		this.checkId = dto.checkId;
		DataMerger.MergeHelper.ListMerge(dto.applications, this.applications,  (i) => { return i.id },  RSApplicationConflict);
	}

	public  deserialize(dto: IRS.IRSGetPatchConflictDetails) : RSGetPatchConflictDetails {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetPatchConflictDetails);
		pd.update(dto);
		return pd;
	}
}

export class RSGetPatchConflictSummary implements IRS.IRSGetPatchConflictSummary {

	public completenessPercentage: number;
	public platforms: Array<RSPlatform> = [];
	public patches: Array<RSPatch> = [];

	public update(dto: IRS.IRSGetPatchConflictSummary) : void {
		this.completenessPercentage = dto.completenessPercentage;
		DataMerger.MergeHelper.ListMerge(dto.platforms, this.platforms,  (i) => { return i.id },  RSPlatform);
		DataMerger.MergeHelper.ListMerge(dto.patches, this.patches,  (i) => { return i.uniqId },  RSPatch);
	}

	public deserialize(dto: IRS.IRSGetPatchConflictSummary) : RSGetPatchConflictSummary {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetPatchConflictSummary);
		pd.update(dto);
		return pd;
	}
}

export class RSGetPatchExport implements IRS.IRSGetPatchExport {

	public items: Array<RSPatchExportItem> = [];

	public update(dto: IRS.IRSGetPatchExport) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.patchId },  RSPatchExportItem);
	}

	public deserialize(dto: IRS.IRSGetPatchExport) : RSGetPatchExport {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetPatchExport);
		pd.update(dto);
		return pd;
	}
}

export class RSGetPatchGroup implements IRS.IRSGetPatchGroup {

	public patchGroup: RSPatchGroup;
	public platforms: Array<RSPlatform> = [];

	public update(dto: IRS.IRSGetPatchGroup) : void {
		if (this.patchGroup == null && dto.patchGroup != null)
			this.patchGroup = new RSPatchGroup();
		if (dto.patchGroup == null)
			this.patchGroup = null
		else
			this.patchGroup.update(dto.patchGroup);

		DataMerger.MergeHelper.ListMerge(dto.platforms, this.platforms,  (i) => { return i.id },  RSPlatform);
	}

	public deserialize(dto: IRS.IRSGetPatchGroup) : RSGetPatchGroup {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetPatchGroup);
		pd.update(dto);
		return pd;
	}
}

export class RSGetPatchGroups implements IRS.IRSGetPatchGroups {

	public patches: Array<RSPatchGroup> = [];

	public update(dto: IRS.IRSGetPatchGroups) : void {
		DataMerger.MergeHelper.ListMerge(dto.patches, this.patches,  (i) => { return i.id },  RSPatchGroup);
	}

	public deserialize(dto: IRS.IRSGetPatchGroups) : RSGetPatchGroups {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetPatchGroups);
		pd.update(dto);
		return pd;
	}
}

export class RSNoteItem implements IRS.IRSNoteItem {

	public id: string;
	public label: string;
	public defaultValue: string;
	public type: string;

	public update(dto: IRS.IRSNoteItem) : void {
		this.id = dto.id;
		this.label = dto.label;
		this.defaultValue = dto.defaultValue;
		this.type =dto.type;
	}

	public deserialize(dto: IRS.IRSNoteItem) : RSNoteItem {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSNoteItem);
		pd.update(dto);
		return pd;
	}
}

export class RSGetProject implements IRS.IRSGetProject {

	public id: string;
	public name: string;
	public storageAccessKey: string;
	public description: string;
	public imageId: string;
	public customerReportingImageId: string;
	public isOwner: boolean;
	public isContributor: boolean;
	public isReady: boolean;
	public owner: string;
	public applicationCount: number;
	public assessmentGroupCount: number;
	public projectOutputItems: Array<RSGeneratedOutputItem> = [];
	public waitingDataMessage: string;
	public networkMappedDriveLinkEnabled: boolean;
	public networkMappedDriveLink: string;
	public virtualisationVhd: string;
	public msixVhd:string;
	public liquidwareVhd:string;
	public repackagingVhd: string;
	public defaultTestingVhd: string;
	public msixTestingVhd: string;
	public canImportApps: boolean;
	public disableAutomaticDetectedDependencyInstall :boolean;
	public disableAutomaticDetectedDependencyInstallRuleId :string;
	public appIdCustomisations: string;
	public appIdCustomisationsRuleId: string;
	public packagingStandardsChecksEnabled: boolean;
	public installCommandFiles: string;
	public installCommandFilesRuleId: string;

	public showEffortInAppCompatReport:boolean;
	public showEffortInAppCompatReportRuleId:string;
	public projectNotes: Array<RSIdNameValue>=[];
	public projectNotesRuleId: string;
	public workflowStages: string;
	public workflowStagesRuleId: string;
	public xcheckImageToolsKey: string;
	public xcheckImageToolsKeyRuleId: string;

	public publishingCommands: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.PublishingCommands, "publishingCommands");
	public publishingCommandsVirtualisation: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.PublishingCommandsVirtualisation, "publishingCommandsVirtualisation");
	public publishingCommandsExe: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.PublishingCommandsExe, "publishingCommandsExe");
	public publishingCommandsMsix: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.PublishingCommandsMsix, "publishingCommandsMsix");
	public publishingCommandsLiqd: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.PublishingCommandsLiqd, "publishingCommandLiqd");

	public videoRecordTestingJobs:RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.VideoRecordTestingJobs, "videoRecordTestingJobs", Constants.VALTYPE_BOOL);
	public reportingConfiguration: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.ReportingConfigurationId, "reportingConfiguration");
	public msixPackagingToolVersion: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.MsixPackagingToolVersion, "msixPackagingToolVersion");
	public msixManifestModifications: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.MsixManifestModifications, "msixManifestModifications");
	public msixPublisherName: RSProjRuleInfo =RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.MsixPublisherName, "msixPublisherName");
	public msixPublisherDisplayName: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.MsixPublisherDisplayName, "msixPublisherDisplayName");
	public vhdUsernameMap: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.VHDUsernameMap, "vhdUsernameMap");
	public workflowStateChangeEmailHtml: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.WorkflowStateChangeEmailHtml, "workflowStateChangeEmailHtml");
	public workflowStateChangeEmailTitle:RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.WorkflowStateChangeEmailTitle, "workflowStateChangeEmailTitle");
	public workflowStateChangeEmailRecipient: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.WorkflowStateChangeEmailRecipient, "workflowStateChangeEmailRecipient");
	public reportingFileFormat:RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.ReportingFileFormat, "reportingFileFormat");
	public userGroup: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.UserGroup, "userGroup");
	public testUserType: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.TestUserType, "testUserType");
	public vmPreparationScript: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.VMPreparationScript, "vmPreparationScript");
	public vmPreparationScriptErrors: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.VMPreparationScriptErrors, "vmPreparationScriptErrors");
	public intuneNotes :RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.intuneNotes, "intuneNotes");
	public intuneOwner: RSProjRuleInfo =  RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.intuneOwner, "intuneOwner");
	public intuneInformationUrl :RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.intuneInformationUrl, "intuneInformationUrl");
	public intuneCredentials:RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.intuneCredentials, "intuneCredentials");
	public intuneNavigatorIdElement:RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.intuneNavigatorIdElement, "intuneNavigatorIdElement");
	public appvDescriptionTemplate: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.AppVDescriptionTemplate, "appvDescriptionTemplate");
	public appvFullWriteMode: RSProjRuleInfo =RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.AppVFullWriteMode,"appvFullWriteMode", Constants.VALTYPE_BOOL);
	public detectionRegistryKey: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.DetectionRegistryKey, "detectionRegistryKey");
	public readmeDocumentationScript:RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.ReadmeDocumentationScript, "readmeDocumentationScript");
	public signingTimestampUrl: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.SigningTimestampUrl, "signingTimestampUrl");
	public defaultAppIcon: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.DefaultAppIcon, "defaultAppIcon");
	public sendWorkflowStateChangeEmail:RSProjRuleInfo =RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.SendWorkflowStateChangeEmails, "sendWorkflowStateChangeEmail", Constants.VALTYPE_BOOL);
	public snapshotDuringDiscovery: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.SnapshotDuringDiscovery, "snapshotDuringDiscovery", Constants.VALTYPE_BOOL);
	public showReportingConfigImageInReadme: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.ShowReportingConfigImageInReadme, "showReportingConfigImageInReadme", Constants.VALTYPE_BOOL);
	public disableXCheck: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.DisableXCheck, "disableXCheck", Constants.VALTYPE_BOOL);
	public applyDelayedFixes:RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.ApplyDelayedFixes, "applyDelayedFixes", Constants.VALTYPE_BOOL);
	public preferInstallCommandFile: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.PreferInstallCommandFile, "preferInstallCommandFile", Constants.VALTYPE_BOOL);
	public sendXCheckVmCreationEmail: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.SendXCheckVmCreationEmail, "sendXCheckVmCreationEmail", Constants.VALTYPE_BOOL);
	public updatePasswordsOnVms: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.UpdatePasswordsOnVms, "updatePasswordsOnVms", Constants.VALTYPE_BOOL);
	public useVendorForPublisherDisplayName: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.UseVendorForPublisherDisplayName, "useVendorForPublisherDisplayName", Constants.VALTYPE_BOOL);
	public disable8Dot3FilenameCreation: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.Disable8Dot3FilenameCreation, "disable8Dot3FilenameCreation", Constants.VALTYPE_BOOL);
	public xcheckRequiresSilentArgs: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.XCheckRequiresSilentArgs, "xcheckRequiresSilentArgs", Constants.VALTYPE_BOOL);
	public readmeIceErrorsOnly: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.ReadmeIceErrorsOnly, "readmeIceErrorsOnly", Constants.VALTYPE_BOOL);
	public readmeIceOnOriginalPackage: RSProjRuleInfo = RSProjRuleInfo.GetBlank(Enum.ProjectRuleType.ReadmeIncludeIceForOriginalPackage, "readmeIceOnOriginalPackage", Constants.VALTYPE_BOOL);

	public desktopBaseImageId: string;
	public desktopBaseImageXCheckRunning: boolean;
	public msixCertificate: string;
	public msixCertificatePassword: string;
	public msixCertificateIssuer: string;
	public msixCertificateIssuerExtracted: boolean;
	public msixCertificateSignError: string;
	public msixCertificateSignInProgress: boolean;
	public isSASTokenExpired: boolean;
	public platforms: Array<RSPlatform> = [];
	public applications: Array<RSApplication> = [];
	public assessmentGroups: Array<RSAssessmentGroup> = [];
	public patchGroups: Array<RSPatchGroup> = [];
	public rules: Array<RSProjectRule> = [];
	public driveLinks: Array<RSDriveLink> = [];
	public operatingSystems: Array<RSOperatingSystem> = [];
	public officeSuites: Array<RSOfficeSuite> = [];
	public browsers: Array<RSBrowser> = [];
	public dotNetVersions: Array<RSDotNetVersion> = [];
	public virtualisationTechnologies: Array<RSVirtualisationTechnology> = [];
	public shares: Array<RSProjectShare> = [];
	public monthlyUpdates: Array<RSMonthlyUpdate> = [];
	public applicationImportProgressions: Array<RSApplicationImportProgress> = [];
	public trackableOperatingSystemConfigurations: Array<RSTrackableOperatingSystemConfiguration> = [];
	public workflowStageList: Array<RSWorkflowStageInfo> = [];
	public applicationNoteTypes: Array<RSAppNoteType> = [];
	public statusSummary: RSRAGElement;
	public notifyItems: Array<RSNotifyItem> = [];
	public conversionTaskInteractionRequired: boolean;
	public updatedActivity: string;
	public noChange: boolean;
	public reportOptions: Array<RSReportOption> = [];

	public errorType:string;
	public errorMessage:string;

	public update(dto: IRS.IRSGetProject) : void {
		this.errorType = dto.errorType;
		this.errorMessage  = dto.errorMessage;
		this.id = dto.id;
		this.name = dto.name;
		this.storageAccessKey = dto.storageAccessKey;
		this.description = dto.description;
		this.imageId = dto.imageId;
		this.customerReportingImageId = dto.customerReportingImageId;
		this.isOwner = dto.isOwner;
		this.isContributor = dto.isContributor;
		this.isReady = dto.isReady;
		this.owner = dto.owner;
		this.applicationCount = dto.applicationCount;
		this.assessmentGroupCount = dto.assessmentGroupCount;
		DataMerger.MergeHelper.ListMerge(dto.projectOutputItems, this.projectOutputItems,  (i) => { return i.key },  RSGeneratedOutputItem);
		this.waitingDataMessage = dto.waitingDataMessage;
		this.networkMappedDriveLinkEnabled = dto.networkMappedDriveLinkEnabled;
		this.networkMappedDriveLink = dto.networkMappedDriveLink;
		this.virtualisationVhd = dto.virtualisationVhd;
		this.msixVhd = dto.msixVhd;
		this.liquidwareVhd = dto.liquidwareVhd;
		this.repackagingVhd = dto.repackagingVhd;
		this.defaultTestingVhd = dto.defaultTestingVhd;
		this.msixTestingVhd = dto.msixTestingVhd;
		this.canImportApps = dto.canImportApps;
		this.disableAutomaticDetectedDependencyInstall = dto.disableAutomaticDetectedDependencyInstall;
		this.disableAutomaticDetectedDependencyInstallRuleId =dto.disableAutomaticDetectedDependencyInstallRuleId;
		this.appIdCustomisations = dto.appIdCustomisations;
		this.appIdCustomisationsRuleId = dto.appIdCustomisationsRuleId;
		this.packagingStandardsChecksEnabled = dto.packagingStandardsChecksEnabled;
		this.installCommandFiles = dto.installCommandFiles;
		this.installCommandFilesRuleId = dto.installCommandFilesRuleId;
		this.showEffortInAppCompatReport = dto.showEffortInAppCompatReport;
		this.showEffortInAppCompatReportRuleId = dto.showEffortInAppCompatReportRuleId;
		this.projectNotesRuleId = dto.projectNotesRuleId;
		this.workflowStages = dto.workflowStages;
		this.workflowStagesRuleId = dto.workflowStagesRuleId;
		this.xcheckImageToolsKey = dto.xcheckImageToolsKey;
		this.xcheckImageToolsKeyRuleId = dto.xcheckImageToolsKeyRuleId;
		
		this.publishingCommands.update(dto.publishingCommands);
		this.publishingCommandsVirtualisation.update( dto.publishingCommandsVirtualisation);
		this.publishingCommandsMsix.update( dto.publishingCommandsMsix);
		this.publishingCommandsExe.update(dto.publishingCommandsExe);
		this.publishingCommandsLiqd.update(dto.publishingCommandsLiqd);

		this.videoRecordTestingJobs.update(dto.videoRecordTestingJobs);
		this.reportingConfiguration.update( dto.reportingConfiguration);
		this.msixManifestModifications.update(dto.msixManifestModifications);
		this.msixPackagingToolVersion.update(dto.msixPackagingToolVersion);
		this.msixPublisherName.update( dto.msixPublisherName);
		this.msixPublisherDisplayName.update(dto.msixPublisherDisplayName);
		this.vhdUsernameMap.update(dto.vhdUsernameMap);
		this.sendWorkflowStateChangeEmail.update(dto.sendWorkflowStateChangeEmail);
        this.workflowStateChangeEmailHtml.update(dto.workflowStateChangeEmailHtml);
        this.workflowStateChangeEmailTitle.update( dto.workflowStateChangeEmailTitle);
        this.workflowStateChangeEmailRecipient.update( dto.workflowStateChangeEmailRecipient);
		this.reportingFileFormat.update(dto.reportingFileFormat);
		this.testUserType.update(dto.testUserType);
		this.userGroup.update(dto.userGroup);
		this.vmPreparationScript.update( dto.vmPreparationScript);
		this.vmPreparationScriptErrors.update(dto.vmPreparationScriptErrors);
		this.intuneNotes.update(dto.intuneNotes);
        this.intuneOwner.update(dto.intuneOwner);
        this.intuneInformationUrl.update(dto.intuneInformationUrl);
        this.intuneCredentials.update(dto.intuneCredentials);
		this.intuneNavigatorIdElement.update(dto.intuneNavigatorIdElement);
		this.appvDescriptionTemplate.update( dto.appvDescriptionTemplate);
		this.appvFullWriteMode.update( dto.appvFullWriteMode)	
		this.detectionRegistryKey.update( dto.detectionRegistryKey);
		this.readmeDocumentationScript.update( dto.readmeDocumentationScript);
		this.signingTimestampUrl.update(dto.signingTimestampUrl);
		this.defaultAppIcon.update(dto.defaultAppIcon);
		this.snapshotDuringDiscovery.update(dto.snapshotDuringDiscovery);
		this.showReportingConfigImageInReadme.update(dto.showReportingConfigImageInReadme);
		this.disableXCheck.update(dto.disableXCheck);
		this.applyDelayedFixes.update( dto.applyDelayedFixes);
		this.preferInstallCommandFile.update(dto.preferInstallCommandFile);
		this.sendXCheckVmCreationEmail.update( dto.sendXCheckVmCreationEmail);
		this.updatePasswordsOnVms.update( dto.updatePasswordsOnVms);
		this.useVendorForPublisherDisplayName.update( dto.useVendorForPublisherDisplayName);
		this.disable8Dot3FilenameCreation.update( dto.disable8Dot3FilenameCreation);
		this.xcheckRequiresSilentArgs.update(dto.xcheckRequiresSilentArgs);
		this.readmeIceErrorsOnly.update( dto.readmeIceErrorsOnly);
		this.readmeIceOnOriginalPackage.update(dto.readmeIceOnOriginalPackage);

		this.desktopBaseImageId = dto.desktopBaseImageId;
		this.desktopBaseImageXCheckRunning = dto.desktopBaseImageXCheckRunning;
		this.msixCertificate = dto.msixCertificate;
		this.msixCertificatePassword = dto.msixCertificatePassword;
		this.msixCertificateIssuer = dto.msixCertificateIssuer;
		this.msixCertificateIssuerExtracted = dto.msixCertificateIssuerExtracted;
		this.msixCertificateSignError = dto.msixCertificateSignError;
		this.msixCertificateSignInProgress = dto.msixCertificateSignInProgress;
		this.isSASTokenExpired = dto.isSASTokenExpired;
		DataMerger.MergeHelper.ListMerge(dto.platforms, this.platforms,  (i) => { return i.id },  RSPlatform);
		DataMerger.MergeHelper.ListMerge(dto.applications, this.applications,  (i) => { return i.id },  RSApplication);
		DataMerger.MergeHelper.ListMerge(dto.assessmentGroups, this.assessmentGroups,  (i) => { return i.id },  RSAssessmentGroup);
		DataMerger.MergeHelper.ListMerge(dto.patchGroups, this.patchGroups,  (i) => { return i.id },  RSPatchGroup);
		DataMerger.MergeHelper.ListMerge(dto.rules, this.rules,  (i) => { return i.projectRuleId },  RSProjectRule);
		DataMerger.MergeHelper.ListMerge(dto.driveLinks, this.driveLinks,  (i) => { return i.driveLinkId },  RSDriveLink);
		DataMerger.MergeHelper.ListMerge(dto.operatingSystems, this.operatingSystems,  (i) => { return i.id },  RSOperatingSystem);
		DataMerger.MergeHelper.ListMerge(dto.officeSuites, this.officeSuites,  (i) => { return i.id },  RSOfficeSuite);
		DataMerger.MergeHelper.ListMerge(dto.browsers, this.browsers,  (i) => { return i.id },  RSBrowser);
		DataMerger.MergeHelper.ListMerge(dto.dotNetVersions, this.dotNetVersions,  (i) => { return i.id },  RSDotNetVersion);
		DataMerger.MergeHelper.ListMerge(dto.virtualisationTechnologies, this.virtualisationTechnologies,  (i) => { return i.id },  RSVirtualisationTechnology);
		DataMerger.MergeHelper.ListMerge(dto.shares, this.shares,  (i) => { return i.userId },  RSProjectShare);
		DataMerger.MergeHelper.ListMerge(dto.monthlyUpdates, this.monthlyUpdates,  (i) => { return i.id },  RSMonthlyUpdate);
		DataMerger.MergeHelper.ListMerge(dto.applicationImportProgressions, this.applicationImportProgressions,  (i) => { return i.applicationId },  RSApplicationImportProgress);
		DataMerger.MergeHelper.ListMerge(dto.trackableOperatingSystemConfigurations, this.trackableOperatingSystemConfigurations,  (i) => { return i.id },  RSTrackableOperatingSystemConfiguration);
		DataMerger.MergeHelper.ListMerge(dto.applicationNoteTypes, this.applicationNoteTypes,  (i) => { return i.id },  RSAppNoteType);
		DataMerger.MergeHelper.ListMerge(dto.workflowStageList, this.workflowStageList,  (i) => { return i.id },  RSWorkflowStageInfo);
		DataMerger.MergeHelper.ListMerge(dto.projectNotes, this.projectNotes, (i)=> {return i.id}, RSIdNameValue);
		if (this.statusSummary == null && dto.statusSummary != null)
			this.statusSummary = new RSRAGElement();
		if (dto.statusSummary == null)
			this.statusSummary = null
		else
			this.statusSummary.update(dto.statusSummary);

		DataMerger.MergeHelper.ListMerge(dto.notifyItems, this.notifyItems,  (i) => { return i.index },  RSNotifyItem);
		this.conversionTaskInteractionRequired = dto.conversionTaskInteractionRequired;
		this.updatedActivity = dto.updatedActivity;
		this.noChange = dto.noChange;
		DataMerger.MergeHelper.ListMerge(dto.reportOptions, this.reportOptions,  (i) => { return i.key },  RSReportOption);
	}

	public deserialize(dto: IRS.IRSGetProject) : RSGetProject {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetProject);
		pd.update(dto);
		return pd;
	}
}

export class RSProjRuleInfo extends RSBase<RSProjRuleInfo,IRS.IRSProjRuleInfo> implements IRS.IRSProjRuleInfo, IUpdate<IRS.IRSProjRuleInfo> {

	constructor() {
		super(RSProjRuleInfo);
	}

	static GetBlank(code: Enum.ProjectRuleType, propertyName:string, valType?:number) :RSProjRuleInfo{
		var r = new RSProjRuleInfo();
		r.ruleId = "00000000-0000-0000-0000-000000000000";
		r.value=r.numValue=null;
		r.code =code;
		r.valType = valType??Constants.VALTYPE_STRING;
		r.propertyName = propertyName;
		return r;
	}

	public code:Enum.ProjectRuleType;
	public propertyName:string;
	public valType:number;
	public numValue: number;
	public value: string;
	public ruleId: string;

	public get boolValue(): boolean {
		return this.numValue == 1;
	}
	public set boolValue(value:boolean) {
		this.numValue = value ? 1 : 0;
	}
	
	public override update(dto: IRS.IRSProjRuleInfo): void {
		this.numValue = dto.numValue;
		this.value = dto.value;
		this.ruleId = dto.ruleId;
	}
}

export class RSUpdateProjectImage implements IRS.IRSUpdateProjectImage {

	public imageId: string;

	public update(dto: IRS.IRSUpdateProjectImage) : void {
		this.imageId = dto.imageId;
	}

	public deserialize(dto: IRS.IRSUpdateProjectImage) : RSUpdateProjectImage {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSUpdateProjectImage);
		pd.update(dto);
		return pd;
	}
}

export class RSGetMsixBundles implements IRS.IRSGetMsixBundles {

	public items: Array<RSMsixBundle> = [];

	public update(dto: IRS.IRSGetMsixBundles) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSMsixBundle);
	}

	public deserialize(dto: IRS.IRSGetMsixBundles) : RSGetMsixBundles {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetMsixBundles);
		pd.update(dto);
		return pd;
	}
}

export class RSMsixBundle implements IRS.IRSMsixBundle {

	public id: string;
	public display: string;

	public update(dto: IRS.IRSMsixBundle) : void {
		this.id = dto.id;
		this.display = dto.display;
	}

	public deserialize(dto: IRS.IRSMsixBundle) : RSMsixBundle {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSMsixBundle);
		pd.update(dto);
		return pd;
	}
}

export class RSIdNameValue implements IRS.IRSIdNameValue {

	public id: string;
	public name: string;
	public value: string;

	public update(dto: IRS.IRSIdNameValue) : void {
		this.id = dto.id;
		this.name = dto.name;
		this.value = dto.value;
	}

	public deserialize(dto: IRS.IRSIdNameValue) : RSIdNameValue {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSIdNameValue);
		pd.update(dto);
		return pd;
	}
}

export class CRSNameValuePair implements IRS.IRSNameValuePair {

	public name: string;
	public value: string;

	public update(dto: IRS.IRSNameValuePair) : void {
		this.name = dto.name;
		this.value = dto.value;
	}
}

export class RSGetProjectImage implements IRS.IRSGetProjectImage {

	public imageData: string;

	public update(dto: IRS.IRSGetProjectImage) : void {
		this.imageData = dto.imageData;
	}

	public deserialize(dto: IRS.IRSGetProjectImage) : RSGetProjectImage {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetProjectImage);
		pd.update(dto);
		return pd;
	}
}

export class RSGetProjectOutput implements IRS.IRSGetProjectOutput {

	public filename: string;
	public outputName: string;

	public update(dto: IRS.IRSGetProjectOutput) : void {
		this.filename = dto.filename;
		this.outputName = dto.outputName;
	}

	public deserialize(dto: IRS.IRSGetProjectOutput) : RSGetProjectOutput {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetProjectOutput);
		pd.update(dto);
		return pd;
	}

	public static factory(): RSGetProjectOutput {
		return new RSGetProjectOutput()
	}
}

export class RSGetProjects implements IRS.IRSGetProjects {

	public projects: Array<RSProject> = [];
	public updatedActivity: string;
	public noChange: boolean;

	public update(dto: IRS.IRSGetProjects) : void {
		DataMerger.MergeHelper.ListMerge(dto.projects, this.projects,  (i) => { return i.id },  RSProject);
		this.updatedActivity = dto.updatedActivity;
		this.noChange = dto.noChange;
	}

	public deserialize(dto: IRS.IRSGetProjects) : RSGetProjects {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetProjects);
		pd.update(dto);
		return pd;
	}
}

export class RSSearchProjects implements IRS.IRSSearchProjects {

	public projects: Array<RSProject> = [];
	public updatedActivity: string;
	public noChange: boolean;

	public update(dto: IRS.IRSSearchProjects) : void {
		DataMerger.MergeHelper.ListMerge(dto.projects, this.projects,  (i) => { return i.id },  RSProject);
		this.updatedActivity = dto.updatedActivity;
		this.noChange = dto.noChange;
	}

	public deserialize(dto: IRS.IRSSearchProjects) : RSSearchProjects {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSSearchProjects);
		pd.update(dto);
		return pd;
	}
}

export class RSGetProjectStandardsDifferences implements IRS.IRSGetProjectStandardsDifferences {

	public projectId: string;
	public otherProjectId: string;
	public items: Array<RSPrjStdRuleDiff> = [];

	public update(dto: IRS.IRSGetProjectStandardsDifferences) : void {
		this.projectId = dto.projectId;
		this.otherProjectId = dto.otherProjectId;
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.ruleId },  RSPrjStdRuleDiff);
	}

	public  deserialize(dto: IRS.IRSGetProjectStandardsDifferences) : RSGetProjectStandardsDifferences {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetProjectStandardsDifferences);
		pd.update(dto);
		return pd;
	}
}

export class RSGetReportingConfigurations implements IRS.IRSGetReportingConfigurations {

	public items: Array<string> = [];

	public update(dto: IRS.IRSGetReportingConfigurations) : void {
		this.items = dto.items;
	}

	public deserialize(dto: IRS.IRSGetReportingConfigurations) : RSGetReportingConfigurations {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetReportingConfigurations);
		pd.update(dto);
		return pd;
	}
}

export class RSGetReadmeDocumentationScripts implements IRS.IRSGetReadmeDocumentationScripts {

	public items: Array<string> = [];

	public update(dto: IRS.IRSGetReadmeDocumentationScripts) : void {
		this.items = dto.items;
	}

	public deserialize(dto: IRS.IRSGetReadmeDocumentationScripts) : RSGetReadmeDocumentationScripts {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetReadmeDocumentationScripts);
		pd.update(dto);
		return pd;
	}
}


export class RSGetUserActivity implements IRS.IRSGetUserActivity {

	public items: Array<RSActivityItem> = [];

	public update(dto: IRS.IRSGetUserActivity) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSActivityItem);
	}

	public deserialize(dto: IRS.IRSGetUserActivity) : RSGetUserActivity {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetUserActivity);
		pd.update(dto);
		return pd;
	}
}

export class RSGetUserGroup implements IRS.IRSGetUserGroup {

	public users: Array<RSUser> = [];

	public update(dto: IRS.IRSGetUserGroup) : void {
		DataMerger.MergeHelper.ListMerge(dto.users, this.users,  (i) => { return i.userId },  RSUser);
	}

	public deserialize(dto: IRS.IRSGetUserGroup) : RSGetUserGroup {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetUserGroup);
		pd.update(dto);
		return pd;
	}
}

export class RSUser implements IRS.IRSUser {

	public userId: string;
	public name: string;

	public update(dto: IRS.IRSUser) : void {
		this.userId = dto.userId;
		this.name = dto.name;
	}

	public deserialize(dto: IRS.IRSUser) : RSUser {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSUser);
		pd.update(dto);
		return pd;
	}
}

export class RSStyling implements IRS.IRSStyling {

	public headerImage: string;
	public headerBackgroundColour: string;
	public headerForegroundColour: string;
	public primaryPalette: RSStylingPalette;

	public update(dto: IRS.IRSStyling) : void {
		this.headerImage = dto.headerImage;
		this.headerBackgroundColour = dto.headerBackgroundColour;
		this.headerForegroundColour = dto.headerForegroundColour;
		if (!this.primaryPalette)
			this.primaryPalette =new RSStylingPalette();
		this.primaryPalette.contrastDefaultColour = dto.primaryPalette.contrastDefaultColour;
		this.primaryPalette.contrastDarkColours = dto.primaryPalette.contrastDarkColours;
		this.primaryPalette.contrastLightColours = dto.primaryPalette.contrastLightColours;
	}

	public deserialize(dto: IRS.IRSStyling) : RSStyling {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSStyling);
		pd.update(dto);
		return pd;
	}
}

export class RSStylingPalette implements IRS.IRSStylingPalette {
	
	public standard: Array<RSNameValue>=[];
	public contrastDefaultColour: string;
	public contrastDarkColours: Array<string>=[];
	public contrastLightColours: Array<string>=[];

	public update(dto: IRS.IRSStylingPalette) : void {
		DataMerger.MergeHelper.ListMerge(dto.standard, this.standard,  (i) => { return i.name },  RSNameValue);
		this.contrastDefaultColour = dto.contrastDefaultColour;
		this.contrastDarkColours = dto.contrastDarkColours;
		this.contrastLightColours = dto.contrastLightColours;
	}

	public deserialize(dto: IRS.IRSStylingPalette) : RSStylingPalette {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSStylingPalette);
		pd.update(dto);
		return pd;
	}
}

export class RSGetStyling implements IRS.IRSGetStyling {

	public value: RSStyling;
	public apiEndpoint: string;

	public update(dto: IRS.IRSGetStyling) : void {
		this.apiEndpoint = dto.apiEndpoint;
		if (!this.value)
			this.value = new RSStyling();
		if (dto!=null && dto.value != null)
			this.value.update(dto.value);
		else 
			this.value=null;
	}

	public deserialize(dto: IRS.IRSGetStyling) : RSGetStyling {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetStyling);
		pd.update(dto);
		return pd;
	}
}

export class RSGetUserInformation implements IRS.IRSGetUserInformation {

	public id: string;
	public userName: string;
	public userEmail: string;
	public validated: boolean;
	public lastSelectedProfileId: string;
	public columnProfiles: Array<RSUserColumnProfile> = [];
	public contextId: string;
	public servers: Array<RSServerItem> = [];
	public versionNo: string;
	public backEndVersionNo: string;
	public jobRouterName: string;
	public privileges: IRS.IRSUserPrivilege;

	public update(dto: IRS.IRSGetUserInformation) : void {
		this.id = dto.id;
		this.userName = dto.userName;
		this.userEmail = dto.userEmail;
		this.validated = dto.validated;
		this.lastSelectedProfileId = dto.lastSelectedProfileId;
		this.contextId = dto.contextId;
		this.versionNo = dto.versionNo;
		this.backEndVersionNo = dto.backEndVersionNo;
		this.jobRouterName =dto.jobRouterName;
		this.privileges = dto.privileges;
		DataMerger.MergeHelper.ListMerge(dto.servers, this.servers, (i)=> { return i.id }, RSServerItem);
		DataMerger.MergeHelper.ListMerge(dto.columnProfiles, this.columnProfiles,  (i) => { return i.profileId },  RSUserColumnProfile);
	}

	public deserialize(dto: IRS.IRSGetUserInformation) : RSGetUserInformation {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetUserInformation);
		pd.update(dto);
		return pd;
	}
}

export class RSUserPrivilege implements IRS.IRSUserPrivilege {

	public admin: boolean;
	public remotingUrl: boolean;
	public copyVmUrl: boolean;
	public msiEd: boolean;
	public visEnh: boolean;
	public xCheck: boolean;
	public canQuery: boolean;
	public canConvert: boolean;
	public canPatch: boolean;
	public canViewActions: boolean;
	public canFix: boolean;
	public canImportApp: boolean;
	public projEdit: boolean;
	public canViewSettings:boolean;
	public canEditSettings:boolean;
	public canViewReports:boolean;
	public canDisableCheck:boolean;
	public canDisableAg:boolean;
	public canViewSource:boolean;
	public canLaunchSourceScan:boolean;
	public canSelectApplication:boolean;
	public canUpdateApplicationWorkflowDetails:boolean;
	public canUpdateApplicationMetaData:boolean;
	public canViewApplicationMetaData:boolean;
	public canTestApplication:boolean;
	public canViewApplicationPrerequisites:boolean;
	public canEditApplicationPrerequisites:boolean;
	public canViewApplicationOutputs:boolean;
	public canEditApplicationCoreData:boolean;
	public canAddApplication:boolean;
	public canEditClickerScripts:boolean;
	public canSendCustomerMessages:boolean;

	public update(dto: IRS.IRSUserPrivilege) : void {
		this.admin = dto.admin;
		this.remotingUrl = dto.remotingUrl;
		this.copyVmUrl = dto.copyVmUrl;
		this.msiEd = dto.msiEd;
		this.visEnh = dto.visEnh;
		this.xCheck = dto.xCheck;
		this.canQuery = dto.canQuery;
		this.canConvert = dto.canConvert;
		this.canPatch = dto.canPatch;
		this.canViewActions = dto.canViewActions;
		this.canFix = dto.canFix;
		this.canImportApp = dto.canImportApp;
		this.projEdit = dto.projEdit;
		this.canViewSettings =dto.canViewSettings;
		this.canEditSettings = dto.canEditSettings;
		this.canViewReports = dto.canViewReports;
		this.canDisableCheck = dto.canDisableCheck;
		this.canDisableAg=dto.canDisableAg;
		this.canViewSource =dto.canViewSource;
		this.canLaunchSourceScan=dto.canLaunchSourceScan; 
		this.canSelectApplication =dto.canSelectApplication;
		this.canUpdateApplicationWorkflowDetails = dto.canUpdateApplicationWorkflowDetails;
		this.canUpdateApplicationMetaData = dto.canUpdateApplicationMetaData;
		this.canViewApplicationMetaData = dto.canViewApplicationMetaData;
		this.canTestApplication = dto.canTestApplication;
		this.canViewApplicationPrerequisites = dto.canViewApplicationPrerequisites;
		this.canEditApplicationPrerequisites =dto.canEditApplicationPrerequisites;
		this.canViewApplicationOutputs = dto.canViewApplicationOutputs;
		this.canEditApplicationCoreData = dto.canEditApplicationCoreData;
		this.canAddApplication =dto.canAddApplication;
		this.canEditClickerScripts =dto.canEditClickerScripts;
		this.canSendCustomerMessages = dto.canSendCustomerMessages;
	}

	public deserialize(dto: IRS.IRSUserPrivilege) : RSUserPrivilege {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSUserPrivilege);
		pd.update(dto);
		return pd;
	}
}

export class RSServerItem implements IRS.IRSServerItem {

	public id: string;
	public display: string;

	public update(dto: IRS.IRSServerItem) : void {
		this.id =dto.id;
		this.display = dto.display;
	}

	public deserialize(dto: IRS.IRSServerItem) : RSServerItem {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSServerItem);
		pd.update(dto);
		return pd;
	}
}

export class RSGetUsers implements IRS.IRSGetUsers {

	public userEmails: Array<string> = [];

	public update(dto: IRS.IRSGetUsers) : void {
		this.userEmails = dto.userEmails;
	}

	public deserialize(dto: IRS.IRSGetUsers) : RSGetUsers {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetUsers);
		pd.update(dto);
		return pd;
	}
}

export class RSGetVhds
		extends RSBase<RSGetVhds, IRS.IRSGetVhds> implements IRS.IRSGetVhds {

	constructor() { super(RSGetVhds); }

	public items: Array<RSVhd> = [];

	public update(dto: IRS.IRSGetVhds) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSVhd);
	}
}

export class RSGetReferenceData
		extends RSBase<RSGetReferenceData, IRS.IRSGetReferenceData> implements IRS.IRSGetReferenceData {

	constructor() { super(RSGetReferenceData); }

	public vhds: RSGetVhds = new RSGetVhds();
	public reportingConfigurations: RSGetReportingConfigurations =new RSGetReportingConfigurations();
	public readmeDocumentationScripts: RSGetReadmeDocumentationScripts = new RSGetReadmeDocumentationScripts();
	public xcheckAvailableVms: RSGetXcheckAvailableVms = new RSGetXcheckAvailableVms();

	public update(dto: IRS.IRSGetReferenceData) : void {
		this.vhds.update(dto.vhds);
		this.reportingConfigurations.update(dto.reportingConfigurations);
		this.readmeDocumentationScripts.update(dto.readmeDocumentationScripts);
		this.xcheckAvailableVms.update(dto.xcheckAvailableVms);
	};
}

export class RSVhd 
		extends RSBase<RSVhd, IRS.IRSVhd> implements IRS.IRSVhd {

	constructor() { super(RSVhd); }

	public id: string;

	public update(dto: IRS.IRSVhd) { super.copy(dto); }
}

export class RSGetXcheckAvailableVms implements IRS.IRSGetXcheckAvailableVms {

	public items: Array<RSVirtualMachine> = [];

	public update(dto: IRS.IRSGetXcheckAvailableVms) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSVirtualMachine);
	}

	public deserialize(dto: IRS.IRSGetXcheckAvailableVms) : RSGetXcheckAvailableVms {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetXcheckAvailableVms);
		pd.update(dto);
		return pd;
	}
}

export class RSWorkflowStageInfo implements IRS.IRSWorkflowStageInfo {

	public id: number;
	public name: string;
	public counts: Array<RSItemCount> = [];
	public percentage: number;

	public update(dto: IRS.IRSWorkflowStageInfo) : void {
		this.id = dto.id;
		this.name = dto.name;
		DataMerger.MergeHelper.ListMerge(dto.counts, this.counts,  (i) => { return i.key },  RSItemCount);
		this.percentage = dto.percentage;
	}

	public deserialize(dto: IRS.IRSWorkflowStageInfo) : RSWorkflowStageInfo {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSWorkflowStageInfo);
		pd.update(dto);
		return pd;
	}
}

export class RSItemCount implements IRS.IRSItemCount {

	public key: string;
	public count: number;

	public update(dto: IRS.IRSItemCount) : void {
		this.key = dto.key;
		this.count = dto.count;
	}

	public deserialize(dto: IRS.IRSItemCount) : RSItemCount {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSItemCount);
		pd.update(dto);
		return pd;
	}
}

export class RSGetXCheckMetrics implements IRS.IRSGetXCheckMetrics {

	public projectId: string;
	public disabled: boolean;
	public operatingSystems: Array<RSXCheckMetricOperatingSystem> = [];

	public update(dto: IRS.IRSGetXCheckMetrics) : void {
		this.projectId = dto.projectId;
		this.disabled = dto.disabled;
		DataMerger.MergeHelper.ListMerge(dto.operatingSystems, this.operatingSystems,  (i) => { return i.id },  RSXCheckMetricOperatingSystem);
	}

	public deserialize(dto: IRS.IRSGetXCheckMetrics) : RSGetXCheckMetrics {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetXCheckMetrics);
		pd.update(dto);
		return pd;
	}
}

export class RSCreateProject implements IRS.IRSCreateProject {
	public projectId: string;
	public update(dto: IRS.IRSCreateProject) : void {
		this.projectId = dto.projectId;
	}

	public deserialize(dto: IRS.IRSCreateProject) : RSCreateProject {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSCreateProject);
		pd.update(dto);
		return pd;
	}
}

export class RSHasProject implements IRS.IRSHasProject {

	public newestProject: string;

	public update(dto: IRS.IRSHasProject) : void {
		this.newestProject = dto.newestProject;
	}

	public deserialize(dto: IRS.IRSHasProject) : RSHasProject {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSHasProject);
		pd.update(dto);
		return pd;
	}
}

export class RSHeartbeatInfo implements IRS.IRSHeartbeatInfo {

	public expression: string;
	public status: number;
	public versionNo: string;

	public update(dto: IRS.IRSHeartbeatInfo) : void {
		this.expression = dto.expression;
		this.status = dto.status;
		this.versionNo = dto.versionNo;
	}

	public deserialize(dto: IRS.IRSHeartbeatInfo) : RSHeartbeatInfo {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSHeartbeatInfo);
		pd.update(dto);
		return pd;
	}
}

export class RSImageItem implements IRS.IRSImageItem {

	public id: string;
	public data: string;

	public update(dto: IRS.IRSImageItem) : void {
		this.id = dto.id;
		this.data = dto.data;
	}

	public deserialize(dto: IRS.IRSImageItem) : RSImageItem {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSImageItem);
		pd.update(dto);
		return pd;
	}
}

export class RSInstaller implements IRS.IRSInstaller {

	public id: string;
	public filename: string;
	public path: string;
	public name: string;
	public arguments: string;
	public platformsWithFixes: Array<number> = [];
	public useFixTransform: boolean;
	public order: number;
	public hasBeenRepackaged: boolean;
	public hasResponseTransform: boolean;
	public defaultMsiArgs: string;
	public selected: boolean;
	public installAsPrerequisite: boolean;
	public useRepack: boolean;
	public useResponseTransform: boolean;

	public update(dto: IRS.IRSInstaller) : void {
		this.id = dto.id;
		this.filename = dto.filename;
		this.path = dto.path;
		this.name = dto.name;
		this.arguments = dto.arguments;
		this.platformsWithFixes = dto.platformsWithFixes;
		this.useFixTransform = dto.useFixTransform;
		this.order = dto.order;
		this.hasBeenRepackaged = dto.hasBeenRepackaged;
		this.hasResponseTransform = dto.hasResponseTransform;
		this.defaultMsiArgs = dto.defaultMsiArgs;
		this.selected = dto.selected;
		this.installAsPrerequisite = dto.installAsPrerequisite;
		this.useRepack = dto.useRepack;
		this.useResponseTransform = dto.useResponseTransform;
	}

	public deserialize(dto: IRS.IRSInstaller) : RSInstaller {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSInstaller);
		pd.update(dto);
		return pd;
	}
}

export class RSIssue implements IRS.IRSIssue {

	public id: string;
	public text: string;
	public category: number;
	public canAutoFix: boolean;
	public checkId: string;
	public checkName: string;
	public ruleId: string;
	public applicationId: string;
	public applicationName: string;
	public assGrp: string;
	public fixState: number;
	public custom: string;
	public custom2: string;
	public custom3: string;
	public isSelected: boolean;
	public assessmentStates: Array<RSRAG> = [];
	public assessmentStatesDictionary : { [platformNumber:string]: RSRAG };

	public update(dto: IRS.IRSIssue) : void {
		this.id = dto.id;
		this.text = dto.text;
		this.category = dto.category;
		this.canAutoFix = dto.canAutoFix;
		this.checkId = dto.checkId;
		this.checkName = dto.checkName;
		this.ruleId = dto.ruleId;
		this.applicationId = dto.applicationId;
		this.applicationName = dto.applicationName;
		this.assGrp = dto.assGrp;
		this.fixState = dto.fixState;
		this.custom = dto.custom;
		this.custom2 = dto.custom2;
		this.custom3 = dto.custom3;
		this.isSelected = dto.isSelected;
		DataMerger.MergeHelper.ListMerge(dto.assessmentStates, this.assessmentStates,  (i) => { return i.platformNumber },  RSRAG);
		this.assessmentStatesDictionary = {};
		this.assessmentStates.forEach(a => {
			this.assessmentStatesDictionary[a.platformNumber] = a;
		});
	}

	public deserialize(dto: IRS.IRSIssue) : RSIssue {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSIssue);
		pd.update(dto);
		return pd;
	}
}

export class RSIssueState implements IRS.IRSIssueState {

	public platformNumber: number;
	public fixState: number;

	public update(dto: IRS.IRSIssueState) : void {
		this.platformNumber = dto.platformNumber;
		this.fixState = dto.fixState;
	}

	public deserialize(dto: IRS.IRSIssueState) : RSIssueState {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSIssueState);
		pd.update(dto);
		return pd;
	}
}

export class RSIssueSummarisation implements IRS.IRSIssueSummarisation {

	public platformNumber: number;
	public autoFixCount: number;
	public manualFixCount: number;
	public ignoredCount: number;
	public autoFixedCount: number;
	public excludedCount: number;
	public remainingCount: number;
	public fixOnPublishCount: number;

	public update(dto: IRS.IRSIssueSummarisation) : void {
		this.platformNumber = dto.platformNumber;
		this.autoFixCount = dto.autoFixCount;
		this.manualFixCount = dto.manualFixCount;
		this.ignoredCount = dto.ignoredCount;
		this.autoFixedCount = dto.autoFixedCount;
		this.excludedCount = dto.excludedCount;
		this.remainingCount = dto.remainingCount;
		this.fixOnPublishCount = dto.fixOnPublishCount;
	}

	public deserialize(dto: IRS.IRSIssueSummarisation) : RSIssueSummarisation {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSIssueSummarisation);
		pd.update(dto);
		return pd;
	}
}

export class RSMetaAttribute implements IRS.IRSMetaAttribute {

	public typeId: number;
	public name: string;

	public update(dto: IRS.IRSMetaAttribute) : void {
		this.typeId = dto.typeId;
		this.name = dto.name;
	}

	public deserialize(dto: IRS.IRSMetaAttribute) : RSMetaAttribute {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSMetaAttribute);
		pd.update(dto);
		return pd;
	}
}

export class RSMetaEntity implements IRS.IRSMetaEntity {

	public eid: number;
	public name: string;
	public attributes: Array<RSMetaAttribute> = [];
	attributesLookup: DataMerger.IDictionary<RSMetaAttribute>

	public update(dto: IRS.IRSMetaEntity) : void {
		this.eid = dto.eid;
		this.name = dto.name;
		DataMerger.MergeHelper.ListMerge(dto.attributes, this.attributes,  (i) => { return i.typeId },  RSMetaAttribute);
		this.attributesLookup ={};
		this.attributes.forEach((i)=> {
			this.attributesLookup[i.typeId] = i
		});
	//attributesLookup: RSMetaAttribute
	}

	public getAttribute(id: number) {
			let r = this.attributesLookup[id];
			return r
	}

	public deserialize(dto: IRS.IRSMetaEntity) : RSMetaEntity {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSMetaEntity);
		pd.update(dto);
		return pd;
	}
}

export class RSMonthlyUpdate implements IRS.IRSMonthlyUpdate {

	public id: string;
	public year: number;
	public month: number;
	public display: string;

	public update(dto: IRS.IRSMonthlyUpdate) : void {
		this.id = dto.id;
		this.year = dto.year;
		this.month = dto.month;
		this.display = dto.display;
	}

	public deserialize(dto: IRS.IRSMonthlyUpdate) : RSMonthlyUpdate {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSMonthlyUpdate);
		pd.update(dto);
		return pd;
	}
}

export class RSMsiColumn implements IRS.IRSMsiColumn {

	public columnId: number;
	public table: string;
	public name: string;
	public dataTypeRaw: number;
	public dataType: string;
	public size: number;
	public primaryKeyOrdinal: number;
	public isRequired: boolean;
	public validationItems: Array<RSMsiValidationItem> = [];
	public foreignKeys: Array<RSMsiForeignKey> = [];

	public update(dto: IRS.IRSMsiColumn) : void {
		this.columnId = dto.columnId;
		this.table = dto.table;
		this.name = dto.name;
		this.dataTypeRaw = dto.dataTypeRaw;
		this.dataType = dto.dataType;
		this.size = dto.size;
		this.primaryKeyOrdinal = dto.primaryKeyOrdinal;
		this.isRequired = dto.isRequired;
		DataMerger.MergeHelper.ListMerge(dto.validationItems, this.validationItems,  (i) => { return i.itemId },  RSMsiValidationItem);
		DataMerger.MergeHelper.ListMerge(dto.foreignKeys, this.foreignKeys,  (i) => { return i.table },  RSMsiForeignKey);
	}

	public  deserialize(dto: IRS.IRSMsiColumn) : RSMsiColumn {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSMsiColumn);
		pd.update(dto);
		return pd;
	}
}

export class RSMsiColumnValue implements IRS.IRSMsiColumnValue {

	public columnId: number;
	public value: string;

	public update(dto: IRS.IRSMsiColumnValue) : void {
		this.columnId = dto.columnId;
		this.value = dto.value;
	}

	public deserialize(dto: IRS.IRSMsiColumnValue) : RSMsiColumnValue {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSMsiColumnValue);
		pd.update(dto);
		return pd;
	}
}

export class RSMsiFind implements IRS.IRSMsiFind {

	public dateModified: number;
	public status: number;
	public results: Array<RSMsiFindResult> = [];

	public update(dto: IRS.IRSMsiFind) : void {
		this.dateModified = dto.dateModified;
		this.status = dto.status;
		DataMerger.MergeHelper.ListMerge(dto.results, this.results,  (i) => { return i.itemId },  RSMsiFindResult);
	}

	public deserialize(dto: IRS.IRSMsiFind) : RSMsiFind {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSMsiFind);
		pd.update(dto);
		return pd;
	}
}

export class RSMsiFindResult implements IRS.IRSMsiFindResult {

	public itemId: number;
	public table: string;
	public primaryKey: Array<string> = [];

	public update(dto: IRS.IRSMsiFindResult) : void {
		this.itemId = dto.itemId;
		this.table = dto.table;
		this.primaryKey = dto.primaryKey;
	}

	public deserialize(dto: IRS.IRSMsiFindResult) : RSMsiFindResult {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSMsiFindResult);
		pd.update(dto);
		return pd;
	}
}

export class RSMsiForeignKey implements IRS.IRSMsiForeignKey {

	public table: string;
	public sourceId: string;
	public targetId: string;

	public update(dto: IRS.IRSMsiForeignKey) : void {
		this.table = dto.table;
		this.sourceId = dto.sourceId;
		this.targetId = dto.targetId;
	}

	public deserialize(dto: IRS.IRSMsiForeignKey) : RSMsiForeignKey {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSMsiForeignKey);
		pd.update(dto);
		return pd;
	}
}

export class RSMsiGetPackageDetails implements IRS.IRSMsiGetPackageDetails {

	public applicationId: string;
	public projectId: string;
	public applicationName: string;
	public applicationFriendlyName: string;
	public importedTransforms: string;
	public projectName: string;
	public dateModified: number;
	public status: number;
	public statusMessage: string;
	public summaryInformation: RSMsiSummaryInformation;
	public tables: Array<RSMsiTable> = [];
	public findResults: Array<RSMsiFindResult> = [];

	public update(dto: IRS.IRSMsiGetPackageDetails) : void {
		this.applicationId = dto.applicationId;
		this.projectId = dto.projectId;
		this.applicationName = dto.applicationName;
		this.applicationFriendlyName = dto.applicationFriendlyName;
		this.importedTransforms = dto.importedTransforms;
		this.projectName = dto.projectName;
		this.dateModified = dto.dateModified;
		this.status = dto.status;
		this.statusMessage = dto.statusMessage;
		if (this.summaryInformation == null && dto.summaryInformation != null)
			this.summaryInformation = new RSMsiSummaryInformation();
		if (dto.summaryInformation == null)
			this.summaryInformation = null
		else
			this.summaryInformation.update(dto.summaryInformation);

		DataMerger.MergeHelper.ListMerge(dto.tables, this.tables,  (i) => { return i.name },  RSMsiTable);
		DataMerger.MergeHelper.ListMerge(dto.findResults, this.findResults,  (i) => { return i.itemId },  RSMsiFindResult);
	}

	public deserialize(dto: IRS.IRSMsiGetPackageDetails) : RSMsiGetPackageDetails {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSMsiGetPackageDetails);
		pd.update(dto);
		return pd;
	}
}

export class RSMsiGetPackageIssueData implements IRS.IRSMsiGetPackageIssueData {

	public applicationId: string;
	public issues: Array<RSMsiIssue> = [];

	public update(dto: IRS.IRSMsiGetPackageIssueData) : void {
		this.applicationId = dto.applicationId;
		DataMerger.MergeHelper.ListMerge(dto.issues, this.issues,  (i) => { return i.issueId },  RSMsiIssue);
	}

	public  deserialize(dto: IRS.IRSMsiGetPackageIssueData) : RSMsiGetPackageIssueData {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSMsiGetPackageIssueData);
		pd.update(dto);
		return pd;
	}
}

export class RSMsiGetPackageTableData implements IRS.IRSMsiGetPackageTableData {

	public applicationId: string;
	public projectId: string;
	public applicationName: string;
	public applicationFriendlyName: string;
	public importedTransforms: string;
	public projectName: string;
	public dateModified: number;
	public status: number;
	public summaryInformation: RSMsiSummaryInformation;
	public tables: Array<RSMsiTable> = [];
	public findResults: Array<RSMsiFindResult> = [];

	public update(dto: IRS.IRSMsiGetPackageTableData) : void {
		this.applicationId = dto.applicationId;
		this.projectId = dto.projectId;
		this.applicationName = dto.applicationName;
		this.applicationFriendlyName = dto.applicationFriendlyName;
		this.importedTransforms = dto.importedTransforms;
		this.projectName = dto.projectName;
		this.dateModified = dto.dateModified;
		this.status = dto.status;
		if (this.summaryInformation == null && dto.summaryInformation != null)
			this.summaryInformation = new RSMsiSummaryInformation();
		if (dto.summaryInformation == null)
			this.summaryInformation = null
		else
			this.summaryInformation.update(dto.summaryInformation);

		DataMerger.MergeHelper.ListMerge(dto.tables, this.tables,  (i) => { return i.name },  RSMsiTable);
		DataMerger.MergeHelper.ListMerge(dto.findResults, this.findResults,  (i) => { return i.itemId },  RSMsiFindResult);
	}

	public deserialize(dto: IRS.IRSMsiGetPackageTableData) : RSMsiGetPackageTableData {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSMsiGetPackageTableData);
		pd.update(dto);
		return pd;
	}
}

export class RSMsiIssue implements IRS.IRSMsiIssue {

	public issueId: string;
	public tableName: string;
	public rowKey: string;
	public checkName: string;
	public message: string;

	public update(dto: IRS.IRSMsiIssue) : void {
		this.issueId = dto.issueId;
		this.tableName = dto.tableName;
		this.rowKey = dto.rowKey;
		this.checkName = dto.checkName;
		this.message = dto.message;
	}

	public deserialize(dto: IRS.IRSMsiIssue) : RSMsiIssue {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSMsiIssue);
		pd.update(dto);
		return pd;
	}
}

export class RSMsiRow implements IRS.IRSMsiRow {

	public rowId: number;
	public columns: Array<RSMsiColumnValue> = [];

	public update(dto: IRS.IRSMsiRow) : void {
		this.rowId = dto.rowId;
		DataMerger.MergeHelper.ListMerge(dto.columns, this.columns,  (i) => { return i.columnId },  RSMsiColumnValue);
	}

	public deserialize(dto: IRS.IRSMsiRow) : RSMsiRow {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSMsiRow);
		pd.update(dto);
		return pd;
	}
}

export class RSMsiSummaryInfoItem implements IRS.IRSMsiSummaryInfoItem {

	public key: string;
	public type: string;
	public value: string;

	public update(dto: IRS.IRSMsiSummaryInfoItem) : void {
		this.key = dto.key;
		this.type = dto.type;
		this.value = dto.value;
	}

	public deserialize(dto: IRS.IRSMsiSummaryInfoItem) : RSMsiSummaryInfoItem {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSMsiSummaryInfoItem);
		pd.update(dto);
		return pd;
	}
}

export class RSMsiSummaryInformation implements IRS.IRSMsiSummaryInformation {

	public data: Array<RSMsiSummaryInfoItem> = [];

	public update(dto: IRS.IRSMsiSummaryInformation) : void {
		DataMerger.MergeHelper.ListMerge(dto.data, this.data,  (i) => { return i.key },  RSMsiSummaryInfoItem);
	}

	public deserialize(dto: IRS.IRSMsiSummaryInformation) : RSMsiSummaryInformation {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSMsiSummaryInformation);
		pd.update(dto);
		return pd;
	}
}

export class RSMsiTable implements IRS.IRSMsiTable {

	public name: string;
	public columns: Array<RSMsiColumn> = [];
	public rows: Array<RSMsiRow> = [];
	public isEmpty: boolean;

	public update(dto: IRS.IRSMsiTable) : void {
		this.name = dto.name;
		DataMerger.MergeHelper.ListMerge(dto.columns, this.columns,  (i) => { return i.columnId },  RSMsiColumn);
		DataMerger.MergeHelper.ListMerge(dto.rows, this.rows,  (i) => { return i.rowId },  RSMsiRow);
		this.isEmpty = dto.isEmpty;
	}

	public deserialize(dto: IRS.IRSMsiTable) : RSMsiTable {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSMsiTable);
		pd.update(dto);
		return pd;
	}
}

export class RSMsiValidationItem implements IRS.IRSMsiValidationItem {

	public itemId: number;
	public table: string;
	public column: string;
	public nullable: boolean;
	public minValue: number;
	public maxValue: number;
	public keyTable: string;
	public keyColumn: number;
	public category: string;
	public set: string;
	public description: string;

	public update(dto: IRS.IRSMsiValidationItem) : void {
		this.itemId = dto.itemId;
		this.table = dto.table;
		this.column = dto.column;
		this.nullable = dto.nullable;
		this.minValue = dto.minValue;
		this.maxValue = dto.maxValue;
		this.keyTable = dto.keyTable;
		this.keyColumn = dto.keyColumn;
		this.category = dto.category;
		this.set = dto.set;
		this.description = dto.description;
	}

	public deserialize(dto: IRS.IRSMsiValidationItem) : RSMsiValidationItem {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSMsiValidationItem);
		pd.update(dto);
		return pd;
	}
}

export class RSNameValue implements IRS.IRSNameValue {

	public name: string;
	public value: string;

	public update(dto: IRS.IRSNameValue) : void {
		this.name = dto.name;
		this.value = dto.value;
	}

	public deserialize(dto: IRS.IRSNameValue) : RSNameValue {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSNameValue);
		pd.update(dto);
		return pd;
	}
}

export class RSNotificationItem implements IRS.IRSNotificationItem {

	public id: number;
	public type: number;
	public dateQueued: string;
	public dateStarted: string;
	public dateCompleted: string;
	public status: number;
	public description: string;
	public applicationId: number;
	public applicationName: string;
	public subType: string;
	public imageId: string;

	public update(dto: IRS.IRSNotificationItem) : void {
		this.id = dto.id;
		this.type = dto.type;
		this.dateQueued = dto.dateQueued;
		this.dateStarted = dto.dateStarted;
		this.dateCompleted = dto.dateCompleted;
		this.status = dto.status;
		this.description = dto.description;
		this.applicationId = dto.applicationId;
		this.applicationName = dto.applicationName;
		this.subType = dto.subType;
		this.imageId = dto.imageId;
	}

	public deserialize(dto: IRS.IRSNotificationItem) : RSNotificationItem {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSNotificationItem);
		pd.update(dto);
		return pd;
	}
}

export class RSNotifyItem implements IRS.IRSNotifyItem {

	public index: number;
	public text: string;
	public hashCode: number;

	public update(dto: IRS.IRSNotifyItem) : void {
		this.index = dto.index;
		this.text = dto.text;
		this.hashCode = dto.hashCode;
	}

	public deserialize(dto: IRS.IRSNotifyItem) : RSNotifyItem {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSNotifyItem);
		pd.update(dto);
		return pd;
	}
}

export class RSOfficeSuite implements IRS.IRSOfficeSuite {

	public id: string;
	public display: string;
	public product: string;

	public update(dto: IRS.IRSOfficeSuite) : void {
		this.id = dto.id;
		this.display = dto.display;
		this.product = dto.product;
	}

	public deserialize(dto: IRS.IRSOfficeSuite) : RSOfficeSuite {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSOfficeSuite);
		pd.update(dto);
		return pd;
	}
}

export class RSOperatingSystem implements IRS.IRSOperatingSystem {

	public id: string;
	public display: string;
	public architecture: string;
	public osRef: string;
	public build: string;
	public type: string;
	public product: string;
	public trackedItems: Array<RSOperatingSystemTrackedItem> = [];

	public update(dto: IRS.IRSOperatingSystem) : void {
		this.id = dto.id;
		this.display = dto.display;
		this.architecture = dto.architecture;
		this.osRef = dto.osRef;
		this.build = dto.build;
		this.type = dto.type;
		this.product = dto.product;
		DataMerger.MergeHelper.ListMerge(dto.trackedItems, this.trackedItems,  (i) => { return i.checkpoint },  RSOperatingSystemTrackedItem);
	}

	public deserialize(dto: IRS.IRSOperatingSystem) : RSOperatingSystem {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSOperatingSystem);
		pd.update(dto);
		return pd;
	}
}

export class RSOperatingSystemTrackedItem implements IRS.IRSOperatingSystemTrackedItem {

	public checkpoint: string;
	public display: string;
	public originalTestEnabled: boolean;
	public msixTestEnabled: boolean;

	public update(dto: IRS.IRSOperatingSystemTrackedItem) : void {
		this.checkpoint = dto.checkpoint;
		this.display = dto.display;
		this.originalTestEnabled = dto.originalTestEnabled;
		this.msixTestEnabled = dto.msixTestEnabled;
	}

	public deserialize(dto: IRS.IRSOperatingSystemTrackedItem) : RSOperatingSystemTrackedItem {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSOperatingSystemTrackedItem);
		pd.update(dto);
		return pd;
	}
}

export class RSPatch implements IRS.IRSPatch, IRSCheck {

	public uniqId: string;
	public id: string;
	public name: string;
	public description: string;
	public vulnerability: string;
	public threats: Array<RSPatchThreat> = [];
	public affectedSoftware: Array<string> = [];
	public exploitD: number;
	public exploitML: number;
	public exploitLL: number;
	public exploitUN: number;
	public exploitNA: number;
	public checkId: string;
	public monthId: string;
	public scores: Array<RSPatchProductScore> = [];
	public hasConflictInfoDefined: boolean;
	public conflictInfo: RSPatchConflictInfo;
	public productFamily: string;
	public platformElement: string;
	public impactRag: number;
	public remediationId: string;

	public update(dto: IRS.IRSPatch) : void {
		this.uniqId = dto.uniqId;
		this.id = dto.id;
		this.name = dto.name;
		this.description = dto.description;
		this.vulnerability = dto.vulnerability;
		DataMerger.MergeHelper.ListMerge(dto.threats, this.threats,  (i) => { return i.key },  RSPatchThreat);
		this.affectedSoftware = dto.affectedSoftware;
		this.exploitD = dto.exploitD;
		this.exploitML = dto.exploitML;
		this.exploitLL = dto.exploitLL;
		this.exploitUN = dto.exploitUN;
		this.exploitNA = dto.exploitNA;
		this.checkId = dto.checkId;
		this.monthId = dto.monthId;
		DataMerger.MergeHelper.ListMerge(dto.scores, this.scores,  (i) => { return i.osId },  RSPatchProductScore);
		this.hasConflictInfoDefined = dto.hasConflictInfoDefined;
		if (this.conflictInfo == null && dto.conflictInfo != null)
			this.conflictInfo = new RSPatchConflictInfo();
		if (dto.conflictInfo == null)
			this.conflictInfo = null
		else
			this.conflictInfo.update(dto.conflictInfo);

		this.productFamily = dto.productFamily;
		this.platformElement = dto.platformElement;
		this.impactRag = dto.impactRag;
		this.remediationId = dto.remediationId;
	}

	public deserialize(dto: IRS.IRSPatch) : RSPatch {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSPatch);
		pd.update(dto);
		return pd;
	}
}

export class RSPatchConflictDistribution implements IRS.IRSPatchConflictDistribution {

	public filename: string;
	public applicationCount: number;
	public fileCount: number;

	public update(dto: IRS.IRSPatchConflictDistribution) : void {
		this.filename = dto.filename;
		this.applicationCount = dto.applicationCount;
		this.fileCount = dto.fileCount;
	}

	public deserialize(dto: IRS.IRSPatchConflictDistribution) : RSPatchConflictDistribution {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSPatchConflictDistribution);
		pd.update(dto);
		return pd;
	}
}

export class RSPatchConflictInfo implements IRS.IRSPatchConflictInfo {

	public hasConflicts: boolean;
	public conflictingAppCount: number;
	public distributionItems: Array<RSPatchConflictDistribution> = [];

	public update(dto: IRS.IRSPatchConflictInfo) : void {
		this.hasConflicts = dto.hasConflicts;
		this.conflictingAppCount = dto.conflictingAppCount;
		DataMerger.MergeHelper.ListMerge(dto.distributionItems, this.distributionItems,  (i) => { return i.filename },  RSPatchConflictDistribution);
	}

	public deserialize(dto: IRS.IRSPatchConflictInfo) : RSPatchConflictInfo {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSPatchConflictInfo);
		pd.update(dto);
		return pd;
	}
}

export class RSPatchExportItem implements IRS.IRSPatchExportItem {

	public patchId: string;
	public year: number;
	public month: number;
	public checkId: string;
	public ruleSetName: string;
	public value: string;

	public update(dto: IRS.IRSPatchExportItem) : void {
		this.patchId = dto.patchId;
		this.year = dto.year;
		this.month = dto.month;
		this.checkId = dto.checkId;
		this.ruleSetName = dto.ruleSetName;
		this.value = dto.value;
	}

	public deserialize(dto: IRS.IRSPatchExportItem) : RSPatchExportItem {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSPatchExportItem);
		pd.update(dto);
		return pd;
	}
}

export class RSPatchGroup implements IRS.IRSPatchGroup {

	public id: string;
	public mnYr: string;
	public summary: string;
	public year: number;
	public month: number;
	public title: string;
	public versionNo: number;
	public patches: Array<RSPatch> = [];
	public checks: Array<RSRemediationCheck> = [];
	public checkCandidateGroups: Array<RSAdminCheckCandidateGrp> = [];
	public stateItems: Array<RSChkCandStateItem> = [];
	public isPlaceholder: boolean;
	public canComplete: boolean;

	public update(dto: IRS.IRSPatchGroup) : void {
		this.id = dto.id;
		this.mnYr = dto.mnYr;
		this.summary = dto.summary;
		this.year = dto.year;
		this.month = dto.month;
		this.title = dto.title;
		this.versionNo = dto.versionNo;
		this.isPlaceholder = dto.isPlaceholder;
		this.canComplete = dto.canComplete;
		DataMerger.MergeHelper.ListMerge(dto.patches, this.patches,  (i) => { return i.uniqId },  RSPatch);
		DataMerger.MergeHelper.ListMerge(dto.checks, this.checks,  (i) => { return i.id },  RSRemediationCheck);
		DataMerger.MergeHelper.ListMerge(dto.checkCandidateGroups, this.checkCandidateGroups,  (i) => { return i.key },  RSAdminCheckCandidateGrp);
		DataMerger.MergeHelper.ListMerge(dto.stateItems, this.stateItems,  (i) => { return i.key },  RSChkCandStateItem);
	}

	public  deserialize(dto: IRS.IRSPatchGroup) : RSPatchGroup {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSPatchGroup);
		pd.update(dto);
		return pd;
	}
}

export class RSPatchProductScore implements IRS.IRSPatchProductScore {

	public osId: string;
	public temporarlScore: number;
	public baseScore: number;

	public update(dto: IRS.IRSPatchProductScore) : void {
		this.osId = dto.osId;
		this.temporarlScore = dto.temporarlScore;
		this.baseScore = dto.baseScore;
	}

	public deserialize(dto: IRS.IRSPatchProductScore) : RSPatchProductScore {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSPatchProductScore);
		pd.update(dto);
		return pd;
	}
}

export class RSPatchThreat implements IRS.IRSPatchThreat {

	public key: string;
	public productIds: Array<string> = [];
	public severity: number;
	public impact: number;
	public baseScore: number;
	public temporalScore: number;

	public update(dto: IRS.IRSPatchThreat) : void {
		this.key = dto.key;
		this.productIds = dto.productIds;
		this.severity = dto.severity;
		this.impact = dto.impact;
		this.baseScore = dto.baseScore;
		this.temporalScore = dto.temporalScore;
	}

	public deserialize(dto: IRS.IRSPatchThreat) : RSPatchThreat {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSPatchThreat);
		pd.update(dto);
		return pd;
	}
}

export class RSPlatform implements IRS.IRSPlatform {

	public id: number;
	public platformGuid: string;
	public name: string;
	public osId: string;
	public osIdTo: string;
	public ostype: string;
	public officeSuite: string;
	public browser: string;
	public virtualisationTechnology: string;
	public dotNetVersion: string;
	public productIds: String[];
	public updateDate: string;
	public isDesktopPlatform: boolean;
	public hash: string;

	public update(dto: IRS.IRSPlatform) : void {
		this.id = dto.id;
		this.platformGuid = dto.platformGuid;
		this.name = dto.name;
		this.osId = dto.osId;
		this.osIdTo = dto.osIdTo;
		this.ostype = dto.ostype;
		this.officeSuite = dto.officeSuite;
		this.browser = dto.browser;
		this.virtualisationTechnology = dto.virtualisationTechnology;
		this.dotNetVersion = dto.dotNetVersion;
		this.productIds = dto.productIds;
		this.updateDate = dto.updateDate;
		this.isDesktopPlatform = dto.isDesktopPlatform;
		this.hash = dto.hash;
	}

	public deserialize(dto: IRS.IRSPlatform) : RSPlatform {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSPlatform);
		pd.update(dto);
		return pd;
	}
}

export class RSPrjStdRuleDiff implements IRS.IRSPrjStdRuleDiff {

	public ruleId: string;
	public ruleName: string;
	public diffType: string;

	public update(dto: IRS.IRSPrjStdRuleDiff) : void {
		this.ruleId = dto.ruleId;
		this.ruleName = dto.ruleName;
		this.diffType = dto.diffType;
	}

	public deserialize(dto: IRS.IRSPrjStdRuleDiff) : RSPrjStdRuleDiff {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSPrjStdRuleDiff);
		pd.update(dto);
		return pd;
	}
}

export class RSProject implements IRS.IRSProject {

	public id: string;
	public name: string;
	public description: string;
	public imageId: string;
	public isReady: boolean;
	public applicationCount: number;
	public assessmentGroupCount: number;
	public isOwner: boolean;
	public ownerEmail: string;
	public createdExpression:string;
	public platformRequirements: Array<string> = [];
	public enabled: boolean;

	public update(dto: IRS.IRSProject) : void {
		this.id = dto.id;
		this.name = dto.name;
		this.description = dto.description;
		this.imageId = dto.imageId;
		this.isReady = dto.isReady;
		this.applicationCount = dto.applicationCount;
		this.assessmentGroupCount = dto.assessmentGroupCount;
		this.isOwner = dto.isOwner;
		this.ownerEmail = dto.ownerEmail;
		this.createdExpression = dto.createdExpression;
		this.platformRequirements = dto.platformRequirements;
		this.enabled = dto.enabled;
	}

	public deserialize(dto: IRS.IRSProject) : RSProject {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSProject);
		pd.update(dto);
		return pd;
	}
}

export class RSSearchProjectPackageSourceFolders implements IRS.IRSSearchProjectPackageSourceFolders {

	public items: Array<string>=[];

	public update(dto: IRS.IRSSearchProjectPackageSourceFolders) : void {
		this.items =dto.items;
	}

	public deserialize(dto: IRS.IRSSearchProjectPackageSourceFolders) : RSSearchProjectPackageSourceFolders {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSSearchProjectPackageSourceFolders);
		pd.update(dto);
		return pd;
	}

	public static factory() : RSSearchProjectPackageSourceFolders {
		return new RSSearchProjectPackageSourceFolders();
	}

}

export class RSGetProjectMetrics implements IRS.IRSGetProjectMetrics {

	public metrics: Array<RSProjectMetric> = [];

	public update(dto: IRS.IRSGetProjectMetrics) : void {
		DataMerger.MergeHelper.ListMerge(dto.metrics, this.metrics,  (i) => { return i.id },  RSProjectMetric);
	}

	public deserialize(dto: IRS.IRSGetProjectMetrics) : RSGetProjectMetrics {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetProjectMetrics);
		pd.update(dto);
		return pd;
	}
}

export class RSProjectMetric implements IRS.IRSProjectMetric {

	public id: string;
	public metricId: string;
	public intervalId: string;
	public successCount: number;
	public failCount: number;

	public update(dto: IRS.IRSProjectMetric) : void {
		this.id = dto.id;
		this.metricId = dto.metricId;
		this.intervalId = dto.intervalId;
		this.successCount = dto.successCount;
		this.failCount = dto.failCount;
	}

	public deserialize(dto: IRS.IRSProjectMetric) : RSProjectMetric {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSProjectMetric);
		pd.update(dto);
		return pd;
	}
}

export class RSProjectRule implements IRS.IRSProjectRule {

	public projectRuleId: string;
	public ruleType: number;
	public targetId: string;

	public update(dto: IRS.IRSProjectRule) : void {
		this.projectRuleId = dto.projectRuleId;
		this.ruleType = dto.ruleType;
		this.targetId = dto.targetId;
	}

	public deserialize(dto: IRS.IRSProjectRule) : RSProjectRule {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSProjectRule);
		pd.update(dto);
		return pd;
	}
}

export class RSProjectShare implements IRS.IRSProjectShare {

	public userId: string;
	public userEmail: string;

	public update(dto: IRS.IRSProjectShare) : void {
		this.userId = dto.userId;
		this.userEmail = dto.userEmail;
	}

	public deserialize(dto: IRS.IRSProjectShare) : RSProjectShare {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSProjectShare);
		pd.update(dto);
		return pd;
	}
}

export class RSPublishApplication implements IRS.IRSPublishApplication {

	public jobId: string;

	public update(dto: IRS.IRSPublishApplication) : void {
		this.jobId = dto.jobId;
	}

	public deserialize(dto: IRS.IRSPublishApplication) : RSPublishApplication {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSPublishApplication);
		pd.update(dto);
		return pd;
	}
}

export class RSPublishedTest implements IRS.IRSPublishedTest {

	public jobId: string;

	public update(dto: IRS.IRSPublishedTest) : void {
		this.jobId = dto.jobId;
	}

	public  deserialize(dto: IRS.IRSPublishedTest) : RSPublishedTest {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSPublishedTest);
		pd.update(dto);
		return pd;
	}
}

export class RSGetApplicationIdFromJob implements IRS.IRSGetApplicationIdFromJob {

	public applicationId: string;

	public update(dto: IRS.IRSGetApplicationIdFromJob) : void {
		this.applicationId = dto.applicationId;
	}

	public deserialize(dto: IRS.IRSGetApplicationIdFromJob) : RSGetApplicationIdFromJob {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetApplicationIdFromJob);
		pd.update(dto);
		return pd;
	}

	public static factory(): RSGetApplicationIdFromJob {
		return new RSGetApplicationIdFromJob();
	}
}

export class RSQuery implements IRS.IRSQuery {

	public id: string;
	public queryName: string;
	public display: string;
	public eid: number;
	public typeId: number;
	public outputAttributes: Array<number> = [];
	public values: Array<string> = [];

	public update(dto: IRS.IRSQuery) : void {
		this.id = dto.id;
		this.queryName = dto.queryName;
		this.display = dto.display;
		this.eid = dto.eid;
		this.typeId = dto.typeId;
		this.outputAttributes = dto.outputAttributes;
		this.values = dto.values;
	}

	public deserialize(dto: IRS.IRSQuery) : RSQuery {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSQuery);
		pd.update(dto);
		return pd;
	}
}

export class RSQueryColumn implements IRS.IRSQueryColumn {

	public att: number;
	public value: string;

	public update(dto: IRS.IRSQueryColumn) : void {
		this.att = dto.att;
		this.value = dto.value;
	}

	public deserialize(dto: IRS.IRSQueryColumn) : RSQueryColumn {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSQueryColumn);
		pd.update(dto);
		return pd;
	}

	public static factory(): RSQueryColumn {
		return new RSQueryColumn()
	}

}

export class RSQueryColumnName implements IRS.IRSQueryColumnName {

	public id: number;
	public name: string;

	public update(dto: IRS.IRSQueryColumnName) : void {
		this.id = dto.id;
		this.name = dto.name;
	}

	public deserialize(dto: IRS.IRSQueryColumnName) : RSQueryColumnName {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSQueryColumnName);
		pd.update(dto);
		return pd;
	}
}

export class RSQueryRow implements IRS.IRSQueryRow {

	public id: number;
	public eid: number;
	public columns: Array<RSQueryColumn> = [];

	public update(dto: IRS.IRSQueryRow) : void {
		this.id = dto.id;
		this.eid = dto.eid;
		DataMerger.MergeHelper.ListMerge(dto.columns, this.columns,  (i) => { return i.att },  RSQueryColumn);
	}

	public deserialize(dto: IRS.IRSQueryRow) : RSQueryRow {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSQueryRow);
		pd.update(dto);
		return pd;
	}
}

export class RSRAG implements IRS.IRSRAG {

	public platformNumber: number;
	public assessmentState: number;
	public blueCount: number;
	public blueAppCount: number;
	public amberCount: number;
	public amberAppCount: number;
	public redCount: number;
	public redAppCount: number;
	public greenCount: number;
	public greenAppCount: number;
	public fixState: number;
	public colorClass: string;

	public update(dto: IRS.IRSRAG) : void {
		this.platformNumber = dto.platformNumber;
		this.assessmentState = dto.assessmentState;
		this.blueCount = dto.blueCount;
		this.blueAppCount = dto.blueAppCount;
		this.amberCount = dto.amberCount;
		this.amberAppCount = dto.amberAppCount;
		this.redCount = dto.redCount;
		this.redAppCount = dto.redAppCount;
		this.greenCount = dto.greenCount;
		this.greenAppCount = dto.greenAppCount;
		this.fixState = dto.fixState;
		this.colorClass = dto.colorClass;
	}

	public deserialize(dto: IRS.IRSRAG) : RSRAG {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSRAG);
		pd.update(dto);
		return pd;
	}
}

export class RSRAGElement implements IRS.IRSRAGElement {

	public assessmentStates: Array<RSRAG> = [];
	public assessmentStatesDictionary : { [platformNumber:string]: RSRAG };

	public update(dto: IRS.IRSRAGElement) : void {
		DataMerger.MergeHelper.ListMerge(dto.assessmentStates, this.assessmentStates,  (i) => { return i.platformNumber },  RSRAG);
		this.assessmentStatesDictionary = {};
		this.assessmentStates.forEach(a => {
			this.assessmentStatesDictionary[a.platformNumber] = a;
		});
	}

	public deserialize(dto: IRS.IRSRAGElement) : RSRAGElement {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSRAGElement);
		pd.update(dto);
		return pd;
	}
}

export class RSRegisterEvaluationMachine implements IRS.IRSRegisterEvaluationMachine {

	public jobId: string;

	public update(dto: IRS.IRSRegisterEvaluationMachine) : void {
		this.jobId = dto.jobId;
	}

	public deserialize(dto: IRS.IRSRegisterEvaluationMachine) : RSRegisterEvaluationMachine {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSRegisterEvaluationMachine);
		pd.update(dto);
		return pd;
	}

}

export class RSQuotaStatistic implements IRS.IRSQuotaStatistic {

	public serverId: string;
	public region: string;
	public limitVcpuXcheck: number;
	public limitVcpuOther: number;
	public inUseVcpuXcheck: number;
	public inUseVcpuOther: number;
	public percentageUsedOther: number;
	public percentageUsedXcheck: number;

	public update(dto: IRS.IRSQuotaStatistic) : void {
		this.serverId = dto.serverId;
		this.region = dto.region;
		this.limitVcpuXcheck = dto.limitVcpuXcheck;
		this.limitVcpuOther = dto.limitVcpuOther;
		this.inUseVcpuXcheck = dto.inUseVcpuXcheck;
		this.inUseVcpuOther = dto.inUseVcpuOther;
		this.percentageUsedOther = dto.percentageUsedOther;
		this.percentageUsedXcheck = dto.percentageUsedXcheck;
	}

	public deserialize(dto: IRS.IRSQuotaStatistic) : RSQuotaStatistic {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSQuotaStatistic);
		pd.update(dto);
		return pd;
	}

	public static factory(): RSQuotaStatistic {
		return new RSQuotaStatistic()
	}

}

export class RSRegisterUser implements IRS.IRSRegisterUser {

	public action: number;

	public update(dto: IRS.IRSRegisterUser) : void {
		this.action = dto.action;
	}

	public deserialize(dto: IRS.IRSRegisterUser) : RSRegisterUser {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSRegisterUser);
		pd.update(dto);
		return pd;
	}
}

export class RSReleaseEvaluationMachine implements IRS.IRSReleaseEvaluationMachine {

	public requestId: string;
	public result: number;

	public update(dto: IRS.IRSReleaseEvaluationMachine) : void {
		this.requestId = dto.requestId;
		this.result = dto.result;
	}

	public deserialize(dto: IRS.IRSReleaseEvaluationMachine) : RSReleaseEvaluationMachine {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSReleaseEvaluationMachine);
		pd.update(dto);
		return pd;
	}
}

export class RSRemediationCheck implements IRS.IRSRemediationCheck {

	public id: string;
	public name: string;
	public isEnabled: boolean;
	public patches: Array<RSPatch> = [];
	public assessmentStates: Array<RSRAG> = [];
	public assessmentStatesDictionary : { [platformNumber:string]: RSRAG };

	public update(dto: IRS.IRSRemediationCheck) : void {
		this.id = dto.id;
		this.name = dto.name;
		this.isEnabled = dto.isEnabled;
		DataMerger.MergeHelper.ListMerge(dto.patches, this.patches,  (i) => { return i.uniqId },  RSPatch);
		DataMerger.MergeHelper.ListMerge(dto.assessmentStates, this.assessmentStates,  (i) => { return i.platformNumber },  RSRAG);
		this.assessmentStatesDictionary = {};
		this.assessmentStates.forEach(a => {
			this.assessmentStatesDictionary[a.platformNumber] = a;
		});
	}

	public deserialize(dto: IRS.IRSRemediationCheck) : RSRemediationCheck {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSRemediationCheck);
		pd.update(dto);
		return pd;
	}
}

export class RSReportOption implements IRS.IRSReportOption {

	public key: string;
	public id: string;
	public display: string;
	public platformBundleNumber: number;
	public format: number;
	public flags: string;

	public update(dto: IRS.IRSReportOption) : void {
		this.key = dto.key;
		this.id = dto.id;
		this.display = dto.display;
		this.platformBundleNumber = dto.platformBundleNumber;
		this.format = dto.format;
		this.flags = dto.flags;
	}

	public deserialize(dto: IRS.IRSReportOption) : RSReportOption {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSReportOption);
		pd.update(dto);
		return pd;
	}
}

export class RSRequestEvaluationMachine implements IRS.IRSRequestEvaluationMachine {

	public requestId: string;

	public update(dto: IRS.IRSRequestEvaluationMachine) : void {
		this.requestId = dto.requestId;
	}

	public deserialize(dto: IRS.IRSRequestEvaluationMachine) : RSRequestEvaluationMachine {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSRequestEvaluationMachine);
		pd.update(dto);
		return pd;
	}
}

export class RSSaveCustomQuery implements IRS.IRSSaveCustomQuery {

	public savedId: string;
	public queries: Array<RSQuery> = [];

	public update(dto: IRS.IRSSaveCustomQuery) : void {
		this.savedId = dto.savedId;
		DataMerger.MergeHelper.ListMerge(dto.queries, this.queries,  (i) => { return i.id },  RSQuery);
	}

	public deserialize(dto: IRS.IRSSaveCustomQuery) : RSSaveCustomQuery {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSSaveCustomQuery);
		pd.update(dto);
		return pd;
	}
}

export class RSSignInUser implements IRS.IRSSignInUser {

	public authenticationToken: string;
	public userInformation: RSGetUserInformation;

	public update(dto: IRS.IRSSignInUser) : void {
		this.authenticationToken = dto.authenticationToken;
		if (this.userInformation == null && dto.userInformation != null)
			this.userInformation = new RSGetUserInformation();
		if (dto.userInformation == null)
			this.userInformation = null
		else
			this.userInformation.update(dto.userInformation);
	}

	public  deserialize(dto: IRS.IRSSignInUser) : RSSignInUser {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSSignInUser);
		pd.update(dto);
		return pd;
	}
}

export class RSTaskLogLine implements IRS.IRSTaskLogLine {

	public lineNo: number;
	public timestamp: string;
	public text: string;

	public update(dto: IRS.IRSTaskLogLine) : void {
		this.lineNo = dto.lineNo;
		this.timestamp = dto.timestamp;
		this.text = dto.text;
	}

	public deserialize(dto: IRS.IRSTaskLogLine) : RSTaskLogLine {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSTaskLogLine);
		pd.update(dto);
		return pd;
	}
}

export class RSTest implements IRS.IRSTest {

	public test3: number;
	public test4: string;

	public update(dto: IRS.IRSTest) : void {
		this.test3 = dto.test3;
		this.test4 = dto.test4;
	}

	public deserialize(dto: IRS.IRSTest) : RSTest {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSTest);
		pd.update(dto);
		return pd;
	}
}

export class RSTextItem implements IRS.IRSTextItem {

	public key: string;
	public value: string;
	public subValues: Array<string> = [];
	public furtherInfo: string;

	public update(dto: IRS.IRSTextItem) : void {
		this.key = dto.key;
		this.value = dto.value;
		this.subValues = dto.subValues;
		this.furtherInfo = dto.furtherInfo;
	}

	public deserialize(dto: IRS.IRSTextItem) : RSTextItem {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSTextItem);
		pd.update(dto);
		return pd;
	}
}

export class RSTrackableOperatingSystemConfiguration implements IRS.IRSTrackableOperatingSystemConfiguration {

	public id: string;
	public operatingSystemId: string;
	public checkpoint: string;
	public origin: string;
	public display: string;
	public usesDesktopOperatingSystem: boolean;

	public update(dto: IRS.IRSTrackableOperatingSystemConfiguration) : void {
		this.id = dto.id;
		this.operatingSystemId = dto.operatingSystemId;
		this.checkpoint = dto.checkpoint;
		this.origin = dto.origin;
		this.display = dto.display;
		this.usesDesktopOperatingSystem = dto.usesDesktopOperatingSystem;
	}

	public deserialize(dto: IRS.IRSTrackableOperatingSystemConfiguration) : RSTrackableOperatingSystemConfiguration {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSTrackableOperatingSystemConfiguration);
		pd.update(dto);
		return pd;
	}
}

export class RSUserColumnProfile implements IRS.IRSUserColumnProfile {

	public profileId: string;
	public profileName: string;
	public columnNames: Array<string> = [];
	public isCurrentProfile: boolean;

	public update(dto: IRS.IRSUserColumnProfile) : void {
		this.profileId = dto.profileId;
		this.profileName = dto.profileName;
		this.columnNames = dto.columnNames;
		this.isCurrentProfile = dto.isCurrentProfile;
	}

	public deserialize(dto: IRS.IRSUserColumnProfile) : RSUserColumnProfile {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSUserColumnProfile);
		pd.update(dto);
		return pd;
	}

}

export class RSVirtualisationTechnology implements IRS.IRSVirtualisationTechnology {

	public id: string;
	public display: string;
	public isServerOnly: boolean;

	public update(dto: IRS.IRSVirtualisationTechnology) : void {
		this.id = dto.id;
		this.display = dto.display;
		this.isServerOnly = dto.isServerOnly;
	}

	public deserialize(dto: IRS.IRSVirtualisationTechnology) : RSVirtualisationTechnology {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSVirtualisationTechnology);
		pd.update(dto);
		return pd;
	}
}

export class RSVirtualMachine implements IRS.IRSVirtualMachine {

	public id: string;
	public name: string;
	public status: string;
	public operatingSystemId: string;
	public checkpoint: string;

	public update(dto: IRS.IRSVirtualMachine) : void {
		this.id = dto.id;
		this.name = dto.name;
		this.status = dto.status;
		this.operatingSystemId = dto.operatingSystemId;
		this.checkpoint = dto.checkpoint;
	}

	public deserialize(dto: IRS.IRSVirtualMachine) : RSVirtualMachine {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSVirtualMachine);
		pd.update(dto);
		return pd;
	}
}

export class RSXCheckDComResult implements IRS.IRSXCheckDComResult {

	public name: string;
	public caption: string;
	public description: string;
	public appId: string;
	public authenticationLevel: string;
	public runAsUser: string;
	public remoteServerName: string;
	public screenshot: string;

	public update(dto: IRS.IRSXCheckDComResult) : void {
		this.name = dto.name;
		this.caption = dto.caption;
		this.description = dto.description;
		this.appId = dto.appId;
		this.authenticationLevel = dto.authenticationLevel;
		this.runAsUser = dto.runAsUser;
		this.remoteServerName = dto.remoteServerName;
		this.screenshot = dto.screenshot;
	}

	public deserialize(dto: IRS.IRSXCheckDComResult) : RSXCheckDComResult {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSXCheckDComResult);
		pd.update(dto);
		return pd;
	}
}

export class RSXCheckComplusResult implements IRS.IRSXCheckComplusResult {

	public name: string;
	public description: string;
	public eventsEnabled: boolean;
	public id: string;
	public identity: string;
	public serviceName: string;
	public screenshot: string;

	public update(dto: IRS.IRSXCheckComplusResult) : void {
		this.name = dto.name;
		this.description = dto.description;
		this.eventsEnabled = dto.eventsEnabled;
		this.id = dto.id;
		this.identity = dto.identity;
		this.serviceName = dto.serviceName;
		this.screenshot = dto.screenshot;
	}

	public deserialize(dto: IRS.IRSXCheckComplusResult) : RSXCheckComplusResult {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSXCheckComplusResult);
		pd.update(dto);
		return pd;
	}
}

export class RSXCheckDriverResult implements IRS.IRSXCheckDriverResult {

	public name: string;
	public configFile: string;
	public dataFile: string;
	public driverPath: string;
	public screenshot: string;

	public update(dto: IRS.IRSXCheckDriverResult) : void {
		this.name = dto.name;
		this.configFile = dto.configFile;
		this.dataFile = dto.dataFile;
		this.driverPath = dto.driverPath;
		this.screenshot = dto.screenshot;
	}

	public deserialize(dto: IRS.IRSXCheckDriverResult) : RSXCheckDriverResult {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSXCheckDriverResult);
		pd.update(dto);
		return pd;
	}
}

export class RSXCheckEventLogResult implements IRS.IRSXCheckEventLogResult {

	public id: number;
	public category: string;
	public level: string;
	public message: string;

	public update(dto: IRS.IRSXCheckEventLogResult) : void {
		this.id = dto.id;
		this.category = dto.category;
		this.level = dto.level;
		this.message = dto.message;
	}

	public deserialize(dto: IRS.IRSXCheckEventLogResult) : RSXCheckEventLogResult {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSXCheckEventLogResult);
		pd.update(dto);
		return pd;
	}
}

export class RSXCheckPrinterResult implements IRS.IRSXCheckPrinterResult {

	public printerName: string;
	public driverName: string;
	public portName: string;
	public screenshot: string;

	public update(dto: IRS.IRSXCheckPrinterResult) : void {
		this.printerName = dto.printerName;
		this.driverName = dto.driverName;
		this.portName = dto.portName;
		this.screenshot = dto.screenshot;
	}

	public deserialize(dto: IRS.IRSXCheckPrinterResult) : RSXCheckPrinterResult {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSXCheckPrinterResult);
		pd.update(dto);
		return pd;
	}

}


export class RSXCheckFirewallRuleResult implements IRS.IRSXCheckFirewallRuleResult {

	public name: string;
	public displayName: string;
	public description: string;
	public enabled: number;
	public direction: number;
	public action: number;
	public program: string;
	public protocol: string;
	public localPort: number;
	public profile: string;
	public remoteAddress: string;

	public update(dto: IRS.IRSXCheckFirewallRuleResult) : void {
		this.name = dto.name;
		this.displayName = dto.displayName;
		this.description = dto.description;
		this.enabled = dto.enabled;
		this.direction = dto.direction;
		this.action = dto.action;
		this.program = dto.program;
		this.protocol = dto.protocol;
		this.localPort = dto.localPort;
		this.profile = dto.profile;
		this.remoteAddress = dto.remoteAddress;
	}

	public deserialize(dto: IRS.IRSXCheckFirewallRuleResult) : RSXCheckFirewallRuleResult {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSXCheckFirewallRuleResult);
		pd.update(dto);
		return pd;
	}

}

export class RSXCheckScheduledTaskResult implements IRS.IRSXCheckScheduledTaskResult {

	name: string;
	uri: string;
	actions: Array<RSXCheckScheduledTaskAction>
	author:string;
	comment :string;
	days: string;
	deleteTaskIfNotRescheduled: string;
	endDate: string;
	hostName: string;
	idleTime: string;
	lastResult: string;
	lastRunTime: string;
	logonMode: string;
	months: string;
	nextRunTime: string;
	powerManagement: string;
	repeatEvery: string;
	repeatStopIfStillRunning: string;
	repeatUntilDuration: string;
	repeatUntilTime: string;
	runAsUser :string;
	schedule: string;
	scheduleType: string;
	scheduledTaskState : string;
	startDate: string;
	startTime :string;
	status: string;
	stopTaskIfRunsXHoursandXMins: string;
	screenshot: string;

	public update(dto: IRS.IRSXCheckScheduledTaskResult) {
		this.name = dto.name;
		this.uri = dto.uri;
	//	actions: Array<RSXCheckScheduledTaskAction>
		this.author = dto.author;
		this.comment = dto.comment;
		this.days = dto.days;
		this.deleteTaskIfNotRescheduled = dto.deleteTaskIfNotRescheduled;
		this.endDate =dto.endDate;
		this.hostName = dto.hostName;
		this.idleTime = dto.idleTime;
		this.lastResult =dto.lastResult;
		this.lastRunTime = dto.lastRunTime;
		this.logonMode = dto.logonMode;
		this.months = dto.months;
		this.nextRunTime = dto.nextRunTime;
		this.powerManagement = dto.powerManagement;
		this.repeatEvery = dto.repeatEvery;
		this.repeatStopIfStillRunning = dto.repeatStopIfStillRunning;
		this.repeatUntilDuration = dto.repeatUntilDuration;
		this.repeatUntilTime = dto.repeatUntilTime;
		this.runAsUser = dto.runAsUser;
		this.schedule = dto.schedule;
		this.scheduleType = dto.scheduleType;
		this.scheduledTaskState =dto.scheduledTaskState;
		this.startDate = dto.startDate;
		this.startTime = dto.startTime;
		this.status = dto.status;
		this.stopTaskIfRunsXHoursandXMins = dto.stopTaskIfRunsXHoursandXMins;
		this.screenshot= dto.screenshot;
	}

	public deserialize(dto: IRS.IRSXCheckScheduledTaskResult) : RSXCheckScheduledTaskResult {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSXCheckScheduledTaskResult);
		pd.update(dto);
		return pd;
	}
}

export class RSXCheckScheduledTaskAction implements IRS.IRSXCheckScheduledTaskAction {
   
   execute: string;
   arguments: string;
   workingDirectory: string;

   public update(dto: IRS.IRSXCheckScheduledTaskAction) {
	this.execute = dto.execute;
	this.arguments = dto.arguments;
	this.workingDirectory =dto.workingDirectory;
   }

   public deserialize(dto: IRS.IRSXCheckScheduledTaskAction) : RSXCheckScheduledTaskAction {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSXCheckScheduledTaskAction);
		pd.update(dto);
		return pd;
	}
}

export class RSXCheckCompatFlagResult implements IRS.IRSXCheckCompatFlagResult {

	public type: string;
	public name: string;
	public value: RSXCheckCompatFlagValue;

	public update(dto: IRS.IRSXCheckCompatFlagResult) {
		this.type = dto.type;
		this.name = dto.name;
		if (!this.value && dto.value)
			this.value = new RSXCheckCompatFlagValue();
		if (!dto.value)
			this.value=null;
		else
			this.value.update(dto.value);
	}

	public deserialize(dto: IRS.IRSXCheckCompatFlagResult) : RSXCheckCompatFlagResult {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSXCheckCompatFlagResult);
		pd.update(dto);
		return pd;
	}
}

export class RSXCheckCompatFlagValue implements IRS.IRSXCheckCompatFlagValue {

	public compatibilityMode: string;
	public displaySettings: string[];
	public runAsAdmin: boolean;

	public update(dto: IRS.IRSXCheckCompatFlagValue) {
		this.compatibilityMode = dto.compatibilityMode;
		this.displaySettings = dto.displaySettings;
		this.runAsAdmin=dto.runAsAdmin;
	}

	public deserialize(dto: IRS.IRSXCheckCompatFlagValue) : RSXCheckCompatFlagValue {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSXCheckCompatFlagValue);
		pd.update(dto);
		return pd;
	}
}

export class RSXCheckEnvVarResult implements IRS.IRSXCheckEnvVarResult {

	public type: string;
	public name: string;
	public value: string;
	public screenshot:string;

	public update(dto: IRS.IRSXCheckEnvVarResult) : void {
		this.name = dto.name;
		this.type = dto.type;
		this.value = dto.value;
		this.screenshot = dto.screenshot;
	}

	public deserialize(dto: IRS.IRSXCheckEnvVarResult) : RSXCheckEnvVarResult {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSXCheckEnvVarResult);
		pd.update(dto);
		return pd;
	}
}

export class RSXCheckPathResult implements IRS.IRSXCheckPathResult {

	public type: string;
	public path: string;
	public screenshot:string;

	public update(dto: IRS.IRSXCheckPathResult) : void {
		this.type = dto.type;
		this.path = dto.type;
		this.screenshot = dto.screenshot;
	}

	public deserialize(dto: IRS.IRSXCheckPathResult) : RSXCheckPathResult {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSXCheckPathResult);
		pd.update(dto);
		return pd;
	}

}

export class RSXCheckResult implements IRS.IRSXCheckResult {

	public jobId: string;
	public id: string;
	public operatingSystemId: string;
	public checkpoint: string;
	public shortcutCount: number;
	public shortcutItems: Array<RSXCheckShortcutResult> = [];
	public serviceCount: number;
	public serviceItems: Array<RSXCheckServiceResult> = [];
	public defenderExclusionCount: number;
	public defenderExclusionItems: Array<RSXCheckDefenderExclusionResult> = [];
	public complusCount: number;
	public complusItems: Array<RSXCheckComplusResult> = [];
	public dcomCount: number;
	public dcomItems: Array<RSXCheckDComResult> = [];
	public printerCount: number;
	public printerItems: Array<RSXCheckPrinterResult> = [];
	public driverCount: number;
	public driverItems: Array<RSXCheckDriverResult> = [];
	public firewallRulesCount: number;
	public firewallItems: Array<RSXCheckFirewallRuleResult> = [];
	public envVarCount:number;
	public envVarItems: Array<RSXCheckEnvVarResult> = [];
	public pathCount:number;
	public pathItems:Array<RSXCheckPathResult>=[];
	public compatFlagCount:number;
	public compatFlagItems: Array<RSXCheckCompatFlagResult>=[];
	public scheduledTaskCount: number;
	public scheduledTaskItems: Array<RSXCheckScheduledTaskResult>=[];
	public status: number;
	public errorCode: number;
	public errorMessage: string;
	public errorDescription: string;
	public failedActions: Array<RSNameValue> =[];
	public eventLogItems: Array<RSXCheckEventLogResult> = [];
	public diskUsage: string;
	public hasFailureScreenshot:boolean;
	public retainedFiles: Array<RSRetainedFile>=[];

	public update(dto: IRS.IRSXCheckResult) : void {
		this.jobId = dto.jobId;
		this.id = dto.id;
		this.operatingSystemId = dto.operatingSystemId;
		this.checkpoint = dto.checkpoint;
		this.shortcutCount = dto.shortcutCount;
		DataMerger.MergeHelper.ListMerge(dto.shortcutItems, this.shortcutItems,  (i) => { return i.keyHash },  RSXCheckShortcutResult);
		this.serviceCount = dto.serviceCount;
		DataMerger.MergeHelper.ListMerge(dto.serviceItems, this.serviceItems,  (i) => { return i.id },  RSXCheckServiceResult);
		this.complusCount = dto.complusCount;
		DataMerger.MergeHelper.ListMerge(dto.complusItems, this.complusItems,  (i) => { return i.name },  RSXCheckComplusResult);
		this.dcomCount = dto.dcomCount;
		DataMerger.MergeHelper.ListMerge(dto.dcomItems, this.dcomItems,  (i) => { return i.name },  RSXCheckDComResult);
		this.printerCount = dto.printerCount;
		DataMerger.MergeHelper.ListMerge(dto.printerItems, this.printerItems,  (i) => { return i.printerName },  RSXCheckPrinterResult);
		this.driverCount = dto.driverCount;
		DataMerger.MergeHelper.ListMerge(dto.driverItems, this.driverItems,  (i) => { return i.name },  RSXCheckDriverResult);
		this.firewallRulesCount = dto.firewallRulesCount;
		DataMerger.MergeHelper.ListMerge(dto.firewallItems, this.firewallItems, (i)=> {return i.name }, RSXCheckFirewallRuleResult);
		this.envVarCount = dto.envVarCount;
		DataMerger.MergeHelper.ListMerge(dto.envVarItems, this.envVarItems, (i)=> {return i.name }, RSXCheckEnvVarResult);
		this.pathCount = dto.pathCount;
		DataMerger.MergeHelper.ListMerge(dto.pathItems, this.pathItems, (i)=> {return i.type }, RSXCheckPathResult);
		this.compatFlagCount = dto.compatFlagCount;
		DataMerger.MergeHelper.ListMerge(dto.compatFlagItems, this.compatFlagItems, (i)=> {return i.name}, RSXCheckCompatFlagResult);
		this.scheduledTaskCount = dto.scheduledTaskCount;
		DataMerger.MergeHelper.ListMerge(dto.scheduledTaskItems, this.scheduledTaskItems, (i)=> {return i.name}, RSXCheckScheduledTaskResult);
		this.status = dto.status;
		this.errorCode = dto.errorCode;
		this.errorMessage = dto.errorMessage;
		this.errorDescription = dto.errorDescription;
		DataMerger.MergeHelper.ListMerge(dto.failedActions, this.failedActions, (i)=>{return i.name}, RSNameValue);
		DataMerger.MergeHelper.ListMerge(dto.eventLogItems, this.eventLogItems,  (i) => { return i.id },  RSXCheckEventLogResult);
		this.diskUsage = dto.diskUsage;
		this.hasFailureScreenshot = dto.hasFailureScreenshot;
		DataMerger.MergeHelper.ListMerge(dto.retainedFiles, this.retainedFiles, (i)=> { return i.name}, RSRetainedFile);
	}

	public deserialize(dto: IRS.IRSXCheckResult) : RSXCheckResult {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSXCheckResult);
		pd.update(dto);
		return pd;
	}
}

export class RSXCheckServiceResult implements IRS.IRSXCheckServiceResult {

	public id: string;
	public displayName: string;
	public serviceType: number;
	public serviceTypeDescriptions: Array<string> = [];
	public startType: number;
	public startTypeDescription: string;
	public screenshot: string;

	public update(dto: IRS.IRSXCheckServiceResult) : void {
		this.id = dto.id;
		this.displayName = dto.displayName;
		this.serviceType = dto.serviceType;
		this.serviceTypeDescriptions = dto.serviceTypeDescriptions;
		this.startType = dto.startType;
		this.startTypeDescription = dto.startTypeDescription;
		this.screenshot = dto.screenshot;
	}

	public deserialize(dto: IRS.IRSXCheckServiceResult) : RSXCheckServiceResult {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSXCheckServiceResult)
		pd.update(dto);
		return pd;
	}
}

export class RSXCheckDefenderExclusionResult implements IRS.IRSXCheckDefenderExclusionResult {

	public id:string;
	public exclusion: string;
	public type: string;

	public update(dto: IRS.IRSXCheckDefenderExclusionResult) : void {
		this.id = dto.id;
		this.exclusion = dto.exclusion;
		this.type = dto.type;
	}

	public deserialize(dto: IRS.IRSXCheckDefenderExclusionResult) : RSXCheckDefenderExclusionResult {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSXCheckDefenderExclusionResult);
		pd.update(dto);
		return pd;
	}

}

export class RSXCheckShortcutResult implements IRS.IRSXCheckShortcutResult {

	public keyHash: number;
	public productCode: string;
	public shortcutFilename: string;
	public screenshot: string;
	public modifiedSysTray: boolean;
	public modifiedSysTrayScreenshot: string;

	public update(dto: IRS.IRSXCheckShortcutResult) : void {
		this.keyHash = dto.keyHash;
		this.productCode = dto.productCode;
		this.shortcutFilename = dto.shortcutFilename;
		this.screenshot = dto.screenshot;
		this.modifiedSysTray = dto.modifiedSysTray;
		this.modifiedSysTrayScreenshot = dto.modifiedSysTrayScreenshot;
	}

	public deserialize(dto: IRS.IRSXCheckShortcutResult) : RSXCheckShortcutResult {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSXCheckShortcutResult);
		pd.update(dto);
		return pd;
	}
}

export class RSXCheckMetricOperatingSystem implements IRS.IRSXCheckMetricOperatingSystem {

	public id: string;
	public operatingSystemId: string;
	public checkpoint: string;
	public display: string;
	public successCount: number;
	public failCount: number;
	public inProgressCount: number;
	public remainingCount: number;

	public update(dto: IRS.IRSXCheckMetricOperatingSystem) : void {
		this.id = dto.id;
		this.operatingSystemId = dto.operatingSystemId;
		this.checkpoint = dto.checkpoint;
		this.display = dto.display;
		this.successCount = dto.successCount;
		this.failCount = dto.failCount;
		this.inProgressCount = dto.inProgressCount;
		this.remainingCount = dto.remainingCount;
	}

	public deserialize(dto: IRS.IRSXCheckMetricOperatingSystem) : RSXCheckMetricOperatingSystem {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSXCheckMetricOperatingSystem);
		pd.update(dto);
		return pd;
	}
}

export class RSMoveApplicationInstallDependencyOrder implements IRS.IRSMoveApplicationInstallDependencyOrder {

	public switchedIndex: number;

	public update(dto: IRS.IRSMoveApplicationInstallDependencyOrder) : void {
		this.switchedIndex = dto.switchedIndex;
	}

	public deserialize(dto: IRS.IRSMoveApplicationInstallDependencyOrder) : RSMoveApplicationInstallDependencyOrder {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSMoveApplicationInstallDependencyOrder);
		pd.update(dto);
		return pd;
	}
}

export class RSApplicationInstallDependency implements IRS.IRSApplicationInstallDependency {

	public id: string;
	public relationshipType: number;
	public relationshipSubType: number;
	public dependentApplicationId: string;
	public dependentApplicationDisplayName: string;
	public relationshipTypeName: string;
	public relationshipSubTypeName: string;
	public locatorExpression: string;
	public locatorExpressionDisplayName: string;
	public index: number;

	public update(dto: IRS.IRSApplicationInstallDependency) : void {
		this.id = dto.id;
		this.relationshipType = dto.relationshipType;
		this.relationshipSubType = dto.relationshipSubType;
		this.dependentApplicationId = dto.dependentApplicationId;
		this.dependentApplicationDisplayName = dto.dependentApplicationDisplayName;
		this.relationshipTypeName = dto.relationshipTypeName;
		this.relationshipSubTypeName = dto.relationshipSubTypeName;
		this.locatorExpression = dto.locatorExpression;
		this.locatorExpressionDisplayName = dto.locatorExpressionDisplayName;
		this.index = dto.index;
	}

	public deserialize(dto: IRS.IRSApplicationInstallDependency) : RSApplicationInstallDependency {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSApplicationInstallDependency);
		pd.update(dto);
		return pd;
	}
}

export class RSRegisterApplicationInstallDependency implements IRS.IRSRegisterApplicationInstallDependency {

	public createdItem: RSApplicationInstallDependency;

	public update(dto: IRS.IRSRegisterApplicationInstallDependency) : void {
		if (this.createdItem == null && dto.createdItem != null)
			this.createdItem = new RSApplicationInstallDependency();
		if (dto.createdItem == null)
			this.createdItem = null
		else
			this.createdItem.update(dto.createdItem);
	}

	public deserialize(dto: IRS.IRSRegisterApplicationInstallDependency) : RSRegisterApplicationInstallDependency {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSRegisterApplicationInstallDependency);
		pd.update(dto);
		return pd;
	}
}

export class RSAdminGetActivity 
		extends RSBase<RSAdminGetActivity,IRS.IRSAdminGetActivity> implements IRS.IRSAdminGetActivity {

	constructor(){super(RSAdminGetActivity)}

	public items: Array<RSAdminActivityItem> = [];

	public update(dto: IRS.IRSAdminGetActivity) : void {
		DataMerger.MergeHelper.ListMerge(dto.items, this.items,  (i) => { return i.id },  RSAdminActivityItem);
	}

	public deserialize(dto: IRS.IRSAdminGetActivity) : RSAdminGetActivity {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSAdminGetActivity);
		pd.update(dto);
		return pd;
	}
}

export class RSAdminActivityItem 
	extends RSBase<RSAdminActivityItem,IRS.IRSAdminActivityItem> implements IRS.IRSAdminActivityItem {

	constructor() {	super(RSAdminActivityItem)	}

	public id: string;
	public type: string;
	public created: string;
	public succeeded: boolean;
	public name : string;
	public message: string;
	public username: string;
	public exceptionDetails: string;

	public update(dto: IRS.IRSAdminActivityItem) : void { super.copy(dto); }
}

export class RSAddApplication implements IRS.IRSAddApplication {

	public application: RSApplication;

	public update(dto: IRS.IRSAddApplication) : void {
		if (this.application == null && dto.application != null)
			this.application = new RSApplication();
		if (dto.application == null)
			this.application = null
		else
			this.application.update(dto.application);
	}

	public deserialize(dto: IRS.IRSAddApplication) : RSAddApplication {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSAddApplication);
		pd.update(dto);
		return pd;
	}
}

export class RSGetApplicationQaChecksheet implements IRS.IRSGetApplicationQaChecksheet {

	public sections: Array<RSQaChecksheetSection> = [];

	public update(dto: IRS.IRSGetApplicationQaChecksheet) : void {
		DataMerger.MergeHelper.ListMerge(dto.sections, this.sections,  (i) => { return i.id },  RSQaChecksheetSection);
	}

	public deserialize(dto: IRS.IRSGetApplicationQaChecksheet) : RSGetApplicationQaChecksheet {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSGetApplicationQaChecksheet);
		pd.update(dto);
		return pd;
	}

}

export class RSQaChecksheetSection 	implements IRS.IRSQaChecksheetSection {

	public id: string;
	public title: string;
	public columnHeadings: Array<string>=[];
	public columnWidths: Array<string>=[];
	public columnHeadingsHidden: boolean;
	public rows: Array<RSQaChecksheetRow> = [];

	public update(dto: IRS.IRSQaChecksheetSection) : void {
		this.id = dto.id;
		this.title = dto.title;
		this.columnHeadings = dto.columnHeadings;
		this.columnWidths = dto.columnWidths;
		this.columnHeadingsHidden = dto.columnHeadingsHidden;
		DataMerger.MergeHelper.ListMerge(dto.rows, this.rows,  (i) => { return i.id },  RSQaChecksheetRow);
	}

	public deserialize(dto: IRS.IRSQaChecksheetSection) : RSQaChecksheetSection {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSQaChecksheetSection);
		pd.update(dto);
		return pd;
	}

}

export class RSQaChecksheetRow implements IRS.IRSQaChecksheetRow {

	public id: string;
	public columns: Array<RSQaChecksheetColumn> = [];

	public update(dto: IRS.IRSQaChecksheetRow) : void {
		this.id = dto.id;
		DataMerger.MergeHelper.ListMerge(dto.columns, this.columns,  (i) => { return i.id },  RSQaChecksheetColumn);
	}

	public deserialize(dto: IRS.IRSQaChecksheetRow) : RSQaChecksheetRow {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSQaChecksheetRow);
		pd.update(dto);
		return pd;
	}
}

export class RSQaChecksheetColumn implements IRS.IRSQaChecksheetColumn {

	public id: number;
	public sectionId: string;
	public rowIndex: number;
	public value: string;
	public valuesAsTable: Array<RSQaChecksheetRow>=[];
	public isEditable: boolean;
	public editType: string;
	public flags: string;
	public listColumns:string;

	public update(dto: IRS.IRSQaChecksheetColumn) : void {
		this.id = dto.id;
		this.sectionId = dto.sectionId;
		this.rowIndex = dto.rowIndex;
		this.value = dto.value;
		this.isEditable = dto.isEditable;
		this.editType = dto.editType;
		this.flags= dto.flags;
		this.listColumns = dto.listColumns;
		if (dto.valuesAsTable && dto.valuesAsTable.length > 0)
			DataMerger.MergeHelper.ListMerge(dto.valuesAsTable, this.valuesAsTable, (i)=> { return i.id}, RSQaChecksheetRow);
	}

	public deserialize(dto: IRS.IRSQaChecksheetColumn) : RSQaChecksheetColumn {
		if (!dto)
			return null;
		let pd = RSClsFactory.factory(RSQaChecksheetColumn);
		pd.update(dto);
		return pd;
	}
}

