import { Component, OnInit } from '@angular/core';
import { INPUTEDITARGS } from '../../model/INPUTEDITARGS';
import { Constants } from '../../api/Constants';
import { CurrentProjectService } from '../../svc/currentProjectService';
import { CertUploadService } from 'src/app/svc/certUploadService';
import * as CRS from '../../api/CRS';

@Component({
  selector: Constants.ROUTE_PROJECTSETTINGSCONVERT,
  templateUrl: './project-settings-convert.html',
  styleUrls: ['../../shared/css/nav-bar.scss', '../../shared/css/view.scss', './project-settings.scss']
})
export class ProjectSettingsConvert implements OnInit {

  constructor(
    public currentProject: CurrentProjectService,
    public certUploadService: CertUploadService) { }

  ngOnInit(): void {
  }

  public get project() : CRS.RSGetProject {
    return this.currentProject.dataItem;
  }

  public get projectIsReady(): boolean {
    return this.currentProject.dataContext.isReady;
  }

  public canEdit(): boolean {
    return this.currentProject.canEdit();
  }

  public dragFileOver(state): void {
    var ele = document.getElementsByClassName("sw-cert-upload-container");
    if (state)
      $(ele).addClass("sw-cert-upload-container-over")
    else
      $(ele).removeClass("sw-cert-upload-container-over");
  }

  public showReportingConfigImageInReadmeChanged() {
    this.currentProject.updateRuleGeneric(this.project.showReportingConfigImageInReadme);
  }

  public appvFullWriteModeChanged() {
    this.currentProject.updateRuleGeneric(this.project.appvFullWriteMode);
  }

  public xcheckRequiresSilentArgsChanged() {
    this.currentProject.updateRuleGeneric(this.project.xcheckRequiresSilentArgs);
  }

  public disable8Dot3FilenameCreationChanged() {
    this.currentProject.updateRuleGeneric(this.project.disable8Dot3FilenameCreation);
  }

  public disableAutomaticDetectedDependencyInstallChanged() {
    var vv = (this.project.disableAutomaticDetectedDependencyInstall) ? "true" : null;
    this.currentProject.updateDisableAutomaticDetectedDependencyInstall(vv);
  }

  public msixPublisherNameChanged(args:INPUTEDITARGS) {
    this.project.msixPublisherName.value = args.value;
    this.currentProject.updateRuleGeneric(this.project.msixPublisherName);
  }
 
  public msixPublisherDisplayNameChanged(args:INPUTEDITARGS) {
    this.project.msixPublisherDisplayName.value =args.value;
    this.currentProject.updateRuleGeneric(this.project.msixPublisherDisplayName);
  }

  public xcheckImageToolsKeyChanged(args:INPUTEDITARGS) {
    this.currentProject.updateXCheckImageToolsKey(args.value);
  }

  public disableXCheckChanged() {
    this.currentProject.updateRuleGeneric(this.project.disableXCheck);
  }

  public snapshotDuringDiscoveryChanged() {
    this.currentProject.updateRuleGeneric(this.project.snapshotDuringDiscovery)
  }

  public sendXCheckVmCreationEmailChanged() {
    this.currentProject.updateRuleGeneric(this.project.sendXCheckVmCreationEmail);
  }

  public updatePasswordsOnVmsChanged() {
    this.currentProject.updateRuleGeneric(this.project.updatePasswordsOnVms);
  }

  public preferInstallCommandFileChanged() {
    this.currentProject.updateRuleGeneric(this.project.preferInstallCommandFile);
  }

  // public videoRecordTestingJobsChanged() {
  //   var vv = (this.currentProject.dataContext.item().videoRecordTestingJobs) ? "true" : null;
  //   this.currentProject.updateVideoRecordTestingJobs(vv);
  // }

  public installCommandFilesChanged(args: INPUTEDITARGS) {
    this.currentProject.updateInstallCommandFiles(args.value);
  }

  public appIdCustomisationsChanged(args: INPUTEDITARGS) {
    this.currentProject.updateAppIdCustomisations(args.value);
  }

  public setApplyDelayedFixesChange() {
    this.currentProject.updateRuleGeneric(this.project.applyDelayedFixes);
  }

  public setUseVendorForPublisherDisplayNameChange() {
    this.currentProject.updateRuleGeneric(this.project.useVendorForPublisherDisplayName);
  }

  public msixManifestModificationsChanged(args:INPUTEDITARGS) {
    this.currentProject.updateMsixManifestModifications(args.value);
  }

  public setMsixPackagingToolVersionChange() {
    this.currentProject.updateRuleGeneric(this.project.msixPackagingToolVersion);
  }

  public signingTimestampUrlChanged(args: INPUTEDITARGS) {
    this.project.signingTimestampUrl.value = args.value;
    this.currentProject.updateRuleGeneric(this.project.signingTimestampUrl);
  }

  public msixCertificatePasswordChanged(args: INPUTEDITARGS) {
    this.currentProject.updateMsixCertificatePassword(args.value);
  }

  public testUserTypeChanged() {
    this.currentProject.updateRuleGeneric(this.project.testUserType);
  }

}
