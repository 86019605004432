import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import {AdminDashboard} from "./admin/admin-dashboard/admin-dashboard";
import { Dashboard } from './project/dashboard/dashboard';
import { ProjectCreate } from './project/project-create/project-create';
import { ProjectDetails } from './project/project-details/project-details';
import { ProjectApplications } from './project/project-applications/project-applications';
import { ProjectDrives } from './project/project-drives/project-drives';
import { ProjectReports } from './project/project-reports/project-reports';
import { ProjectSettings } from './project/project-settings/project-settings';
import { ProjectQueries } from './project/project-queries/project-queries';
import { ProjectQueriesStandards } from './project/project-queries/project-queries-standards';
import { ProjectQueriesChecksheet } from './project/project-queries/project-queries-checksheet';
import { ProjectQueriesCustom } from './project/project-queries/project-queries-custom';
import { ProjectPatches } from './project/project-patches/project-patches';
import { ProjectXCheck } from './project/project-xcheck/project-xcheck';
import { ProjectActions } from './project/project-actions/project-actions';
import { ProjectActionDetails } from './project/project-actions/project-action-details';
import { ProjectSettingsGeneral } from './project/project-settings/project-settings-general';
import { ProjectSettingsVHDs } from './project/project-settings/project-settings-vhds';
import { ProjectSettingsPublishing } from './project/project-settings/project-settings-publishing';
import { ProjectSettingsConvert } from './project/project-settings/project-settings-convert';
import { ProjectSettingsXCheck } from './project/project-settings/project-settings-xcheck';
import { ProjectSettingsReporting } from './project/project-settings/project-settings-reporting';
import { ProjectSettingsShared } from './project/project-settings/project-settings-shared';
import { ProjectSettingsWorkflow } from './project/project-settings/project-settings-workflow';
import { ProjectSettingsScripts } from './project/project-settings/project-settings-scripts';
import { ProjectReportsChecks } from './project/project-reports-checks/project-reports-checks';
import { ProjectApplicationActionsDetail } from './project/project-applications/project-application-actions-detail';
import { ProjectApplicationLanding } from './project/project-applications/project-application-landing';
import { ProjectApplicationBrowse } from './project/project-applications/project-application-browse';
import { ProjectApplicationBrowseTable } from './project/project-applications/project-application-browse-table';
import { ProjectReportsChecksDetail } from './project/project-reports-checks-detail/project-reports-checks-detail';
import { ErrorView } from '../app/miscViews/error-view';
import { ErrorNotFound } from '../app/miscViews/error-not-found';
import { ErrorServerLost } from '../app/miscViews/error-server-lost';
import { Constants } from './api/Constants';
import { AdminCheckLibrary } from './admin/admin-check-library/admin-check-library';
import { AdminCheckDetail } from './admin/admin-check-detail/admin-check-detail';
import { AdminActivity } from './admin/admin-activity/admin-activity';
import { AdminPatchGroups } from './admin/admin-patch-groups/admin-patch-groups';
import { AdminPatchGroupItem } from './admin/admin-patch-group-item/admin-patch-group-item';
import { AdminPatchKB } from './admin/admin-patch-kb/admin-patch-kb';
import { AdminPatchCats} from './admin/admin-patch-cats/admin-patch-cats';
import { AdminPatchCatFiles } from './admin/admin-patch-cat-files/admin-patch-cat-files';
import { AdminPatchCveCats } from './admin/admin-patch-cve-cats/admin-patch-cve-cats';
import { AdminProjects } from './admin/admin-projects/admin-projects';
import { AdminWindowsUpdates } from './admin/admin-windows-updates/admin-windows-updates';
import { AdminAssessmentGroups } from './admin/admin-assessment-groups/admin-assessment-groups';
import { AdminAssessmentGroupsDetail } from './admin/admin-assessment-groups-detail/admin-assessment-groups-detail';
import { AdminAssessmentGroupCheck } from './admin/admin-assessment-group-check/admin-assessment-group-check';
import { AdminTaskMgr } from './admin/admin-task-mgr/admin-task-mgr';
import { AdminUsers } from './admin/admin-users/admin-users';
import { AdminUserDetail } from './admin/admin-users/admin-user-detail';


/**
 * MSAL Angular can protect routes in your application
 * using MsalGuard. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#secure-the-routes-in-your-application
 */
const routes: Routes = [
  { 
    path: Constants.ROUTE_ADMINDASHBOARD, component: AdminDashboard, canActivate: [ MsalGuard ],
    children: [
      {
        path: Constants.ROUTE_ADMINCHECKLIBRARY, component: AdminCheckLibrary, canActivate: [MsalGuard], 
        children: [
          { path: Constants.ROUTE_ADMINCHECKDETAIL, component: AdminCheckDetail, canActivate: [MsalGuard] }
        ]
      },
      {
        path: Constants.ROUTE_ADMIN_ACTIVITY, component: AdminActivity, canActivate: [MsalGuard]
      },
      {
        path: Constants.ROUTE_ADMINPATCHGROUPS, component: AdminPatchGroups, canActivate: [MsalGuard],
        children: [
          { 
            path: Constants.ROUTE_PATCHGROUPMONTH, component: AdminPatchGroupItem, canActivate: [MsalGuard],
            children: [
              { path: Constants.ROUTE_PATCHGROUPITEMDETAIL, component: AdminPatchKB, canActivate: [MsalGuard]}
            ]
          }
        ]
      },
      {
        path: Constants.ROUTE_ADMINPATCHCATS, component: AdminPatchCats, canActivate: [MsalGuard],
        children: [
          { path: Constants.ROUTE_PATCHCATEGORYFILES, component: AdminPatchCatFiles, canActivate: [MsalGuard] }
        ]
      },
      {
        path: Constants.ROUTE_ADMINPATCHCVECATS, component: AdminPatchCveCats, canActivate: [MsalGuard] 
      },
      {
        path: Constants.ROUTE_ADMINPROJECTS, component: AdminProjects, canActivate: [MsalGuard]
      },
      {
        path: Constants.ROUTE_ADMINASSESSMENTGROUPS, component: AdminAssessmentGroups, canActivate: [MsalGuard],
        children: [
          { 
            path: Constants.ROUTE_ADMIN_ASSESSMENT_GROUPS_DETAIL, component: AdminAssessmentGroupsDetail, canActivate: [MsalGuard],
            children: [
              { path: Constants.ROUTE_ADMIN_ASSESSMENT_GROUPS_CHECK, component: AdminAssessmentGroupCheck, canActivate: [MsalGuard] }
            ]
          }
        ]
      },
      {
        path: Constants.ROUTE_ADMINWINDOWSUPDATES, component: AdminWindowsUpdates, canActivate: [MsalGuard]
      },
      {
        path: Constants.ROUTE_ADMINTASKMGR, component: AdminTaskMgr, canActivate: [MsalGuard]
      },
      {
        path: Constants.ROUTE_ADMINUSERS, component: AdminUsers, canActivate: [MsalGuard],
        children: [
          { 
            path: Constants.ROUTE_ADMINUSERDETAIL, component: AdminUserDetail, canActivate: [MsalGuard]
          }
        ]
      }
    ]
  },
  { path: Constants.ROUTE_PROJECTDETAILS, component: ProjectDetails, canActivate: [ MsalGuard ],
      children: [
        { path: Constants.ROUTE_PROJECTAPPLICATIONS, component: ProjectApplications, canActivate: [ MsalGuard ],
            children: [
              { path: Constants.ROUTE_PROJECTAPPLICATIONLANDING, component: ProjectApplicationLanding, canActivate: [MsalGuard],
                children: [
                    { path: Constants.ROUTE_PROJECTAPPLICATIONACTIONSDETAIL, component: ProjectApplicationActionsDetail, canActivate: [MsalGuard]},
                    { path: Constants.ROUTE_PROJECTAPPLICATIONBROWSE, component: ProjectApplicationBrowse, canActivate: [MsalGuard],
                    children: [
                      {path: Constants.ROUTE_PROJECTAPPLICATIOBROWSETABLE, component: ProjectApplicationBrowseTable, canActivate: [MsalGuard]}
                    ]}
                 ]
              }
            ]
        },
        { path: Constants.ROUTE_PROJECTDRIVES, component: ProjectDrives, canActivate: [MsalGuard]},
        { path: Constants.ROUTE_PROJECTREPORTS, component: ProjectReports, canActivate: [MsalGuard],
            children : [
              { path: Constants.ROUTE_PROJECTREPORTAG, component: ProjectReportsChecks, canActivate:[MsalGuard],
                children: [
                  { path: Constants.ROUTE_PROJECTREPORTCHECKDETAIL, component: ProjectReportsChecksDetail, canActivate:[MsalGuard]}
                ]}
            ]
        },
        { path: Constants.ROUTE_PROJECTQUERIES, component: ProjectQueries, canActivate: [MsalGuard],
            children : [
              { path: Constants.ROUTE_PROJECTQUERIESSTANDARDS, component: ProjectQueriesStandards, canActivate: [MsalGuard]},
              { path: Constants.ROUTE_PROJECTQUERIESCHECKSHEET, component: ProjectQueriesChecksheet, canActivate: [MsalGuard]},
              { path: Constants.ROUTE_PROJECTQUERIESCUSTOM, component: ProjectQueriesCustom, canActivate: [MsalGuard]}
            ]
        },
        { path: Constants.ROUTE_PROJECTSETTINGS, component: ProjectSettings, canActivate: [MsalGuard],
            children: [
                { path: Constants.ROUTE_PROJECTSETTINGSGENERAL, component: ProjectSettingsGeneral, canActivate: [MsalGuard]},
                { path: Constants.ROUTE_PROJECTSETTINGSWORKFLOW, component: ProjectSettingsWorkflow, canActivate: [MsalGuard]},
                { path: Constants.ROUTE_PROJECTSETTINGSVHDS, component: ProjectSettingsVHDs, canActivate: [MsalGuard]},
                { path: Constants.ROUTE_PROJECTSETTINGSPUBLISH, component: ProjectSettingsPublishing, canActivate: [MsalGuard]},
                { path: Constants.ROUTE_PROJECTSETTINGSSCRIPTS, component: ProjectSettingsScripts, canActivate: [MsalGuard]},
                { path: Constants.ROUTE_PROJECTSETTINGSCONVERT, component: ProjectSettingsConvert, canActivate: [MsalGuard]},
                { path: Constants.ROUTE_PROJECTSETTINGSXCHECK, component: ProjectSettingsXCheck, canActivate: [MsalGuard]},
                { path: Constants.ROUTE_PROJECTSETTINGSREPORTING, component: ProjectSettingsReporting, canActivate: [MsalGuard]},
                { path: Constants.ROUTE_PROJECTSETTINGSSHARED, component: ProjectSettingsShared, canActivate: [MsalGuard]}
            ]
        },    
        { path: Constants.ROUTE_PROJECTPATCHES, component: ProjectPatches, canActivate: [MsalGuard]},
        { path: Constants.ROUTE_PROJECTACTIONS, component: ProjectActions, canActivate: [MsalGuard],
            children : [
              {path: Constants.ROUTE_PROJECTACTIONDETAILS, component: ProjectActionDetails, canActivate: [MsalGuard]}
            ]
        },
        { path: Constants.ROUTE_PROJECTXCHECK, component: ProjectXCheck, canActivate: [MsalGuard]}
      ]
  },
  { path: Constants.ROUTE_PROJECTCREATE, component: ProjectCreate, canActivate: [MsalGuard]},
  { path: Constants.ROUTE_ERROR, component: ErrorView },
  { path: Constants.ROUTE_ERROR_404, component: ErrorNotFound},
  { path: Constants.ROUTE_ERROR_SERVER_LOST, component: ErrorServerLost},
  { path: 'code', component: Dashboard },
  { path: '', component: Dashboard },
  { path: 'dash', component: Dashboard },
  { path: '**', redirectTo:'/' + Constants.ROUTE_ERROR_404 },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { 

}
