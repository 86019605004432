<div fxLayout="column">

    <div fxFlex="none" fxLayout="row" fxLayoutGap="20px">
        <div fxFlex="30">
            <h3>DOCUMENTATION</h3>
            <mat-form-field appearance="outline" style="min-width: 300px;" *ngIf="readmeDocumentationScriptsReady">
                <mat-label>Readme Script</mat-label>
                <mat-select [(ngModel)]="project.readmeDocumentationScript.value"
                    (selectionChange)="readmeDocumentationScriptChanged()" [disabled]="!canEdit()" style="min-width:200px">
                    <mat-option value="{{null}}">Default Script</mat-option>
                    <mat-option [value]="r" *ngFor="let r of readmeDocumentationScripts">{{r}}</mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <div fxFlex="70" fxLayout="column">
            <div fxFlex="none">
                <h3>INTUNE INFO</h3>
            </div>
            <div fxFlex="none" fxLayout="row wrap" fxLayoutGap="10px">
                <div fxFlex="49">
                    <input-edit datatype="STRING" label="Credentals (Base 64 Enc)" [value]="project.intuneCredentials.value" context="intuneCredentials"
                                (on-change)="intuneInfoChanged($event)" [disable]="!canEdit()"></input-edit>
                </div>
                <div fxFlex="49">
                    <input-edit datatype="STRING" label="Owner" [value]="project.intuneOwner.value" context="intuneOwner"
                                (on-change)="intuneInfoChanged($event)" [disable]="!canEdit()"></input-edit>
                </div>
                <div fxFlex="49">
                    <input-edit datatype="URL" label="Information URL" [value]="project.intuneInformationUrl.value" context="intuneInfoUrl"
                                (on-change)="intuneInfoChanged($event)" [disable]="!canEdit()"></input-edit>
                </div>
                <div fxFlex="49">
                    <input-edit datatype="STRING" label="Notes" [value]="project.intuneNotes.value" context="intuneNotes"
                                (on-change)="intuneInfoChanged($event)" [disable]="!canEdit()"></input-edit>
                </div>
                <div fxFlex="49">
                    <input-edit datatype="STRING" label="Navigator Id Element" [value]="project.intuneNavigatorIdElement.value" context="intuneNavigatorIdElement"
                                (on-change)="intuneInfoChanged($event)" [disable]="!canEdit()"></input-edit>
                </div>
            </div>
        </div>
    </div>

    <div fxFlex="none">
        <div class="sw-pad">
            <h3>PUBLISHING COMMANDS</h3>
        </div>
    </div>

    <mat-tab-group fxFlex>
        <mat-tab label="Msi">
            <div class="sw-big-pad">
                <input-edit datatype="MSTRING" [rows]="15" label="Publishing Commands" [value]="currentProject.publishCommandsSplit['DFLT']" 
                    (on-change)="publishingCommandsChanged($event)" [disable]="!canEdit()"></input-edit> 
            </div>
        </mat-tab>
        <mat-tab label="EXE">
            <div class="sw-big-pad">
                <input-edit datatype="MSTRING" [rows]="15" label="Publishing Commands (Exe)" [value]="currentProject.publishCommandsSplit['EXE']" 
                (on-change)="publishingCommandsExeChanged($event)" [disable]="false ||!canEdit()"></input-edit>
            </div>  
        </mat-tab>
        <mat-tab label="App-V">
            <div class="sw-big-pad">
                <input-edit datatype="MSTRING" [rows]="15" label="Publishing Commands (App-V)" [value]="currentProject.publishCommandsSplit['VIRT']" 
                (on-change)="publishingCommandsVirtChanged($event)" [disable]="!canEdit()"></input-edit>
            </div>
        </mat-tab>
        <mat-tab label="MSIX">
            <div class="sw-big-pad">
                <input-edit datatype="MSTRING" [rows]="15" label="Publishing Commands (Msix)" [value]="currentProject.publishCommandsSplit['MSIX']" 
                    (on-change)="publishingCommandsMsixChanged($event)" [disable]="!canEdit()"></input-edit>
            </div>
        </mat-tab>
        <mat-tab label="Liquidware">
            <div class="sw-big-pad">
                <input-edit datatype="MSTRING" [rows]="15" label="Publishing Commands (Liquidware)" [value]="currentProject.publishCommandsSplit['LIQD']" 
                    (on-change)="publishingCommandsLiqdChanged($event)" [disable]="!canEdit()"></input-edit>
            </div>
        </mat-tab>
      </mat-tab-group>

</div>  

