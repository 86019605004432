import { Injector } from '@angular/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CurrentApplicationService } from '../../svc/currentApplicationService';
import { Constants } from '../../api/Constants';
import { PollingService } from '../../svc/pollingService';
import { UtilService } from '../../svc/utilService';
import { GridAssistant } from 'src/app/model/gridAssistant';

import * as CRS from '../../api/CRS';

@Component({
  selector: 'project-application-history',
  templateUrl: './project-application-history.html',
})
export class ProjectApplicationHistory implements OnInit, OnDestroy {

  constructor(
      private injector: Injector,
      private currentApplication: CurrentApplicationService,
      private pollingService: PollingService,
      private utilService: UtilService
  ) { }

  public gridAssist: GridAssistant<CRS.RSGetApplicationActivity, CRS.RSActivityItem>;

  ngOnInit(): void {
    this.gridAssist = new GridAssistant<CRS.RSGetApplicationActivity,CRS.RSActivityItem>("#app-history", this.injector);
    this.gridAssist.setColumns(   [         
        { id: 'activityDate', name: 'Date', field: 'activityDate', sortable: true, width: 120,
            formatter:  (r,c,v,cd,dc)=> {
              return this.utilService.getDateAsStringWithTime(v);
          }
        },
        { id: 'text', name: 'Description', field: 'text', sortable: true, width: 400 },
      ]
    );
    this.gridAssist.setUpdate(()=>this.start());
    this.currentApplication.dataContext.onLoaded().subscribe((data)=> {
      this.pollingService.refreshPollingSequence(Constants.POLLING_ID_APPLICATIONHISTORY);
    });
  }

  ngOnDestroy(): void {
    this.gridAssist.dispose();
    this.pollingService.stopSequence(Constants.POLLING_ID_APPLICATIONHISTORY);
  }

  private start(): void {
    this.pollingService.startPollingSequence(Constants.POLLING_ID_APPLICATIONHISTORY, Constants.POLLING_APPLICATIONHISTORY_INTVL, -1, ()=> {
      this.updateHistory();
    });
  }

  private updateHistory() : void {
      this.currentApplication.getHistory().then((response) => {
            var convResp = new CRS.RSGetApplicationActivity();
            convResp.items = response;
            var wrap = new CRS.ResponseWrapper<CRS.RSGetApplicationActivity>( convResp, new CRS.RSStatus());
            this.gridAssist.mergeDataResponse(wrap, "items", "id", CRS.RSActivityItem );
      }, () => {
          console.error('History couldn\'t be loaded');
      });
  }

}

